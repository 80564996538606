<template>
  <div>
    <div class="d-flex justify-end sticky">
      <v-btn href="https://kmr.sze.hu/setup.exe" download height="60" depressed tile color="muted darken-1"
        class="d-none d-md-flex">
        <v-icon dark left>mdi-download</v-icon>
        Kártyaolvasó illesztő letöltése
      </v-btn>
    </div>

    <h1 class="py-6">Üdvözöljük</h1>
    <div class="normal-text mb-8">
      A rendszer használatához válasszon a bal oldali menüpontok közül!
      <v-btn @click="registration">Passwordless regisztráció</v-btn>
    </div>
  </div>
</template>

<script>
// import { client } from '@passwordless-id/webauthn'

export default {
  methods: {
    // async registration() {

    //   console.log('Registering...')
    //   try {
    //     let res = await client.register('MyUsername', 'random-challenge-base64-encoded')
    //     alert(JSON.stringify(res))
    //   }
    //   catch (e) {
    //     alert(e)
    //   }
    // }
  }
};
</script>
