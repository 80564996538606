<template>
  <v-card style="overflow: hidden" tile elevation="0">
    <v-overlay
      v-show="state !== State.OPEN"
      absolute
      class="text-center"
      color="dark"
      opacity="0.1"
    >
      <template v-if="state === State.LOADING">
        <p class="my-4">Kamerakép betöltése</p>
        <v-progress-linear
          stream
          buffer-value="0"
          rounded
          height="5"
        ></v-progress-linear>
      </template>
      <template v-else-if="state === State.ERROR">
        <v-icon size="96" color="primary">mdi-alert-rhombus-outline</v-icon>
        <p class="my-4" v-html="errorMessage"></p>
        <v-progress-linear v-if="retryTimeout" v-model="retryIntervalProgress" rounded height="5"></v-progress-linear>
      </template>
    </v-overlay>
    <v-responsive :aspect-ratio="16/9" class="d-flex justify-center align-center">
      <v-expand-transition>
        <canvas v-show="state === State.OPEN" ref="canvas" style="width: 100%; display: block"></canvas>
      </v-expand-transition>
    </v-responsive>
  </v-card>
</template>

<script>
const State = {
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  OPEN: 'OPEN',
};

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      State,
      state: State.LOADING,
      player: null,
      errorMessage: null,
      retryTimeout: null,
      retryIntervalInSeconds: 3,
      retryIntervalProgress: 0,
      exiting: false,
    };
  },

  mounted() {
    this.play();
  },

  methods: {
    play() {
      this.retryIntervalProgress = 0;
      this.state = State.LOADING;
      this.player = null;
      this.errorMessage = null;
      try {
        this.player = new JSMpeg.Player(this.url, {
          canvas: this.$refs.canvas,
        });
        this.player.source.socket.onclose = (e) => {
          if (this.exiting) {
            return;
          }
          this.state = State.ERROR;
          switch (e.reason) {
            case 'UNAUTHORIZED':
              this.errorMessage = 'Jogosultság hiba!';
              break;
            default:
              this.retryTimeout = setTimeout(this.retryIntervalEventHandler, 100);
              this.errorMessage = 'Szerveroldali hiba!<br>Újrapróbálkozás...';
          }
        };
        const onmessage = this.player.source.socket.onmessage;
        this.player.source.socket.onmessage = (message) => {
          this.state = State.OPEN;
          onmessage(message);
        };
      } catch (e) {
        console.error(e);
      }
    },

    retryIntervalEventHandler() {
      this.retryIntervalProgress += 100 / (this.retryIntervalInSeconds * 10);
      if (this.retryIntervalProgress < 100) {
        setTimeout(this.retryIntervalEventHandler, 100);
      } else {
        this.play();
      }
    },
  },

  beforeDestroy() {
    this.player.destroy();
  },
};
</script>
