<template>
  <section class="d-flex">
    <div class="container my-auto pb-12">
      <div id="login-modal">
        <v-card elevation="24" outlined>
          <v-card id="header" tile color="muted" class="text-center pa-8 px-sm-12">
            <div>
              <!-- <v-icon color="secondary" size="96">mdi-shield-key-outline</v-icon> -->
              <v-img width="120" class="mx-auto" :src="require('../../assets/logo.svg')" />
            </div>
            <h1 class="my-4 pa-0 text-uppercase">BR<span> - Beléptető rendszer</span></h1>
            <small>DirektGuard beléptető menedzsment felülete.</small>
          </v-card>

          <v-card id="body" tile elevation="0" :loading="loading">
            <div class="pa-8 px-sm-12">
              <form @submit.prevent="login">
                <v-text-field
                  label="Email"
                  type="email"
                  filled
                  dense
                  v-model="form.email"
                  :error-messages="errors.email"
                  autofocus
                />
                <v-text-field
                  label="Jelszó"
                  filled
                  v-model="form.password"
                  :error-messages="errors.password"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                />

                <v-expand-transition>
                  <v-alert
                    v-if="errors.login && errors.login.length"
                    dense
                    filled
                    dismissible
                    border="left"
                    type="error"
                  >
                    <template v-for="error in errors.login">
                      {{ error }}
                    </template>
                  </v-alert>
                  <v-alert v-if="$route.params.message" dense text type="warning">
                    {{ $route.params.message }}
                  </v-alert>
                </v-expand-transition>

                <div class="d-flex flex-column justify-center align-center">
                  <v-btn
                    type="submit"
                    color="accent"
                    x-large
                    depressed
                    :loading="loading"
                    :disabled="loading"
                  >
                    <v-icon class="mr-2" small>mdi-login-variant</v-icon>
                    Bejelentkezés
                  </v-btn>
                </div>
              </form>
            </div>
          </v-card>
        </v-card>
        <div class="text-center" style="position: relative">
          <small class="text-caption white--text" :title="`Build #${buildNumber}\n${buildDate}`">{{
            version
          }}</small>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { version } from '@/../package.json';

export default {
  data: function () {
    return {
      version,
      form: {
        email: '',
        password: '',
      },
      loading: false,
      showPassword: false,
      errors: [],
    };
  },

  mounted() {
    this.$store.commit('removeToken');
    this.$store.commit('removeUser');
  },

  methods: {
    async login() {
      this.loading = true;
      this.errors = {};
      try {
        const response = await this.$http.post('auth/login', this.form);
        if (Object.keys(response.errors).length) {
          throw response.errors;
        }
        this.$store.commit('setUser', response.user);
        this.$router.push(this.$route.params.redirect || '/');
      } catch (err) {
        setTimeout(() => (this.errors = err), 500);
      } finally {
        setTimeout(() => (this.loading = false), 1000);
      }
    },
  },
  computed: {
    buildDate() {
      return new Date(process.env.VUE_APP_BUILD_DATETIME).toLocaleString();
    },
    buildNumber() {
      return parseInt(process.env.VUE_APP_BUILD_NUMBER);
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  background: url('~@/assets/img/login.jpg') no-repeat center center;
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  min-height: 100%;
  top: 0;
  left: 0;
  right: 0;

  .container {
    max-width: 600px;

    #login-modal {
      margin: auto;

      #header {
        border-bottom: 2px solid var(--v-primary-base) !important;

        #logo {
          font-size: 63px;
        }

        h1 {
          line-height: 1 !important;
          font-size: 45px !important;
          letter-spacing: -4px;
          font-weight: 600;

          & > span {
            font-size: 35px;
            letter-spacing: -3px;
            font-weight: 300;
          }
        }
      }

      #body {
        h2 {
          color: inherit;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
