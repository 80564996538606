<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn
        tile
        depressed
        color="grey"
        dark
        width="60"
        height="60"
        class="ml-1"
        @click="$router.go(-1)"
      >
        <v-icon dark>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn
        tile
        depressed
        color="accent"
        width="60"
        height="60"
        class="ml-1"
        @click="save"
        :disabled="loading"
      >
        <v-icon dark>mdi-content-save</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Ütemzés</h1>

    <v-card :loading="loading">
      <v-card-text>
        <v-text-field
          label="Megnevezés"
          v-model="form.name"
          :error-messages="errors.name"
          filled
        />
      </v-card-text>
      <v-multi-select-list
        :headers="headers"
        v-model="form.schedules"
        :blankItemTemplate="blankSchedule"
      >
        <template #[`item.v`]="{ index }">{{ index + 1 }}.</template>
        <template #[`item.start_time`]="{ item, index }">
          <v-datetime-picker
            filled
            dense
            hide-details
            v-model="item.start_time"
            :error-messages="errors[`start_time_${index}`]"
            :date-picker="false"
          />
        </template>
        <template #[`item.end_time`]="{ item, index }">
          <v-datetime-picker
            filled
            dense
            hide-details
            v-model="item.end_time"
            :error-messages="errors[`end_time_${index}`]"
            :date-picker="false"
            :text-field-props="{
              dense: true,
              hideDetails: true
            }"
          />
        </template>
        <template v-for="day of $config.constants.daysOfWeek" #[`item.${day}`]="{ item, index }">
            <v-simple-checkbox
              :key="`checkbox-${index}-${day}`"
              v-model="item[day]"
              color="secondary"
              :ripple="false"
            />
        </template>
        <template #[`item.date_interval_id`]="{ item }">
          <v-autocomplete
            @change="dateChange(item)"
            v-model="item.date_interval_id"
            :items="dateIntervals"
            item-text="name"
            item-value="id"
            hide-details
            filled
            dense
          />
        </template>
      </v-multi-select-list>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        name: '',
        schedules: [],
      },
      errors: [],
      loading: false,
      headers: [
        { text: '', value: 'v', sortable: false },
        {
          text: 'Kezdő időpont',
          value: 'start_time'
        },
        { text: 'Záró időpont', value: 'end_time', sortable: false },
        { text: 'Hétfő', value: 'monday', sortable: false },
        { text: 'Kedd', value: 'tuesday', sortable: false },
        { text: 'Szerda', value: 'wednesday', sortable: false },
        { text: 'Csütörtök', value: 'thursday', sortable: false },
        { text: 'Péntek', value: 'friday', sortable: false },
        { text: 'Szombat', value: 'saturday', sortable: false },
        { text: 'Vasárnap', value: 'sunday', sortable: false },
        { text: 'Időszak', value: 'date_interval_id', sortable: false },
      ],
    };
  },

  mounted() {
    this.fetchScheduleGroup();
  },

  methods: {
    async fetchScheduleGroup() {
      try {
        const response = await this.$http.get(`schedule-groups/details/${ this.$route.params.id || 0 }`);
        this.form = response.schedule_group;
        for (const schedule of this.form.schedules) {
          for (const day of this.$config.constants.daysOfWeek) {
            schedule[day] = !!Number(schedule[day]);
          }
        }
      } catch (e) {
        console.error(e);
      }
    },

    dateChange(item) {
      for (const day of this.$config.constants.daysOfWeek) {
        item[day] = false;
      }
    },

    async save() {
      try {
        this.errors = {};
        this.loading = true;
        const response = await this.$http.post('schedule-groups/save', this.form);
        if (this.lodash.size(response.errors) > 0) {
          this.errors = response.errors;
          return;
        }
        if (response.status === 'OK') {
          this.$dialog.notify.success('Sikeres mentés');
          if (this.$route.name === 'NewSchedule') {
            this.$router.push({ name: 'ScheduleEditor', params: { id: response.schedule_group.id } });
          } else {
            this.fetchScheduleGroup();
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {
    blankSchedule() {
      const schedule = {
        start_time: '00:00:00',
        end_time: '23:59:59',
        date_interval_id: 0,
      };
      for (const day of this.$config.constants.daysOfWeek) {
        schedule[day] = false;
      }
      return schedule;
    }
  },

  asyncComputed: {
    dateIntervals: {
      async get() {
        const response = await this.$http.post('date-intervals/list');
        return response.date_intervals;
      },
      default: [],
    }
  }
};
</script>
