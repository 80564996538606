<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn v-bind="$config.buttonAttrs.floating" @click="$router.go(-1)">
        <v-icon dark>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn v-bind="$config.buttonAttrs.floating" @click="save" :disabled="loading">
        <v-icon dark>mdi-content-save</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Kulcsszekrény</h1>

    <loading-overlay v-if="loading" />

    <v-row>
      <v-col :cols="12">
        <v-card>
          <v-card-title>
            <v-icon left>mdi-file-cabinet</v-icon>
            Alapadatok
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col :cols="12" :md="6">
                <v-text-field
                  label="Megnevezés"
                  :error-messages="errors.name"
                  v-model="form.name"
                  filled
                />
                <v-autocomplete
                  label="Épület"
                  :items="buildings"
                  item-text="name"
                  item-value="id"
                  v-model="form.building_id"
                  :value-comparator="$config.comparators.isEqual"
                  filled
                  clearable
                />
                <v-autocomplete
                  label="Szint"
                  :items="building_levels"
                  item-text="name"
                  item-value="id"
                  v-model="form.building_level_id"
                  :disabled="!form.building_id"
                  :hint="!form.building_id ? 'Épület kiválasztása kötelező' : ''"
                  persistent-hint
                  :value-comparator="$config.comparators.isEqual"
                  filled
                  clearable
                />
              </v-col>
              <v-col :cols="12" :md="6">
                <v-textarea
                  label="Megjegyzés"
                  name="name"
                  v-model="form.remark"
                  filled
                  auto-grow
                  :rows="5"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="12">
        <v-card>
          <v-card-title>
            <v-icon left>mdi-key-chain</v-icon>
            Kulcsok
          </v-card-title>
          <v-card-text>
            <v-two-panel-multiselect
              :headers="[
                { text: 'Név', value: 'name' },
                { text: 'Megjegyzés', value: 'remark' },
              ]"
              :options="{ sortBy: ['card_number'] }"
              :items="keys"
              v-model="form.keys"
              item-value="element_id"
            >
              <template #header="{ search }">
                <v-card outlined class="mb-6">
                  <v-card-title>
                    <v-icon left>mdi-filter</v-icon>
                    Keresés
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col :cols="12" :md="6" :lg="3">
                        <v-text-field label="Név" v-model="search.name" hide-details />
                      </v-col>
                      <v-col :cols="12" :md="6" :lg="3">
                        <v-text-field label="Megjegyzés" v-model="search.remark" hide-details />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </template>
            </v-two-panel-multiselect>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: '',
        remark: '',
        cards: [],
        building_id: 0,
      },

      keys: [],
      buildings: [],
      // building_levels: [],

      errors: {},
      loading: false,
    };
  },

  async mounted() {
    this.fetchAll();
  },

  methods: {
    async fetchKeys() {
      const response = await this.$http.post('keys/list', { limit: 100000 });
      this.keys = response.keys;
    },
    async fetchBuildings() {
      const response = await this.$http.post('buildings/list');
      this.buildings = response.buildings;
    },

    async fetchAll() {
      this.loading = true;
      await Promise.all([this.fetchKey(), this.fetchKeys(), this.fetchBuildings()]);
      this.loading = false;
    },

    async fetchKey() {
      try {
        const response = await this.$http.post(
          `key-cabinets/details/${this.$route.params.id || 0}`
        );
        this.form = response.key_cabinet;
      } catch (e) {
        console.error(e);
      }
    },

    async save() {
      try {
        this.errors = {};
        this.loading = true;
        const response = await this.$http.post('key-cabinets/save/', this.form);
        if (this.lodash.size(response.errors) > 0) {
          this.errors = response.errors;
          return;
        }

        if (response.status === 'OK') {
          this.$dialog.notify.success('Sikeres mentés');
          if (this.$route.name === 'NewKeyCabinet') {
            this.$router.push({
              name: 'KeyCabinetEditor',
              params: { id: response.key_cabinet.id },
            });
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },

  asyncComputed: {
    building_levels: {
      async get() {
        const response = await this.$http.post('building-levels/list', {
          building_id: this.form.building_id,
        });
        return response.building_levels;
      },
      default: [],
    },
  },
};
</script>
