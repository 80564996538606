var render = function render(){
  var _vm$form$status, _vm$form$status2, _vm$form$status3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [((_vm$form$status = _vm.form.status) === null || _vm$form$status === void 0 ? void 0 : _vm$form$status.name) !== 'permInactive' ? _c('v-btn', _vm._b({
    attrs: {
      "disabled": _vm.loading,
      "title": "Mentés"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-content-save")])], 1) : _vm._e(), ((_vm$form$status2 = _vm.form.status) === null || _vm$form$status2 === void 0 ? void 0 : _vm$form$status2.name) === 'idReading' ? _c('v-btn', {
    staticClass: "ml-1",
    attrs: {
      "tile": "",
      "depressed": "",
      "color": "accent",
      "width": "60",
      "height": "60",
      "disabled": _vm.loading,
      "title": "Kártya beolvasása",
      "dark": ""
    },
    on: {
      "click": function ($event) {
        return _vm.setRFID();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-card-search")])], 1) : _vm._e()], 1), _vm.$route.name === 'NewCard' ? _c('h1', {
    staticClass: "my-6"
  }, [_vm._v("Új kártya")]) : _c('h1', {
    staticClass: "my-6"
  }, [_vm._v("Kártya szerkesztése")]), _vm.loading ? _c('loading-overlay') : _vm._e(), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 12,
      "md": 6,
      "lg": 4
    }
  }, [_c('v-card', [_c('v-card-title', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-badge-account-horizontal")]), _vm._v("Alapadatok")], 1), _c('v-card-text', [_c('v-autocomplete', {
    attrs: {
      "label": "Kártyabirtokos",
      "error-messages": _vm.errors.owner_id,
      "items": _vm.cardOwners,
      "search-input": _vm.search,
      "cache-items": "",
      "item-value": "element_id",
      "filled": "",
      "hint": this.form.owner_id ? 'Nem módosítható' : '',
      "persistent-hint": "",
      "readonly": !!(this.$route.params.ownerId || _vm.form.element_id !== 0 || _vm.$route.params.id !== undefined)
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.search = $event;
      },
      "update:search-input": function ($event) {
        _vm.search = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.name) + " "), item.email ? _c('span', {
          staticClass: "ml-2"
        }, [_vm._v("(" + _vm._s(item.email) + ")")]) : _vm._e()];
      }
    }, {
      key: "item",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.name) + " "), item.email ? _c('span', {
          staticClass: "ml-2"
        }, [_vm._v("(" + _vm._s(item.email) + ")")]) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.form.owner_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "owner_id", $$v);
      },
      expression: "form.owner_id"
    }
  }), _vm.form.element_id != 0 ? _c('v-masked-text-field', {
    attrs: {
      "label": "Kártyaszám",
      "mask": _vm.$config.inputMasks.cardNumber,
      "readonly": "",
      "hint": "Nem módosítható",
      "persistent-hint": "",
      "filled": ""
    },
    model: {
      value: _vm.form.card_number,
      callback: function ($$v) {
        _vm.$set(_vm.form, "card_number", $$v);
      },
      expression: "form.card_number"
    }
  }) : _vm._e(), _vm.form.element_id != 0 ? _c('v-text-field', {
    attrs: {
      "label": "Státusz",
      "value": (_vm$form$status3 = _vm.form.status) === null || _vm$form$status3 === void 0 ? void 0 : _vm$form$status3.name,
      "readonly": "",
      "hint": "Nem módosítható",
      "persistent-hint": "",
      "filled": ""
    }
  }) : _vm._e()], 1)], 1), _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-account-school")]), _vm._v("Neptun képzések")], 1), _c('v-card-text', [_vm.neptunCourses === null ? _c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "type": "list-item-three-line"
    }
  }) : _vm._e(), _vm.neptunCourses && _vm.neptunCourses.length === 0 ? _c('v-alert', {
    attrs: {
      "icon": "mdi-alert",
      "color": "muted"
    }
  }, [_vm._v(" Nem található képzés a rendszerben. ")]) : _vm._l(_vm.neptunCourses, function (course) {
    return _c('v-list-item', {
      key: course.szakkod
    }, [_c('v-list-item-icon', [course.statusz === 'Aktív' ? _c('v-icon', {
      attrs: {
        "color": "success",
        "title": course.statusz
      }
    }, [_vm._v("mdi-check-circle")]) : _c('v-icon', {
      attrs: {
        "color": "grey darken-1",
        "title": `Inaktív (${course.statusz})`
      }
    }, [_vm._v("mdi-close-circle-outline")])], 1), _c('div', [_c('div', [_vm._v(_vm._s(course.szak))]), _c('div', [_vm._v(_vm._s(course.tagozat) + " (" + _vm._s(course.szakkod) + ")")])])], 1);
  })], 2)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "md": 6,
      "lg": 4
    }
  }, [_c('v-card', [_c('v-card-title', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-security")]), _vm._v("Hozzáférési szintek")], 1), _c('v-multi-select-list', {
    attrs: {
      "headers": [{
        text: 'Megnevezés',
        value: 'name'
      }, {
        text: 'Lejárat',
        value: 'valid_to'
      }],
      "items": _vm.accessLevels,
      "item-value": "element_id",
      "item-text": "name",
      "unique-values": "",
      "blank-item-template": {
        element_id: 0
      }
    },
    scopedSlots: _vm._u([{
      key: `item.name`,
      fn: function (_ref3) {
        var item = _ref3.item,
          items = _ref3.items;
        return [_c('v-autocomplete', {
          attrs: {
            "items": items,
            "item-text": "name",
            "item-value": "element_id",
            "placeholder": "Válassz",
            "hide-details": "",
            "filled": "",
            "dense": ""
          },
          model: {
            value: item.element_id,
            callback: function ($$v) {
              _vm.$set(item, "element_id", $$v);
            },
            expression: "item.element_id"
          }
        })];
      }
    }, {
      key: `item.valid_to`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-datetime-picker', {
          attrs: {
            "filled": "",
            "placeholder": "Nincs lejárat",
            "clearable": "",
            "dense": "",
            "hide-details": "",
            "time-picker": false
          },
          model: {
            value: item.valid_to,
            callback: function ($$v) {
              _vm.$set(item, "valid_to", $$v);
            },
            expression: "item.valid_to"
          }
        })];
      }
    }], null, true),
    model: {
      value: _vm.form.access_levels,
      callback: function ($$v) {
        _vm.$set(_vm.form, "access_levels", $$v);
      },
      expression: "form.access_levels"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "md": 6,
      "lg": 4
    }
  }, [_c('v-card', [_c('v-card-title', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-car")]), _vm._v("Rendszámok")], 1), _c('v-multi-select-list', {
    attrs: {
      "headers": [{
        text: 'Rendszám',
        value: 'name'
      }],
      "items": _vm.form.plates,
      "item-value": "number",
      "item-text": "number",
      "hide-default-header": "",
      "blank-item-template": {
        number: ''
      }
    },
    scopedSlots: _vm._u([{
      key: `item.name`,
      fn: function (_ref5) {
        var item = _ref5.item,
          index = _ref5.index;
        return [_c('v-masked-text-field', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "label": `#${item.id || index + 1}`,
            "error-messages": _vm.errors[`plate-${index}`],
            "filled": "",
            "dense": "",
            "hide-details": !item.active_season_ticket,
            "mask": _vm.$config.inputMasks.plateNumber
          },
          model: {
            value: item.number,
            callback: function ($$v) {
              _vm.$set(item, "number", $$v);
            },
            expression: "item.number"
          }
        })];
      }
    }], null, true),
    model: {
      value: _vm.form.plates,
      callback: function ($$v) {
        _vm.$set(_vm.form, "plates", $$v);
      },
      expression: "form.plates"
    }
  })], 1)], 1), _vm.hasFeature('PARKING_PENALTY') ? _c('v-col', {
    attrs: {
      "cols": 12
    }
  }, [_c('v-card', [_c('v-card-title', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-gavel")]), _vm._v(" Parkolási büntetések ")], 1), _c('parking-penalties', {
    attrs: {
      "card": _vm.form
    }
  })], 1)], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": 12
    }
  }, [_c('v-card', [_c('v-card-title', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-history")]), _vm._v("Eseménynapló")], 1), _vm.form.element_id ? _c('event-log', {
    attrs: {
      "log": _vm.form.log,
      "access-levels": _vm.accessLevels,
      "card-statuses": _vm.cardStatuses
    }
  }) : _vm._e()], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }