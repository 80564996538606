<template>
  <v-card style="overflow: hidden" tile elevation="0">
    <v-overlay
      v-show="state !== State.OPEN || state === State.BUFFERING"
      absolute
      class="text-center"
      color="dark"
      opacity="0.5"
    >
      <template v-if="state === State.LOADING">
        <p class="my-4">Kamerakép betöltése</p>
        <v-progress-linear stream buffer-value="0" rounded height="5"></v-progress-linear>
      </template>
      <template v-if="state === State.BUFFERING">
        <p class="my-4">Pufferelés: {{ bufferProcess }}%</p>
        <v-progress-linear
          stream
          :buffer-value="bufferProcess"
          :value="bufferProcess"
          rounded
          height="5"
        ></v-progress-linear>
      </template>
      <template v-else-if="state === State.ERROR">
        <v-icon size="96" color="primary">mdi-alert-rhombus-outline</v-icon>
        <p class="my-4" v-html="errorMessage"></p>
        <v-progress-linear
          v-if="retryTimeout"
          v-model="retryIntervalProgress"
          rounded
          height="5"
        ></v-progress-linear>
      </template>
    </v-overlay>
    <v-responsive :aspect-ratio="16 / 9" class="d-flex justify-center align-center">
      <v-expand-transition>
        <video
          style="
            object-fit: cover;
            width: 100%;
            background: black;
            display: block;
            transition: 0.6s all, 0.2s max-height;
            transition-timing-function: ease-in-out;
          "
          :style="{ transform: `translate(0.5, 0.5) scale(1, ${state === State.OPEN ? 1 : 0.8})`, maxHeight: `${state === State.OPEN ? 1080 : 0}px`, filter: cssFilters }"
          ref="videoPlayer"
          autoplay
          muted
        ></video>
        <!-- <canvas
          v-show="state === State.OPEN"
          ref="canvas"
          style="width: 100%; display: block"
        ></canvas> -->
      </v-expand-transition>
    </v-responsive>
  </v-card>
</template>

<script>
const State = {
  LOADING: 'LOADING',
  BUFFERING: 'BUFFERING',
  ERROR: 'ERROR',
  OPEN: 'OPEN',
};

export default {
  props: {
    serverAddress: {
      type: String,
      required: true,
    },
    streamId: {
      type: String,
      required: true,
    },
    cssFilters: {
      type: String,
      default: 'saturate(0.6)',
    },
  },

  data() {
    return {
      State,
      state: State.LOADING,
      player: null,
      errorMessage: null,
      retryTimeout: null,
      retryIntervalInSeconds: 3,
      retryIntervalProgress: 0,
      exiting: false,
      bufferProcess: 0,
      bufferProcessAddition: 10,
      bufferProcessInterval: null,

      // stream: null,
      peerConnection: null,
      webrtcSendChannel: {},
    };
  },

  mounted() {
    this.play();
  },

  methods: {
    play() {
      this.$refs.videoPlayer.onplay = (event) => {
        clearInterval(this.bufferProcessInterval);
        this.bufferProcess = 100;
        setTimeout(() => {
          this.state = State.OPEN;
        }, 200);
      };

      this.$refs.videoPlayer.addEventListener('loadeddata', () => {
        this.$refs.videoPlayer.play();
      });

      this.$refs.videoPlayer.addEventListener('error', () => {
        console.log('video_error');
      });

      // this.stream = new MediaStream();
      this.peerConnection = new RTCPeerConnection({
        iceServers: [
          {
            urls: ['stun:stun.l.google.com:19302'],
          },
        ],
        sdpSemantics: 'unified-plan',
      });
      this.peerConnection.onnegotiationneeded = this.handleNegotiationNeededEvent;

      this.peerConnection.ontrack = (e) => {
        this.$refs.videoPlayer.srcObject = e.streams[0];
      };

      this.peerConnection.addTransceiver('video', {
        direction: 'sendrecv',
      });

      this.webrtcSendChannel = this.peerConnection.createDataChannel('foo');

      // this.webrtcSendChannel.onclose = () => {
      //   console.log('sendChannel has closed');
      //   this.state = State.ERROR;
      //   this.errorMessage = 'Hiba történt!<br>Újrapróbálkozás...';
      // };
      this.webrtcSendChannel.onopen = () => {
        this.state = State.BUFFERING;
        this.bufferProcessInterval = setInterval(() => {
          this.bufferProcess += Math.floor(
            Math.random() * ((100 - this.bufferProcess) / 4) + (100 - this.bufferProcess) / 5
          );
          if (this.bufferProcess >= 100) {
            this.bufferProcess = 100;
            clearInterval(this.bufferProcessInterval);
          }
        }, 250);
        console.log('sendChannel has opened');
        this.webrtcSendChannel.send('ping');
        this.webrtcSendChannelInterval = setInterval(() => {
          console.log('pinging');
          this.webrtcSendChannel.send('ping');
        }, 1000);
      };

      this.peerConnection.ondatachannel = (e) => console.log(e);

      this.webrtcSendChannel.onmessage = (e) => console.log(e);
      this.webrtcSendChannel.onerror  = (e) => console.log(e);
      this.webrtcSendChannel.ondatachannel   = (e) => console.log(e);

      this.peerConnection.oniceconnectionstatechange = (e) => {
        switch (this.peerConnection.iceConnectionState) {
          case 'disconnected':
            this.state = State.ERROR;
            this.retryTimeout = setTimeout(this.retryIntervalEventHandler, 100);
            this.errorMessage = 'Hiba történt!<br>Újrapróbálkozás...';
            break;
        }
      };
    },
    async handleNegotiationNeededEvent() {
      const url = `${this.serverAddress}/stream/${this.streamId}/channel/0/webrtc?uuid=${this.streamId}&channel=0`;
      const offer = await this.peerConnection.createOffer();
      await this.peerConnection.setLocalDescription(offer);

      const body = new URLSearchParams();
      body.append('data', btoa(this.peerConnection.localDescription.sdp));
      const result = await fetch(url, {
        method: 'POST',
        body,
      });
      const data = await result.text();
      try {
        this.peerConnection.setRemoteDescription(
          new RTCSessionDescription({
            type: 'answer',
            sdp: atob(data),
          })
        );
      } catch (e) {
        console.warn(e);
      }
    },

    async getCodecInfo() {
      const result = await fetch(`${this.serverAddress}/stream/codec/${this.streamId}`);
      const data = await result.json();
      for (const value of data) {
        this.peerConnection.addTransceiver(value.Type, {
          direction: 'sendrecv',
        });
      }
    },
    async getRemoteSdp() {
      const body = new URLSearchParams();
      body.append('suuid', this.streamId);
      body.append('data', btoa(this.peerConnection.localDescription.sdp));
      const result = await fetch(`${this.serverAddress}/stream/receiver/${this.streamId}`, {
        method: 'POST',
        body,
      });
      const data = await result.text();
      try {
        this.peerConnection.setRemoteDescription(
          new RTCSessionDescription({
            type: 'answer',
            sdp: atob(data),
          })
        );
      } catch (e) {
        console.warn(e);
      }
    },
    retryIntervalEventHandler() {
      this.retryIntervalProgress += 100 / (this.retryIntervalInSeconds * 10);
      if (this.retryIntervalProgress < 100) {
        setTimeout(this.retryIntervalEventHandler, 100);
      } else {
        this.play();
      }
    },
  },

  beforeDestroy() {
    clearInterval(this.webrtcSendChannelInterval);
    this.peerConnection.close();
  },
};
</script>
