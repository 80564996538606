<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn v-bind="$config.buttonAttrs.floating" color="grey" @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn v-bind="$config.buttonAttrs.floating" @click="save" :disabled="loading">
        <v-icon dark>mdi-content-save</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Relé</h1>

    <v-card :loading="loading" class="mb-6">
      <v-card-title>
        <v-icon left>mdi-doorbell</v-icon>
        Alapadatok
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col :cols="12" :md="6" :sm="12">
            <v-text-field
              label="Megnevezés"
              :error-messages="errors.name"
              v-model="form.name"
              filled
              clearable
            />

            <v-autocomplete
              label="Kontroller"
              :error-messages="errors.controller_id"
              v-model="form.controller_id"
              :items="controllers"
              item-text="name"
              item-value="element_id"
              filled
              clearable
              placeholder="Válasszon"
              :value-comparator="$config.comparators.isEqual"
            />

            <v-text-field
              label="Relé cím"
              :error-messages="errors.address"
              v-model="form.address"
              filled
              clearable
              type="number"
              min="0"
              max="32"
            />

            <v-select
              label="Működési mód"
              :error-messages="errors.operating_mode"
              v-model="form.operating_mode"
              :items="operatingModes"
              item-text="label"
              item-value="value"
              filled
              clearable
            />
          </v-col>
          <v-col :cols="12" :md="6">
            <v-textarea
              label="Megjegyzés"
              :error-messages="errors.comment"
              v-model="form.comment"
              filled
              auto-grow
              rows="3"
            />
          </v-col>
        </v-row>
        <!-- <v-row class="m-1">
          <v-col :cols="12" :md="3" :sm="6">
            <v-select
              label="Eszköz típus"
              v-model="deviceModelName"
              :items="deviceTypes"
              item-text="name"
              item-value="model_name"
              @change="changeDeviceType"
              filled
              placeholder="Válasszon"
            />
            <v-select
              label="Eszköz"
              v-model="deviceId"
              :items="devices"
              item-text="name"
              item-value="element_id"
              filled
              placeholder="Válasszon"
            />
            <v-btn
              v-show="deviceId != 0"
              @click="addDevice"
              fab
              small
              color="secondary"
              dark
              elevation="0"
              class="rounded-sm"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
          <v-col :cols="12" :md="3" :sm="6">
            <h2>Eszközök</h2>
            <v-card v-if="form.devices.length > 0">
              <v-data-table :headers="headers" :items="form.devices" hide-default-footer>
                <template #[`item.actions`]="{ item }">
                  <v-btn
                    @click="removeDevice(item.element_id)"
                    fab
                    small
                    color="secondary"
                    dark
                    elevation="0"
                    class="rounded-sm"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
            <div v-else class="normal-text" style="margin-top: 10px">
              Nem található csatolt eszköz.
            </div>
            nem található eszköz
          </v-col>
        </v-row>
        <v-row class="m-1">
          <v-col :cols="12" :md="3" :sm="6"> </v-col>
        </v-row> -->
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-title>
        <v-icon left>mdi-transit-connection-variant</v-icon>
        Eszközök
      </v-card-title>
      <v-card-text>
        <v-two-panel-multiselect
          :headers="[
            { text: 'Eszköz', value: 'name' },
            { text: 'Típus', value: 'type' },
          ]"
          :items="devices"
          :options="{ sortBy: ['type.id'] }"
          v-model="form.devices"
          item-value="element_id"
          :default-search="{ ['type.model_name']: deviceTypes.map((e) => e.model_name) }"
        >
          <template #header="{ search }">
            <v-card outlined class="mb-6">
              <v-card-title>
                <v-icon left>mdi-filter</v-icon>
                Keresés
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col :cols="12" :md="6" :lg="3">
                    <v-text-field label="Eszköz neve" v-model="search.name" clearable />
                  </v-col>
                  <v-col :cols="12" :md="6" :lg="3">
                    <v-select
                      label="Eszköz típus"
                      v-model="search['type.model_name']"
                      :items="deviceTypes"
                      item-text="name"
                      item-value="model_name"
                      multiple
                      clearable
                      small-chips
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip small v-if="search['type.model_name'].length < deviceTypes.length">
                          {{ item.name }}
                        </v-chip>
                        <v-chip small v-else-if="index === 0">Minden típus</v-chip>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
          <template #[`item.type`]="{ item }">
            {{ item.type.name }}
          </template>
        </v-two-panel-multiselect>
      </v-card-text>
    </v-card>

    <!--<div class="row">
      <div class="col-xs-6">

        <div class="row">
                <div class="col-xs-6">
                  <comboboxV2 :change="changeDeviceType" id="device_types" label="Eszköz típus" name="device_types" v-model="device_type_id" :options="device_types"></comboboxV2>
                  <div style="display: flex; justify-content: space-between; align-items: center;">
                    <comboboxV2 style="width: 90%" id="device_id" label="Eszköz" name="device_id" v-model="device_id" :options="devices"></comboboxV2>
                    <button v-on:click="deviceSelect" v-show="device_id != 0" type="button" class="button small"><i class="customicon customicon-chevron-right"></i></button>
                  </div>
                </div>
                <div class="col-xs-6">
                  Eszközök
                  <div v-if="form.devices.length > 0" class="selected-items-list" style="margin-top: 10px;">
                    <div class="item" v-for="device in form.devices">
                      <button v-on:click="deviceDelete(device.value)" type="button" class="button small"><i class="customicon customicon-trash"></i></button>
                      {{ device.label }}
                    </div>
                  </div>
                  <div v-if="form.devices.length == 0" class="normal-text" style="margin-top: 10px;">
                    Nem található csatolt eszköz.
                  </div>
                </div>
              </div>
      </div>
    </div>-->
  </div>
</template>
<script>
export default {
  components: {},

  data() {
    return {
      form: {
        element_id: 0,
        controller_id: 0,
        name: '',
        address: '',
        comment: '',
        operating_mode: 0,
        devices: [],
      },

      operatingModes: [
        {
          label: 'Normál',
          value: 0,
        },
        {
          label: 'Fordított',
          value: 1,
        },
      ],
      deviceTypes: [
        {
          name: 'Ajtó',
          model_name: 'Doors',
        },
        {
          name: 'Sorompó',
          model_name: 'Barriers',
        },
        {
          name: 'Forgóvilla',
          model_name: 'Turnstiles',
        },
      ],
      devices: [],
      deviceId: 0,
      controllers: [],
      errors: [],
      loading: false,
      headers: [
        { text: 'Eszköz', value: 'name', sortable: false },
        { text: '', value: 'actions', sortable: false, align: 'end' },
      ],
    };
  },

  async mounted() {
    this.fetchRelay();
  },

  methods: {
    async fetchRelay() {
      try {
        const response = await this.$http.get(`relays/details/${this.$route.params.id || 0}`);
        this.form = response.relay;
        Promise.all([this.fetchDevices(), this.fetchControllers()]);
      } catch (e) {
        console.error(e);
      }
    },

    async fetchDevices() {
      try {
        const response = await this.$http.post(`elements/list`, {
          model_names: this.deviceTypes.map((e) => e.model_name),
        });
        this.devices = response.elements;
      } catch (e) {
        console.error(e);
      }
    },

    async fetchControllers() {
      try {
        const response = await this.$http.post('controllers/list');
        this.controllers = response.controllers;
      } catch (e) {
        console.error(e);
      }
    },

    async save() {
      try {
        this.errors = {};
        this.loading = true;
        const response = await this.$http.post('relays/save', this.form);
        if (Object.keys(response.errors).length) {
          this.errors = response.errors;
        } else {
          if (this.$route.name !== 'RelayEditor') {
            this.$router.push({ name: 'RelayEditor', params: { id: response.relay.element_id } });
          }
          this.fetchRelay();
          this.$dialog.notify.success('Sikeres mentés!');
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
