export default [
  {
    to: { name: 'Home' },
    label: 'Főoldal',
    icon: 'mdi-view-dashboard'
  },
  {
    label: 'Adminisztrátorok',
    right: 'USER',
    icon: 'mdi-account',
    group: 'Users',
    children: [
      {
        to: { name: 'UserList' },
        label: 'Lista',
        right: 'USER_READ',
        icon: 'mdi-format-list-bulleted',
      },
      {
        to: { name: 'NewUser' },
        label: 'Új',
        right: 'USER_WRITE',
        icon: 'mdi-plus',
      },
      {
        to: { name: 'Filter' },
        label: 'Szűrő',
        right: 'FILTER',
        icon: 'mdi-filter',
      }
    ]
  },
  {
    label: 'Kártyabirtokosok',
    right: 'CARD_OWNER',
    icon: 'mdi-clipboard-account',
    group: 'CardOwners',
    children: [
      {
        to: { name: 'CardOwnerList' },
        label: 'Lista',
        right: 'CARD_OWNER_READ',
        icon: 'mdi-format-list-bulleted',
      },
      {
        to: { name: 'NewCardOwner' },
        label: 'Új',
        right: 'CARD_OWNER_WRITE',
        icon: 'mdi-plus',
      }
    ]
  },
  {
    label: 'Kártyák',
    right: 'CARD',
    icon: 'mdi-badge-account-horizontal',
    group: 'Cards',
    children: [
      {
        to: { name: 'CardList' },
        label: 'Lista',
        right: 'CARD_READ',
        icon: 'mdi-format-list-bulleted',
      },
      {
        to: { name: 'NewCard' },
        label: 'Új',
        right: 'CARD_WRITE',
        icon: 'mdi-plus',
      }
    ]
    // children: [
    //   {
    //     id: 'm33',
    //     link: '/cardgroups',
    //     label: 'Kártya csoport',
    //     icon: 'fa fa-object-group',
    //     parents: ['m3'],
    //     level: 2,
    //     children: [
    //       {
    //         id: 'm331',
    //         link: '/cardgroup/edit',
    //         label: 'Új csoport',
    //         icon: 'fa fa-object-group',
    //         parents: ['m3','m33'],
    //         level: 3
    //       }
    //     ]
    //   }
    // ]
  },
  {
    label: 'Rendszámok',
    right: 'PLATE',
    icon: 'mdi-car',
    group: 'Plates',
    children: [
      {
        to: { name: 'PlateList' },
        label: 'Lista',
        right: 'PLATE_READ',
        icon: 'mdi-format-list-bulleted',
      },
      {
        to: { name: 'NewPlate' },
        label: 'Új',
        right: 'PLATE_WRITE',
        icon: 'mdi-plus',
      },
    ]
  },
  {
    label: 'QR kódok',
    right: 'QR',
    icon: 'mdi-qrcode',
    group: 'QrCodes',
    children: [
      {
        to: { name: 'QrCodeRequestList' },
        label: 'Igénylés',
        right: 'QR_REQUEST',
        icon: 'mdi-plus',
      },
      {
        to: { name: 'QrCodeApprovalList' },
        label: 'Jóváhagyás',
        right: 'QR_APPROVAL',
        icon: 'mdi-check',
      },
      {
        to: { name: 'QrCodeList' },
        label: 'Lista',
        right: 'QR_READ',
        icon: 'mdi-format-list-bulleted',
      }
    ]
  },
  {
    to: { name: 'Tickets' },
    label: 'Parkolójegyek',
    right: 'PARKING_TICKET_READ',
    icon: 'mdi-ticket-confirmation-outline',
  },
  {
    to: { name: 'RemoteOpen' },
    right: 'REMOTE_OPEN',
    label: 'Távoli nyitás',
    icon: 'mdi-lock-open-variant',
  },
  {
    label: 'Naplók',
    right: 'LOG_READ',
    icon: 'mdi-notebook-outline',
    group: 'Log',
    children: [
      {
        to: { name: 'AdminLog' },
        label: 'Admin napló',
        icon: 'mdi-shield-account-outline',
      },
      {
        to: { name: 'ParkingLog', params: { type: 'ParkingLot' } },
        label: 'Parkoló napló',
        icon: 'mdi-parking',
      },
      {
        to: { name: 'BuildingLog', params: { type: 'Building' } },
        label: 'Épület napló',
        icon: 'mdi-office-building',
      },
    ]
  },
  {
    to: { name: 'GroupList' },
    label: 'Csoportok',
    right: 'GROUP_READ',
    icon: 'mdi-group',
  },
  {
    to: { name: 'ScheduleList' },
    label: 'Ütemzések',
    right: 'SCHEDULE_READ',
    icon: 'mdi-calendar-clock',
  },
  {
    to: { name: 'DateIntervalList' },
    label: 'Időszakok',
    right: 'SCHEDULE_READ',
    icon: 'mdi-calendar-multiple',
  },
  {
    to: { name: 'AccessLevelList' },
    label: 'Hozzáférési szintek',
    right: 'ACCESS_LEVEL_READ',
    icon: 'mdi-security',
  },
  {
    to: { name: 'OpeningHours' },
    label: 'Nyitvatartás',
    right: 'OPENING_READ',
    icon: 'mdi-timetable',
  },
  {
    label: 'Eszközök',
    right: 'DEVICE_READ',
    icon: 'mdi-cog-outline',
    group: 'Devices',
    children: [
      {
        to: { name: 'BuildingList' },
        label: 'Épületek',
        icon: 'mdi-office-building',
        //            children: [
        //              {
        //                id: 'm611',
        //                link: '/building/edit',
        //                label: 'Épület módosítás',
        //                icon: '',
        //                parents: ['m6', 'm61'],
        //                level: 3
        //              }
        //            ]
      },
      {
        to: { name: 'BuildingLevelList' },
        label: 'Szintek',
        icon: 'mdi-layers-triple-outline',
      },
      {
        to: { name: 'GatewayList' },
        label: 'Gateways',
        icon: 'mdi-router-network',
      },
      {
        to: { name: 'ConnectionList' },
        label: 'Connections',
        icon: 'mdi-connection',
      },
      {
        to: { name: 'ControllerList' },
        label: 'Kontrollerek',
        icon: 'mdi-chip',
      },
      {
        to: { name: 'ReaderList' },
        label: 'Olvasók',
        icon: 'mdi-smart-card-reader-outline',
      },
      {
        to: { name: 'RelayList' },
        label: 'Relék',
        icon: 'mdi-doorbell',
      },
      {
        to: { name: 'DoorList' },
        label: 'Ajtók',
        icon: 'mdi-door-closed',
      },
      {
        to: { name: 'BarrierList' },
        label: 'Sorompók',
        icon: 'mdi-boom-gate',
      },
      {
        to: { name: 'TurnstileList' },
        label: 'Forgóvillák',
        icon: 'mdi-turnstile-outline',
      }
    ]
  }
];
