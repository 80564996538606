<template>
  <div>
    <v-card-text>
      <div class="text-h4 text-center mb-6">Kulcs kiadása</div>

      <v-row>
        <v-col :cols="6">
          <v-card
            width="360"
            height="180"
            class="text-center d-flex flex-column justify-center align-center"
            style="position: relative; overflow: hidden"
            outlined
          >
            <v-overlay :value="loading" absolute>
              <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card-text>
              <div v-if="!card" class="d-flex flex-column justify-center">
                <div class="icon-container">
                  <v-icon size="96" class="my-6">mdi-scan-helper</v-icon>
                  <v-icon size="48" class="center">mdi-card-account-details</v-icon>
                  <div class="spinner center"></div>
                </div>
                Érintse a kártyát az olvasóhoz!
              </div>
              <div v-else class="flex-grow-1 d-flex align-center">
                <v-avatar width="120" height="160" color="grey ligthen-2" rounded>
                  <v-img
                    v-if="card.photo"
                    :src="'data:image/png;base64, ' + card.photo"
                    @click="lightbox = true"
                  ></v-img>
                  <v-icon v-else size="80">mdi-account</v-icon>
                </v-avatar>
                <div class="ml-5 d-flex flex-column justify-center align-start">
                  <div class="text-h5">{{ card.owner?.name }}</div>
                  <div class="my-2">Kártyaszám: {{ card.card_number }}</div>
                  <div class="">Típus: {{ card.card_rule_name_hu }}</div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col :cols="6">
          <v-card
            width="360"
            height="180"
            class="text-center d-flex flex-column justify-center align-center"
            style="position: relative; overflow: hidden"
            outlined
          >
            <v-overlay :value="loading" absolute>
              <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card-text>
              <div v-if="!key" class="d-flex flex-column justify-center">
                <div class="icon-container">
                  <v-icon size="96" class="my-6">mdi-scan-helper</v-icon>
                  <v-icon size="48" class="center">mdi-key</v-icon>
                  <div class="spinner center"></div>
                </div>
                Érintse a kulcsot az olvasóhoz!
              </div>
              <div v-else class="flex-grow-1 d-flex align-center">
                <v-avatar width="120" height="160" :color="key.color" rounded>
                  <v-icon size="80">mdi-key</v-icon>
                </v-avatar>
                <div class="ml-5 d-flex flex-column justify-center align-start">
                  <div class="text-h5">{{ key.name }}</div>
                  <div class="my-2">{{ key.remark }}</div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12">
          <v-textarea v-model="remark" rows="3" auto-grow outlined label="Megjegyzés" autofocus />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="text-center">
          <v-alert v-if="result" :color="result.color" outlined text>
            <v-icon size="100" :color="result.color">{{ result.icon }}</v-icon>
            <div class="mt-2 text-h5" v-html="result.message"></div>

            <template v-if="RESULTS.permitted === result">
              <v-divider class="ma-4"></v-divider>

              <div class="text-h6">Megtörtént a kulcs kiadása?</div>

              <v-btn
                v-if="result"
                color="error"
                class="ma-4"
                width="200"
                depressed
                x-large
                @click="$emit('close')"
              >
                <v-icon left>mdi-close-thick</v-icon>
                NEM
              </v-btn>
              <v-btn
                v-if="RESULTS.permitted === result"
                color="success"
                class="ma-4"
                width="200"
                depressed
                x-large
                @click="taken"
              >
                <v-icon left>mdi-check-bold</v-icon>
                IGEN
              </v-btn>
            </template>
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
const RESULTS = {
  permitted: {
    icon: 'mdi-check-circle',
    color: 'success',
    message: 'A kártya tulajdonosa jogosult a kulcs használatára.',
    // message: 'Jogosult',
  },
  rejected: {
    icon: 'mdi-cancel',
    color: 'error',
    message: 'A kártya tulajdonosa nem jogosult a kulcs használatára.',
  },
  taken: {
    icon: 'mdi-alert-circle',
    color: 'warning lighten-1',
    message:
      'Ez a kulcs a rendszer szerint használatban van.<br>Kérjük először vegye vissza a kulcsot, majd próbálja újra!',
  },
};

export default {
  data() {
    return {
      RESULTS,
      loading: false,
      rfid: '',
      card: null,
      key: null,
      result: null,
      remark: '',
      readerDisabled: false,
      socket: false,
      socketError: false,
    };
  },

  mounted() {
    this.watchCardReader();
  },

  destroyed() {
    this.socket.close();
  },

  methods: {
    reset() {
      this.view = null;
      this.readerDisabled = false;
      this.result = null;
      this.card = null;
      this.key = null;
      this.remark = '';
    },

    async checkAccess() {
      const res = await this.$http.post('keys/check-access', {
        card_element_id: this.card.element_id,
        key_id: this.key.id,
      });
      this.result = res.permitted ? RESULTS.permitted : RESULTS.rejected;
      this.readerDisabled = true;
    },

    async taken() {
      const res = await this.$http.post('keys/take', {
        card_element_id: this.card.element_id,
        key_id: this.key.id,
        remark: this.remark,
      });

      this.reset();
      this.$emit('close');
    },

    async watchCardReader() {
      if (this.socket.readyState === WebSocket.OPEN) {
        this.socket.close();
      }

      try {
        this.socket = new WebSocket('wss://kmr-reader.uninet.hu:8080');

        this.socket.onmessage = async (event) => {
          if (this.readerDisabled) {
            return;
          }

          this.loading = true;
          this.readerDisabled = true;
          setTimeout(() => {
            if (this.card && this.key) {
              return;
            }
            this.readerDisabled = false;
          }, 1000);
          if (event.data != undefined) {
            this.rfid = event.data;
            const response = await this.$http.get(`keys/lookup/${this.rfid}`);
            if (!response.key && !response.card) {
              this.$dialog.notify.error(
                'Ez a kártya vagy kulcs nincs a rendszerben!<br>Kérjük próbálja újra!',
                {
                  position: 'bottom-right',
                }
              );
              this.loading = false;
              return;
            }

            this.card = response.card ?? this.card;
            this.key = response.key ?? this.key;

            if (this.key?.taken_by) {
              this.result = RESULTS.taken;
              this.readerDisabled = true;
              this.loading = false;
              return;
            }

            if (this.card && this.key) {
              this.checkAccess();
            }

            this.loading = false;
          }
        };
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },

    // test methods
    async simulateRead() {
      if (this.readerDisabled || this.rfid.length === 0) {
        return;
      }
      try {
        this.loading = true;
        const response = await this.$http.get(`keys/lookup/${this.rfid}`);
        if (!response.key && !response.card) {
          throw Error('Ez a kártya vagy kulcs nincs a rendszerben!<br>Kérjük próbálja újra!');
        }

        this.card = response.card ?? this.card;
        this.key = response.key ?? this.key;

        if (this.key?.taken_by) {
          this.result = RESULTS.taken;
          this.readerDisabled = true;
          return;
        }

        if (this.card && this.key) {
          this.checkAccess();
        }
      } catch (err) {
        this.$dialog.notify.error(err.message, {
          position: 'bottom-right',
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-container {
  position: relative;

  .center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.spinner {
  width: 120%;
  aspect-ratio: 1/1;
  background-color: #8882;

  border-radius: 100%;
  -webkit-animation: sk-scaleout 4s infinite ease-in-out;
  animation: sk-scaleout 4s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0);
  }
  50% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
  }
  50% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}
</style>
