<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn
        v-if="hasRight('CARD_WRITE') && hasFeature('CARD_CREATION')"
        v-bind="$config.buttonAttrs.floating"
        :to="{ name: 'NewCard' }"
        title="Hozzáadás"
      >
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </div>
    <h1 class="py-6">Kártya lista</h1>

    <v-expansion-panels class="mb-4" :mandatory="!!Object.values(search).find((e) => e !== '')">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-filter</v-icon>
            Szűrés
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col :cols="12" :sm="12" :md="2">
              <v-text-field
                label="Kártya tulajdonos"
                v-model="search.owner_name"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                hide-details
                clearable
              />
            </v-col>
            <v-col :cols="12" :sm="12" :md="2">
              <v-masked-text-field
                label="Kártyaszám"
                v-model="search.card_number"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                :mask="$config.inputMasks.cardNumber"
                hide-details
                clearable
              />
            </v-col>
            <v-col :cols="12" :sm="12" :md="2">
              <v-masked-text-field
                label="RFID"
                v-model="search.rfid"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                :mask="$config.inputMasks.rfId"
                hide-details
                clearable
                v-uppercase
              />
            </v-col>
            <v-col :cols="12" :sm="12" :md="2">
              <v-masked-text-field
                label="Rendszám"
                v-model="search.plate_number"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                :mask="$config.inputMasks.plateNumber"
                hide-details
                clearable
                v-uppercase
              />
            </v-col>
            <v-col :cols="12" :sm="12" :md="2">
              <v-select
                label="Státusz"
                v-model="search.active"
                @change="searchEventHandler"
                @click:clear="searchEventHandler"
                :items="[
                  { text: 'Aktív', value: '1' },
                  { text: 'Inaktív', value: '0' },
                ]"
                hide-details
                clearable
                :value-comparator="$config.comparators.isEqual"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card>
      <v-data-table
        :loading="loading"
        :headers="dataTable.headers"
        :items="dataTable.items"
        :server-items-length="dataTable.itemsLength"
        :options.sync="dataTable.options"
        :footer-props="$config.dataTableFooterProps"
        :item-class="itemClass"
      >
        <template #[`item.photo`]="{ item }">
          <Avatar rounded color="grey" :size="40" :hide-picture="!item.photo" :hash="item.photo" />
        </template>
        <template #[`item.active`]="{ item }">
          {{ item.active == 1 ? 'Aktív' : 'Inaktív' }}
        </template>
        <template #[`item.plate_numbers`]="{ item }">
          <template v-for="number in item.plate_numbers">
            <v-chip :key="`plate-${number}`" v-if="number" small class="mr-1">{{ number }}</v-chip>
          </template>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn
            v-if="hasRight('CARD_WRITE')"
            small
            fab
            text
            color="secondary"
            dark
            elevation="0"
            class="rounded-sm m-2"
            :to="{ name: 'CardEditor', params: { id: item.element_id } }"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="hasRight('CARD_WRITE') && $root.user.filter_element == 1"
            small
            fab
            text
            color="secondary"
            dark
            elevation="0"
            class="rounded-sm m-2"
            @click="editButtonInfo"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { dataTablePage } from '@/mixins';

export default {
  mixins: [dataTablePage],

  data() {
    return {
      routeName: 'CardList',
      fetchItems: this.fetchCards,
      dataTable: {
        options: {
          sortBy: ['card_number'],
        },
        headers: [
          { text: '', value: 'photo' },
          { text: 'Tulajdonos', value: 'owner_name' },
          { text: 'Kártyaszám', value: 'card_number' },
          { text: 'RFID', value: 'rf_id' },
          { text: 'Státusz', value: 'active' },
          { text: 'Rendszámok', value: 'plate_numbers' },
          { text: '', value: 'actions', sortable: false, align: 'end' },
        ],
      },
      search: {
        card_number: '',
        owner_name: '',
        rfid: '',
        plate_number: '',
        active: '1',
      },
      cardStatuses: [],
      socket: false,
      socketError: false,
    };
  },

  created() {
    if (this.hasFeature('CARD_READER')) {
      this.watchCardReader((event) => {
        if (event.data) {
          this.clearSearchFields();
          this.search.rfid = event.data;
          this.searchEventHandler();
        }
      });
    }
  },

  mounted() {
    this.fetchCardStatuses();
  },

  beforeDestroy() {
    if (this.socket) {
      this.socket.close();
    }
    // this.socket?.close();
  },

  methods: {
    editButtonInfo() {
      this.$dialog.notify.warn('Ez a művelet nem engedélyezett aktív csoportszűrő esetén.');
      // TODO: implement
    },

    rowClick(value, { item }) {
      this.$router.push({ name: 'CardEditor', params: { id: item.element_id } });
    },

    parseSearchQueries() {
      return {
        card_number: this.$route.query.card_number,
        owner_name: this.$route.query.owner_name,
        rfid: this.$route.query.rfid,
        plate_number: this.$route.query.plate_number,
        active: this.$route.query.active,
      };
    },

    async fetchCardStatuses() {
      try {
        const response = await this.$http.post('/card-statuses/list');
        this.cardStatuses = response.card_statuses;
      } catch (e) {
        console.error(e);
      }
    },

    clearSearchFields() {
      this.search = {
        card_number: '',
        owner_name: '',
        rfid: '',
        plate_number: '',
        active: 0,
      };
    },

    watchCardReader() {
      if (this.$vuetify.breakpoint.mobile) {
        return;
      }
      if (this.socket.readyState === WebSocket.OPEN) {
        this.socket?.close();
      }

      try {
        this.socket = new WebSocket('wss://kmr-reader.uninet.hu:8080');
        this.socket.onopen = (event) => {
          this.socketError = false;
        };
        this.socket.onmessage = (event) => {
          if (event.data) {
            this.clearSearchFields();
            this.search.rfid = event.data;
            this.searchEventHandler();
          }
        };
        this.socket.onerror = (event) => {
          if (!this.socketError) {
            this.$dialog.notify.error('Nincs kapcsolat a kártyaolvasó szolgáltatással!');
          }
          this.socketError = true;
          setTimeout(this.watchCardReader, 2000);
        };
      } catch (err) {
        // console.error(err);
      }
    },

    async fetchCards() {
      this.loading = true;
      try {
        const response = await this.$http.post('cards/list', this.options);
        this.dataTable.items = response.cards;
        this.dataTable.itemsLength = response.cards_count;
        this.dataTable.options.page = Number(this.$route.query.page) || 1;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {
    searchQueries() {
      return {
        card_number: this.search.card_number || '',
        owner_name: this.search.owner_name || '',
        rfid: this.search.rfid || '',
        plate_number: this.search.plate_number || '',
        active: this.search.active || '',
      };
    },
  },
};
</script>
