<template>
  <div>
    <h1 class="py-6">Parkolójegyek</h1>
    <v-expansion-panels class="mb-4" :mandatory="!!Object.values(search).find((e) => e !== '')">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-filter</v-icon>
            Szűrés
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mt-2">
            <v-col :cols="12" :sm="4" :md="3">
              <v-masked-text-field
                v-model="search.rf_id_code"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                label="RF ID / Kód"
                :mask="$config.inputMasks.barcodeRfId"
                dense
                clearable
                hide-details
                class="my-4 my-sm-0"
                v-uppercase
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-masked-text-field
                v-model="search.license_plate_number"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                label="Rendszám"
                :mask="$config.inputMasks.plateNumber"
                dense
                clearable
                hide-details
                class="my-4 my-sm-0"
                v-uppercase
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card :loading="loading">
      <v-data-table
        :headers="dataTable.headers"
        :items="dataTable.items"
        :server-items-length="dataTable.itemsLength"
        :options.sync="dataTable.options"
        :footer-props="$config.dataTableFooterProps"
      >
        <template #[`item.rf_id_code`]="{ item }">
          <span>
            <small>{{ item.rf_id }}</small>
            <br/>
            {{ item.barcode_number }}
          </span>
        </template>
        <template #[`item.payment`]="{ item }">
          <span v-if="item.exonerated">
            <v-icon>mdi-information</v-icon>
            Fizetés alól felmentve
          </span>
          <span v-else>{{ item.end_date }}</span>
        </template>
        <template #[`item.exit_datetime`]="{ item }">
          <span v-if="item.exit_datetime === false">-</span>
          <span v-else>{{ item.exit_datetime }}</span>
        </template>
        <template #[`item.cost`]="{ item }">
          <span v-if="item.exit_datetime || item.exonerated">0 Ft</span>
          <span v-else>{{ parseInt(item.fee).toLocaleString() }} Ft</span>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn
            v-if="hasRight('PARKING_TICKET_WRITE') && !item.exit_datetime && item.end_date !== '9999-12-31 00:00:00'"
            fab
            x-small
            color="secondary"
            dark
            elevation="0"
            class="rounded-sm"
            :disabled="loading"
            @click="exonerationClick(item)"
            title="Fizetési kötelezettség elengedése"
          >
            <v-icon>mdi-currency-usd-off</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <div class="text-center">
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-app-bar flat color="primary" class="mb-6">
            <v-icon dark left>mdi-currency-usd-off</v-icon>
            <v-toolbar-title class="text-h6 white--text pl-0">
              Fizetési kötelezettség elengedése
            </v-toolbar-title>
          </v-app-bar>

          <v-card-text class="pb-0 px-9">
            <v-row class="mb-3">
              Az alábbi parkolójegy mentesül minden fizetési kötelezettség alól.
            </v-row>
            <v-row>
              <v-col :cols="12" :md="6" :sm="12" class="p-0">
                <b>RF ID:</b>
              </v-col>
              <v-col :cols="12" :md="6" :sm="12" class="p-0">
                {{ exoneration.ticket.rf_id }}
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="12" :md="6" :sm="12" class="p-0">
                <b>Behajtás ideje:</b>
              </v-col>
              <v-col :cols="12" :md="6" :sm="12" class="p-0">
                {{ exoneration.ticket.created }}
              </v-col>
            </v-row>
            <v-row class="pt-3">
              <v-textarea
                filled
                auto-grow
                rows="3"
                label="Indoklás (opcionális)"
                v-model="exoneration.ticket.remark"
              />
            </v-row>
          </v-card-text>

          <v-divider class="mb-0 pb-0"></v-divider>

          <v-card-actions>
            <v-btn color="primary" text @click="dialog = false"> Mégse </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              align="start"
              @click="exonerationHandler"
            >
              Elfogadás
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { dataTablePage } from '@/mixins';

export default {
  mixins: [dataTablePage],

  data() {
    return {
      routeName: 'TicketList',
      fetchItems: this.fetchTickets,
      exoneration: {
        ticket: false,
        remark: '',
      },
      selectedPlate: {},
      dataTable: {
        options: {
          sortBy: ['start_date_time'],
          sortDesc: [true],
        },
        headers: [
          { text: 'Rf ID / Kód', value: 'rf_id_code', sortable: false },
          { text: 'Rendszám', value: 'license_plate' },
          { text: 'Behajtás ideje', value: 'start_date_time' },
          { text: 'Érvényesség vége', value: 'payment', sortable: false },
          { text: 'Kihajtás ideje', value: 'exit_datetime', sortable: false },
          { text: 'Fizetendő összeg', value: 'cost', sortable: false },
          { text: '', value: 'actions', sortable: false },
        ],
      },
      search: {
        rf_id_code: '',
        license_plate_number: '',
      },
      dialog: false,
      searchTimeout: null,
    };
  },

  methods: {
    parseSearchQueries() {
      return {
        license_plate_number: this.$route.query.license_plate_number,
        rf_id_code: this.$route.query.rf_id_code,
      };
    },

    exonerationClick(item) {
      this.exoneration.ticket = item;
      this.dialog = true;
    },

    // clearSearchFields() {
    //   this.search = {
    //     rff_id_code: '',
    //     license_plate_number: '',
    //   };
    // },

    async fetchTickets() {
      this.loading = true;
      const data = JSON.parse(JSON.stringify(this.search));
      data.sort_by = this.options.sortBy || [''];
      data.sort_desc = this.options.sortDesc || [false];
      try {
        const response = await this.$http.post(
          'parking-tickets/list/physical',
          this.options
        );
        this.dataTable.items = response.tickets;
        this.dataTable.itemsLength = response.tickets_count;
        this.dataTable.options.page = Number(this.$route.query.page) || 1;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async exonerationHandler() {
      this.loading = true;
      this.errors = [];
      try {
        await this.$http.post(
          'parking-tickets/exoneration/',
          this.exoneration
        );
        this.loading = false;
        this.exoneration.ticket = [];
        this.routerChange();
      } catch (e) {
        console.error(e);
      } finally {
        this.dialog = false;
      }
    },
  },

  computed: {
    searchQueries() {
      return {
        rf_id_code: this.search.rf_id_code || '',
        license_plate_number: this.search.license_plate_number || '',
      };
    }
  }
};
</script>
