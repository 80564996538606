<template>
  <div>
    <h1 class="py-6">Vonalkód jóváhagyás</h1>
    <v-card :loading="loading" v-if="requests.length > 0">
      <v-data-table :headers="headers" :items="requests" hide-default-footer>
        <template #[`item.count`]="{ item }">
          <v-text-field
            :disabled="item.approved == 1"
            type="number"
            :rules="numrules"
            :error-messages="errors['amount' + item.id]"
            v-model="item.amount"
          />
        </template>
        <template #[`item.license`]="{ item }">
          <v-checkbox
            v-for="al in item.access_levels"
            :key="al.element_id"
            v-model="al.selected"
            :disabled="item.approved == 1"
            :label="al.name"
            color="orange"
          />
        </template>
        <template #[`item.accept`]="{ item }">
          <v-icon v-if="item.approved == 0">mdi-minus</v-icon>
          <v-icon v-else>mdi-check</v-icon>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn
            fab
            small
            color="secondary"
            dark
            elevation="0"
            class="rounded-sm m-1"
            v-if="item.approved == 0"
            @click="approve(item.id)"
            title="Igénylés jóváhagyása"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
          <v-btn
            fab
            small
            color="secondary"
            dark
            elevation="0"
            class="rounded-sm m-1"
            v-if="item.approved == 0"
            @click="del(item.id)"
            title="Igénylés törlése"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <div v-else>Nem található vonalkód igénylés.</div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      requests: [],
      errors: [],
      isSave: false,
      loading: false,
      headers: [
        { text: "Név", value: "name" },
        { text: "Email", value: "email" },
        { text: "Igénylés dátuma", value: "request_date" },
        { text: "Csoport", value: "groupname" },
        { text: "Mennyiség", value: "count" },
        { text: "Engedély", value: "license" },
        { text: "Megjegyzés", value: "comment" },
        { text: "Jóváhagyva", value: "accept" },
        { text: "Jóváhagyás dátuma", value: "approved_date" },
        { text: "", value: "actions" },
      ],
      numrules: [(v) => /^[0-9]*$/.test(v) || "Csak számot lehet írni"],
      amount: 0,
    };
  },
  mounted() {
    this.getRequests();
  },
  methods: {
    async getRequests() {
      this.loading = true;
      var _this = this;
      try {
        const response = await this.$http.get("barcodeRequestsToApproved");
        _this.requests = response.requests;
        _this.amount = response.length;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async approve(id) {
      var _this = this;

      this.errors = [];

      var selectedRequest;

      this.requests.map(function (request) {
        if (request.id == id) selectedRequest = request;
      });

      this.isSave = true;
      try {
        const response = await this.$http.post(
          "barcodeRequest/approve/",
          selectedRequest
        );
        _this.isSave = false;
        if (response.msg) {
          throw response.msg;
        }
        if (Object.keys(response.errors).length) {
          _this.errors = response.errors;
        } else {
          _this.getRequests();
        }
      } catch (e) {
        console.error(e);
      }
    },

    async del(id) {
      var _this = this;
      try {
        const response = await this.$http.get("barcodeRequest/del/" + id);
        _this.getRequests();
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
