<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn
        v-bind="$config.buttonAttrs.floating"
        title="Importálás fájlból"
        @click="showImportDialog = true"
      >
        <v-icon dark>mdi-file-import</v-icon>
      </v-btn>

      <v-btn
        v-if="hasRight('KEY_WRITE')"
        v-bind="$config.buttonAttrs.floating"
        :to="{ name: 'NewKey' }"
        title="Új kulcs"
      >
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Kulcs lista</h1>

    <v-expansion-panels class="mb-4" :mandatory="!!Object.values(search).find((e) => e !== '')">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-filter</v-icon>
            Szűrés
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mt-2" align="end">
            <v-col :cols="12" :sm="4" :md="3">
              <v-text-field
                v-model="search.name"
                label="Megnevezés"
                dense
                clearable
                hide-details
                @input="searchEventHandler"
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-autocomplete
                label="Kulcsszekrény"
                :items="key_cabinets"
                item-text="name"
                item-value="id"
                v-model="search.key_cabinet_id"
                :value-comparator="$config.comparators.isEqual"
                dense
                hide-details
                clearable
                @input="searchEventHandler"
              />
            </v-col>
            <v-col :cols="12" :sm="2" :md="2">
              <v-select
                label="Használatban"
                :items="[
                  { text: 'Igen', value: true },
                  { text: 'Nem', value: false },
                ]"
                v-model="search.taken"
                :value-comparator="$config.comparators.isEqual"
                dense
                hide-details
                clearable
                @input="searchEventHandler"
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-btn
                color="accent"
                depressed
                @click="fetchItems"
              >
                <v-icon left>mdi-magnify</v-icon>
                Szűrés
              </v-btn>
            </v-col>
            <!-- <v-col :cols="12" :sm="4" :md="3">
              <v-autocomplete
                label="Épület"
                :items="buildings"
                item-text="name"
                item-value="id"
                v-model="search.building_id"
                :value-comparator="$config.comparators.isEqual"
                hide-details
                clearable
                @input="searchEventHandler"
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-autocomplete
                label="Szint"
                :items="building_levels"
                item-text="name"
                item-value="id"
                v-model="search.building_level_id"
                :disabled="!building_levels.length"
                hide-details
                :value-comparator="$config.comparators.isEqual"
                clearable
                @input="searchEventHandler"
              />
            </v-col> -->
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card :loading="loading">
      <v-data-table
        :headers="dataTable.headers"
        :items="dataTable.items"
        :server-items-length="dataTable.itemsLength"
        :options.sync="dataTable.options"
        :footer-props="$config.dataTableFooterProps"
      >
        <template #[`item.status`]="{ item }">
          <v-icon
            v-if="item.taken_by"
            color="error"
            :title="`A kulcsot elvitte: ${item.taken_by_name} (${item.taken_by_card_number})`"
          >
            mdi-close-box
          </v-icon>
          <v-icon v-else color="success" title="A kulcs a helyén van">mdi-checkbox-marked</v-icon>
        </template>

        <template #[`item.taken_by`]="{ item }">
          <div>
            <div>{{ item.taken_by_name }}</div>
            <div class="text-caption">{{ item.taken_by_card_number }}</div>
          </div>
        </template>

        <template #[`item.name`]="{ item }">
          <!-- <v-chip :color="item.color" class="mx-1 px-4">
            <v-icon color="white" size="24">mdi-key</v-icon>
          </v-chip>
          {{ item.name }} -->
          <v-chip :color="`${item.color}`" class="key-tag pl-3 pr-0">
            <v-sheet label color="white" class="key-tag-label px-2 py-1" rounded>
              {{ item.name }}
            </v-sheet>
            <v-chip color="white" x-small class="key-tag-hole px-2 mx-1"></v-chip>
          </v-chip>
        </template>
        <template #[`item.cabinets`]="{ item }">
          <v-chip
            v-for="cabinet in item.cabinets"
            :key="cabinet.id"
            label
            link
            small
            :to="{ name: 'KeyCabinetList', params: { highlight: [cabinet.id] } }"
            class="ma-1"
          >
            {{ cabinet.name }}
          </v-chip>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn
            v-if="hasRight('KEY_WRITE')"
            text
            fab
            small
            dark
            color="secondary"
            class="rounded-sm mr-1"
            :to="{ name: 'KeyEditor', params: { id: item.id } }"
            title="Szerkesztés"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="hasRight('KEY_WRITE')"
            text
            fab
            small
            dark
            color="secondary"
            class="rounded-sm ma-1"
            @click="deleteKey(item)"
            title="Archiválás"
          >
            <v-icon>mdi-archive</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <import-dialog
      v-model="showImportDialog"
      @close="showImportDialog = false"
      @success="fetchKeys"
    />
  </div>
</template>

<script>
import { dataTablePage } from '@/mixins';
import ImportDialog from './ImportDialog.vue';

export default {
  mixins: [dataTablePage],

  components: {
    ImportDialog,
  },

  data() {
    return {
      showImportDialog: false,
      routeName: 'KeyList',
      fetchItems: this.fetchKeys,
      dataTable: {
        options: {
          sortBy: ['name'],
        },
        headers: [
          // { text: 'Szín', value: 'color', sortable: false, width: 60 },
          {
            text: 'Státusz',
            value: 'status',
            sortable: false,
            width: 60,
            align: 'center',
          },
          {
            text: 'Elvitte',
            value: 'taken_by',
          },
          {
            text: 'Megnevezés',
            value: 'name',
          },
          {
            text: 'Kulcsszekrények',
            value: 'cabinets',
            sortable: false,
          },
          {
            text: 'RFID',
            value: 'rfid',
            sortable: false,
          },
          {
            text: 'Megjegyzés',
            value: 'remark',
            sortable: false,
          },
          {
            text: '',
            value: 'actions',
            sortable: false,
            align: 'end',
          },
        ],
      },
      search: {
        name: '',
        taken: null,
        key_cabinet_id: '',
        building_id: '',
        building_level_id: '',
      },
      groups: [],
      searchTimeout: null,
    };
  },

  methods: {
    // called before dataTablePage mixin's mounted event
    mounted() {
      this.search.key_cabinet_id =
        this.search.key_cabinet_id !== ''
          ? this.search.query.key_cabinet_id
          : this.$store.getters.config.default_key_cabinet_id;
    },

    parseSearchQueries() {
      return {
        name: this.$route.query.name,
        taken: this.$route.query.taken,
        key_cabinet_id: this.$route.query.key_cabinet_id,
        building_id: this.$route.query.building_id,
        building_level_id: this.$route.query.building_level_id,
      };
    },

    async deleteKey(item) {
      const confirm = await this.$dialog.confirm({
        type: 'error',
        text: `<b>${item.name}</b> kulcs archiválására készül! Folytatja?`,
        title: 'Figyelem',
      });

      if (confirm) {
        try {
          await this.$http.post(`keys/archive/${item.id}`);
          this.fetchKeys();
          if (response.status === 'OK') {
            this.$dialog.notify.info('A kulcs archiválva');
          }
        } catch (e) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      }
    },

    async fetchKeys() {
      this.abortController?.abort();
      clearTimeout(this.searchTimeout);
      this.loading = true;
      try {
        const response = await this.$http.post('keys/list', this.postData);
        this.dataTable.items = response.keys;
        this.dataTable.itemsLength = response.keys_count;
        this.dataTable.options.page = Number(this.$route.query.page) || 1;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {
    searchQueries() {
      return {
        name: this.search.name || '',
        taken: this.search.taken ?? null,
        key_cabinet_id: this.search.key_cabinet_id || '',
        building_id: this.search.building_id || '',
        building_level_id: this.search.building_level_id || '',
      };
    },
  },

  asyncComputed: {
    key_cabinets: {
      async get() {
        const response = await this.$http.post('key-cabinets/list', {
          nolimit: true,
        });
        return response.key_cabinets;
      },
      default: [],
    },
    // buildings: {
    //   async get() {
    //     this.building_levels = [];
    //     const response = await this.$http.post('buildings/list', {
    //       nolimit: true,
    //     });
    //     return response.buildings;
    //   },
    //   default: [],
    // },
    // building_levels: {
    //   async get() {
    //     if (this.search.building_id <= 0) {
    //       this.building_level_id = 0;
    //       this.search.building_level_id = 0;
    //       return [];
    //     }
    //     const response = await this.$http.post('building-levels/list', {
    //       building_id: this.search.building_id,
    //     });
    //     return response.building_levels;
    //   },
    //   default: [],
    // },
  },
};
</script>


<style lang="scss" scoped>
::v-deep .key-tag {
  box-shadow: 0 0 2px #0008;
}
::v-deep .key-tag-hole {
  box-shadow: 0 0 2px inset #0008;
}
::v-deep .key-tag-label {
  box-shadow: 0 0 2px inset #0008;
}
</style>
