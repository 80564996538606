<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn
        :disabled="options.page != 1"
        :title="options.page == 1 ? '' : 'A frissítés opció csak az első oldalon működik'"
        v-bind="$config.buttonAttrs.floating"
        @click="togglePolling"
      >
        <v-icon v-if="polling">mdi-stop</v-icon>
        <v-icon v-else>mdi-play</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Parkoló napló</h1>

    <v-expansion-panels class="mb-4" :mandatory="!!Object.values(search).find((e) => e !== '')">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-filter</v-icon>
            Szűrés
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mt-2">
            <v-col :cols="12" :md="3" :lg="2">
              <v-select
                label="Parkoló"
                :items="buildings"
                item-text="name"
                item-value="id"
                dense
                clearable
                hide-details
                v-model="search.building_id"
                placeholder="Összes"
                @change="searchEventHandler"
              />
            </v-col>
            <v-col :cols="12" :md="3" :lg="2">
              <v-select
                label="Típus"
                :items="logTypes"
                item-text="label"
                item-value="value"
                dense
                hide-details
                clearable
                v-model="search.log_type"
                placeholder="Összes"
                @change="searchEventHandler"
              />
            </v-col>
            <v-col :cols="12" :md="3" :lg="2">
              <v-select
                label="Időkorlát"
                :items="timePeriods"
                item-text="label"
                item-value="value"
                dense
                clearable
                hide-details
                v-model="search.period"
                @change="searchEventHandler"
              />
            </v-col>
            <v-col :cols="12" :md="3" :lg="2">
              <v-select
                label="Kontroller"
                :items="controllers"
                item-text="name"
                item-value="element_id"
                dense
                clearable
                hide-details
                v-model="search.controller_id"
                placeholder="Összes"
                @change="searchEventHandler"
              />
            </v-col>
            <v-col :cols="12" :md="3" :lg="4">
              <v-text-field
                label="Név / Kártyaszám / Vonalkód száma / Rendszám"
                dense
                clearable
                hide-details
                v-model="search.keyword"
                @input="searchEventHandler"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card>
      <v-data-table
        :loading="loading"
        :headers="dataTable.headers"
        :items="dataTable.items"
        :server-items-length="dataTable.itemsLength"
        :options.sync="dataTable.options"
        :footer-props="$config.dataTableFooterProps"
        calculate-widths
      >
        <template #[`item.timestamp`]="{ item }">
          <span :title="item.timestamp | moment('LLL')">{{ item.timestamp | moment('from') }}</span>
        </template>
        <template #[`item.number`]="{ item }">
          {{ item.card_number ? item.card_rule_prefix + item.card_number : item.barcode_number }}
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn
            v-if="item.card_id"
            :to="{ name: 'CardEditor', params: { id: item.card_id } }"
            fab
            small
            color="secondary"
            dark
            text
            class="rounded-sm"
          >
            <v-icon>mdi-card-account-details</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { dataTablePage } from '@/mixins';

export default {
  mixins: [dataTablePage],

  data() {
    return {
      lastId: 0,
      polling: false,
      buildingType: 'P',
      waiting: false,
      routeName: 'ParkingLog',
      fetchItems: this.fetchParkingLog,
      dataTable: {
        options: {
          sortBy: ['timestamp'],
          sortDesc: [true],
        },
        headers: [
          { text: 'Idő', value: 'timestamp' },
          { text: 'Név', value: 'creator_name' },
          { text: 'Kártyaszám', value: 'card_number' },
          { text: 'Hely', value: 'gate_name' },
          { text: 'Station ID', value: 'stationid' },
          { text: 'Gate ID', value: 'gateid' },
          { text: 'Típus', value: 'type' },
          { text: 'Esemény', value: 'message', sortable: false },
          { value: 'actions', sortable: false },
        ],
      },
      logTypes: [],
      buildings: [],
      controllers: [],
      timePeriods: [
        {
          label: 'utolsó 1 óra',
          value: '1 hours',
        },
        {
          label: 'utolsó 1 nap',
          value: '1 days',
        },
        {
          label: 'utolsó 1 hét',
          value: '1 weeks',
        },
        {
          label: 'utolsó 1 hónap',
          value: '1 months',
        },
        {
          label: 'utolsó 3 hónap',
          value: '3 months',
        },
      ],
      search: {
        keyword: '',
        log_type: 'enter_identified',
        building_id: '',
        building_type: 'P',
        period: '1 days',
        controller_id: '',
      },
      logs: [],
      pollingInterval: null,
      pollintTimeout: null,
    };
  },

  created() {
    this.fetchBuildings();
    this.fetchControllers();
    this.fetchLogTypes();
    if (this.options.page == 1) {
      this.polling = true;
    }
  },

  beforeDestroy() {
    this.polling = false;
    clearTimeout(this.pollintTimeout);
  },

  methods: {
    parseSearchQueries() {
      return {
        keyword: this.$route.query.keyword,
        log_type: this.$route.query.log_type,
        period: this.$route.query.period,
        controller_id: Number(this.$route.query.controller_id),
        building_id: Number(this.$route.query.building_id),
      };
    },

    togglePolling() {
      this.polling = !this.polling;
      if (this.polling) {
        this.fetchParkingLog();
      }
    },

    tdStyle(log) {
      return {
        'background-color': log.row_background_color,
        color: log.row_text_color,
      };
    },

    async fetchLogTypes() {
      try {
        const response = await this.$http.post('daemon-logs/types/list');
        this.logTypes = response.log_types;
      } catch (e) {
        console.error(e);
      }
    },

    async fetchBuildings() {
      try {
        const response = await this.$http.post('buildings/list', {
          type: 'P', // Parkolók
        });
        this.buildings = response.buildings;
      } catch (e) {
        console.error(e);
      }
    },

    async fetchControllers() {
      try {
        const response = await this.$http.post('controllers/list', {
          building_type: 'P', // Parkolók
        });
        this.controllers = response.controllers;
      } catch (e) {
        console.error(e);
      }
    },

    async fetchParkingLog() {
      this.loading = true;
      try {
        const response = await this.$http.post(
          'daemon-logs/list/parking',
          Object.assign(this.options, { last_id: this.polling ? this.lastId : 0 })
        );
        if (this.polling) {
          this.pollintTimeout = setTimeout(() => {
            this.fetchParkingLog();
          }, 2000);
          if (this.lastId === response.last_id) {
            return;
          }
        }
        this.lastId = response.last_id;
        this.dataTable.items = response.logs;
        this.dataTable.itemsLength = response.logs_count;
        this.dataTable.options.page = this.$route.query.page * 1 || 1;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {
    searchQueries() {
      return {
        keyword: this.search.keyword || '',
        period: this.search.period || '',
        log_type: this.search.log_type || '',
        building_id: Number(this.search.building_id) || 0,
        controller_id: Number(this.search.controller_id) || 0,
        building_type: this.search.building_type || '',
      };
    },
  },
};
</script>

