<template>
  <div></div>
</template>
<script>
export default {
  mounted() {
    this.$logout();
  }
}
</script>
