<template>
  <div class="mx-6">
    <div class="d-flex justify-end sticky to-header"></div>

    <h1 class="py-6">Kulcsfoglalások</h1>

    <v-card class="mb-6">
      <v-card-text>
        <v-autocomplete
          v-model="selectedKey"
          label="Kulcs"
          :items="keys"
          item-text="name"
          item-value="id"
          return-object
          hide-details
          clearable
        />
      </v-card-text>
    </v-card>

    <v-card :loading="loading" class="relative">
      <v-overlay :value="!selectedKey" absolute opacity="0.8">
        A foglalások megtekintéséhez válasszon ki egy kulcsot!
      </v-overlay>
      <v-card-title>
        <v-btn fab text small tile @click="prev">
          <v-icon small> mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn fab text small tile @click="next">
          <v-icon small> mdi-chevron-right </v-icon>
        </v-btn>

        <v-btn v-if="focus.length" outlined class="mx-2" @click="setToday"> Ma </v-btn>

        <v-toolbar-title v-if="cal">
          {{ $refs.calendar.title }}
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined v-bind="attrs" v-on="on">
              <span>{{ typeToLabel[type] }}</span>
              <v-icon right> mdi-menu-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="type = 'day'">
              <v-list-item-title>Napi nézet</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'week'">
              <v-list-item-title>Heti nézet</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'month'">
              <v-list-item-title>Havi nézet</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = '4day'">
              <v-list-item-title>4 napos nézet</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>

      <v-row>
        <v-col>
          <v-sheet :max-height="type === 'month' ? 800 : 24 * 28" :height="calendarHeight">
            <v-calendar
              ref="calendar"
              v-model="focus"
              :event-color="color"
              :event-height="40"
              :event-name="(e) => (e.name ? e.name : 'Nincs megadva')"
              :events="keyReservations"
              :first-interval="0"
              :interval-count="24"
              :interval-height="24"
              :interval-minutes="60"
              :last-interval="24"
              :type="type"
              :start="startDate"
              weekdays="1,2,3,4,5,6,0"
              event-end="end_datetime"
              event-start="start_datetime"
              @click:event="showReservation"
              @click:time="addEvent"
            >
              <template #day-body="{ date, week }">
                <div
                  class="v-current-time"
                  :class="{ first: date === week[0].date }"
                  :style="{ top: nowY }"
                ></div>
              </template>

              <template #day-label="{ day, present }">
                <v-avatar
                  v-if="present"
                  rounded
                  size="40"
                  color="primary"
                  class="white--text font-weight-bold"
                >
                  {{ day }}
                </v-avatar>
                <v-avatar v-else size="40" color="transparent">
                  {{ day }}
                </v-avatar>
              </template>
              <template #day-label-header="{ day, present }">
                <v-avatar
                  v-if="present"
                  rounded
                  size="40"
                  color="primary"
                  class="white--text font-weight-bold"
                >
                  {{ day }}
                </v-avatar>
                <v-avatar v-else size="40" color="transparent">
                  {{ day }}
                </v-avatar>
              </template>
            </v-calendar>
          </v-sheet>
        </v-col>
      </v-row>
    </v-card>
    <reservation-dialog ref="reservationDialog" :keyId="selectedKey?.id" />
  </div>
</template>

<script>
import moment from 'moment';
import ReservationDialog from './ReservationDialog.vue';

export default {
  components: {
    ReservationDialog,
  },
  data() {
    return {
      loading: false,
      ready: false,
      focus: '',
      type: 'week',
      typeToLabel: {
        month: 'Havi nézet',
        week: 'Heti nézet',
        day: 'Napi nézet',
        '4day': '4 napos nézet',
      },
      selectedKey: null,
    };
  },

  mounted() {
    this.ready = true;
    this.scrollToTime();
    this.updateTime();
    console.log('KeyRess')
  },

  methods: {
    setToday() {
      this.focus = '';
    },

    prev() {
      this.$refs.calendar.prev();
    },

    next() {
      this.$refs.calendar.next();
    },

    getCurrentTime() {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0;
    },

    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 60);

      this.cal.scrollToTime(first);
    },

    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },

    showReservation(e) {
      this.$refs.reservationDialog.edit(e);
    },

    addEvent(e) {
      e.time = e.time.split()[1];
      this.$refs.reservationDialog.create(e);
    },
  },

  computed: {
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },

    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px';
    },

    calendarHeight() {
      return `calc(100vh - ${process.env.VUE_APP_HEADER_HEIGHT}px - ${process.env.VUE_APP_FOOTER_HEIGHT}px - 60px)`;
    },

    color() {
      return this.selectedKey ? this.selectedKey.color : 'grey';
    },

    startDate() {
      return this.$moment().format("YYYY-MM-DD");
    }
  },

  asyncComputed: {
    keys: {
      async get() {
        const response = await this.$http.post('keys/list');
        this.selectedKey = response.keys[0];
        return response.keys;
      },
      default: [],
    },

    keyReservations: {
      async get() {
        this.loading = true;
        const response = await this.$http.post('keys/reservations/list', {
          key_id: this.selectedKey.id,
        });
        this.loading = false;
        return response.key_reservations;
      },
      default: [],
      shouldUpdate() {
        return !!this.selectedKey;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
#calendar-wrapper {
  max-height: calc(100vh - 424px);
}

.v-current-time {
  height: 2px;
  background-color: #ea433588;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>
