<template>
  <v-navigation-drawer
    v-model="drawer"
    :width="sidebarWidth"
    hide-overlay
    absolute
    app
    :mobile-breakpoint="840"
    class="elevation-1 white"
  >
    <!-- <vue-scroll style="height: 100%"> -->

    <sidebar-menu :items="menu" />

    <!-- <v-list dense nav>
        <template v-for="(item, i) in menu">
          <v-list-item
            v-if="!item.children && hasRight(item.right)"
            :key="`menu-item-${i}`"
            :to="item.link || item.to"
          >
            <v-list-item-icon class="mr-3">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            v-else-if="hasRight(item.right)"
            :key="`menu-item-group-${i}`"
            :prepend-icon="item.icon"
            :value="matchChildRoute(item.group)"
            color="primary"
            class="elevation-0 white my-1"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </template>

            <v-list class="px-2">
              <template v-for="child in item.children">
                <v-list-item
                  v-if="hasRight(child.right)"
                  :key="child.label"
                  :to="child.link || child.to"
                  :class="{
                    'primary white--text': matchRoute(child?.to?.name),
                  }"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="child.label"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-list-group>
        </template>
      </v-list> -->
    <v-sheet height="40" color="transparent"></v-sheet>
    <!-- </vue-scroll> -->

    <template v-slot:prepend>
      <router-link :to="{ name: 'Home' }">
        <v-hover>
          <v-sheet
            fab
            tile
            :width="sidebarWidth"
            :height="headerHeight"
            dark
            class="d-flex align-center"
          >
            <!-- <v-icon size="36" class="mx-4">mdi-shield-key-outline</v-icon> -->
            <v-img
              max-width="44"
              class="mx-4"
              contain
              :src="require('../../assets/logo.png')"
            ></v-img>
            <div class="text-left">
              <div class="font-weight-bold">UNIOM</div>
              <div class="caption">Beléptetőrendszer</div>
            </div>
          </v-sheet>
        </v-hover>
      </router-link>
      <v-sheet height="4" color="primary darken-1"></v-sheet>
    </template>
    <template v-slot:append>
      <v-divider class="ma-0" />
      <v-sheet flat dark :height="footerHeight" class="d-flex justify-center align-center pa-3">
        <v-btn large text block @click="$logout">
          <v-icon left>mdi-exit-run</v-icon>
          Exit
        </v-btn>
      </v-sheet>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { version } from '@/../package.json';
import menu from '../../menu.js';
import SidebarMenu from '@/components/SidebarMenu.vue';

export default {
  components: {
    SidebarMenu,
  },
  data: () => ({
    width: 80,
    version,
    menu,
  }),
  methods: {
    matchRoute(name) {
      return !!this.$route.matched.filter((e) => e.path && e.name === name).length;
    },
    matchChildRoute(name) {
      return !!this.$route.matched.filter((e) => e.path && e.name === name).length;
    },
    // hasRight(name) {
    //   if (!name) {
    //     return true;
    //   }
    //   const right = this.findRight(this.$store.state.rights, name);
    //   if (right && (right.selected || this.lodash.find(right.children, { selected: true}))) {
    //     return true;
    //   }
    //   return false;
    // },

    // findRight(rights, alias) {
    //   let result = null;

    //   for (const right of rights) {
    //     if (right.alias === alias) {
    //       result = right;
    //       break;
    //     }
    //     if (right.children) {
    //       result = this.findRight(right.children, alias);
    //       if (result) {
    //         break;
    //       }
    //     }
    //   }

    //   return result;
    // },
  },
  computed: {
    headerHeight() {
      return parseInt(process.env.VUE_APP_HEADER_HEIGHT - 4);
    },
    footerHeight() {
      return parseInt(process.env.VUE_APP_FOOTER_HEIGHT);
    },
    sidebarWidth() {
      return parseInt(process.env.VUE_APP_SIDEBAR_WIDTH);
    },
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(value) {
        this.$store.commit('setDrawer', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.v-navigation-drawer {
  background-color: #2228 !important;

  &.theme--light {
    background-color: #fffa !important;
  }

  &.v-navigation-drawer--is-mobile {
    backdrop-filter: none;
    background-color: #1a1a1a !important;

    &.theme--light {
      background-color: #fafafa !important;
    }
  }
}

.v-list-item--active {
  &::before {
    display: none !important;
  }

  & > * {
    color: white !important;
  }
}

::v-deep .v-list-group__items {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 8px;
    bottom: 8px;
    background-color: var(--v-primary-base);
    width: 2px;
  }

  margin-left: 24px;
}
</style>
