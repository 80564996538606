<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn
        tile
        elevation="0"
        color="accent"
        width="60"
        height="60"
        class="ml-1"
        @click="save"
        :disabled="loading"
      >
        <v-icon dark>mdi-content-save</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Nyitvatartás</h1>

    <v-expansion-panels class="mb-4" :value="0">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-filter</v-icon>
            Szűrés
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mt-2">
            <v-col :cols="12" :md="4" :sm="12">
              <v-autocomplete
                label="Típus"
                v-model="elementTypeId"
                :items="elementTypes"
                item-text="name"
                item-value="id"
                placeholder="Válassz"
                @change="fetchElements"
              />
            </v-col>
            <v-expand-transition>
              <v-col :cols="12" :md="4" :sm="12" v-show="elements.length">
                <v-autocomplete
                  label="Eszköz"
                  v-model="elementId"
                  :items="elements"
                  item-text="name"
                  item-value="element_id"
                  @change="fetchDates"
                >
                  <template #[slot]="{ item }" v-for="slot in ['selection', 'item']">
                    {{ item.name }} ({{ item.openings.length }})
                  </template>
                </v-autocomplete>
              </v-col>
            </v-expand-transition>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-expand-transition>
      <v-alert
        v-show="openWarning"
        color="error"
        type="error"
        icon="mdi-alert-outline"
        elevation="0"
        class="mb-6"
        dark
        prominent
      >
        <b>FIGYELEM!</b>
        <br />
        Ebben az ajtóban olyan zár található, melynek tartós nyitva tartása annak meghibásodását
        okozhatja.
        <br />
        Ennek az opciónak a használata itt nem javasolt.
      </v-alert>
    </v-expand-transition>

    <v-expand-transition>
      <v-card v-show="elementId">
        <v-card-text>
          <v-card class="mb-6" outlined>
            <v-card-title>Nap</v-card-title>
            <v-card-text>
              <v-multi-select-list
                :headers="[
                  { text: 'Nap', value: 'day' },
                  { text: 'Időponttól', value: 'time_from' },
                  { text: 'Időpontig', value: 'time_to' },
                ]"
                :items="days"
                v-model="items"
                :blank-item-template="{ day: null, time_from: '00:00', time_to: '23:59' }"
              >
                <template #[`item.day`]="{ items, item }">
                  <v-select :items="items" v-model="item.day" hide-details dense filled placeholder="Válasszon" :value-comparator="$config.comparators.isEqual"/>
                </template>
                <template v-for="slot in ['time_from', 'time_to']" #[`item.${slot}`]="{ item }">
                  <v-datetime-picker :key="slot" v-model="item[slot]" dense :date-picker="false" filled hide-details />
                </template>
              </v-multi-select-list>
            </v-card-text>
          </v-card>
          <v-card class="mb-6" outlined>
            <v-card-title>Dátum</v-card-title>
            <v-card-text>
              <v-multi-select-list
                :headers="[
                  { text: 'Dátumtól', value: 'date_from' },
                  { text: 'Időponttól', value: 'time_from' },
                  { text: 'Dátumig', value: 'date_to' },
                  { text: 'Időpontig', value: 'time_to' },
                  { text: 'Zárva', value: 'close' },
                ]"
                :items="days"
                v-model="items_"
                :blank-item-template="{ date_from: null, date_to: null, time_from: '00:00', time_to: '23:59', close: { selected: false } }"
              >
                <template #[`item.date_from`]="{ item }">
                  <v-datetime-picker v-model="item.date_from" :time-picker="false" dense placeholder="Válasszon" hide-details filled />
                </template>
                <template #[`item.date_to`]="{ item }">
                  <v-datetime-picker v-model="item.date_to" :time-picker="false" dense placeholder="Válasszon" hide-details filled />
                </template>
                <template #[`item.time_from`]="{ item }">
                  <v-datetime-picker v-model="item.time_from" :date-picker="false" dense hide-details filled />
                </template>
                <template #[`item.time_to`]="{ item }">
                  <v-datetime-picker v-model="item.time_to" :date-picker="false" dense hide-details filled />
                </template>
                <template #[`item.close`]="{ item }">
                  <v-checkbox
                    v-model="item.close.selected"
                    off-icon="mdi-lock-open-variant-outline"
                    on-icon="mdi-lock-outline"
                    :color="item.close.selected ? 'error' : 'success'"
                    :title="item.close.selected ? 'Zárva' : 'Nyitva'"
                  ></v-checkbox>
                </template>
              </v-multi-select-list>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-expand-transition>
        <!-- <h2 class="ml-1">Nap</h2>
        <v-row v-show="item.deleted == undefined" v-for="(item, i) in items" :key="`day${i}`">
          <v-col :cols="12" :md="3" :sm="4">
            <v-select
              label="Nap"
              v-model="item.day"
              :items="days"
              item-text="label"
              item-value="value"
              filled
            />
          </v-col>
          <v-col :cols="12" :md="3" :sm="4">
            <v-datetime-picker
              label="Időpont-tól"
              :timePicker="true"
              :datePicker="false"
              v-model="item.time_from"
              :error-messages="errors['item_time_from' + item.id]"
            />
          </v-col>
          <v-col :cols="12" :md="3" :sm="4">
            <v-datetime-picker
              label="Időpont-ig"
              :timePicker="true"
              :datePicker="false"
              v-model="item.time_to"
              :error-messages="errors['item_time_to_' + item.id]"
            />
          </v-col>
          <v-col :cols="12" :md="3" :sm="4" class="d-flex align-items-center">
            <v-btn
              width="30"
              height="30"
              min-height="30"
              min-width="30"
              color="accent"
              @click="removeItem(item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="m-1">
          <v-col :cols="12" :md="3" :sm="4">
            <v-select
              label="Nap"
              v-model="newItem.day"
              :items="days"
              item-text="label"
              item-value="value"
              filled
            />
          </v-col>
          <v-col :cols="12" :md="3" :sm="4">
            <v-datetime-picker
              label="Időpont-tól"
              :timePicker="true"
              :datePicker="false"
              v-model="newItem.time_from"
              ref="newItem_time_from"
              :error-messages="errors.new_item_time_from"
            />
          </v-col>
          <v-col :cols="12" :md="3" :sm="4">
            <v-datetime-picker
              label="Időpont-ig"
              :timePicker="true"
              :datePicker="false"
              v-model="newItem.time_to"
              ref="newItem_time_to"
              :error-messages="errors.new_item_time_to"
            />
          </v-col>
          <v-col :cols="12" :md="3" :sm="4" class="d-flex align-items-center">
            <v-btn
              width="30"
              height="30"
              min-height="30"
              min-width="30"
              color="accent"
              @click="addItem"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row> -->

        <!--Dátum-->
        <!-- <h2 class="ml-1">Dátum</h2>
        <v-row
          v-show="item.deleted == undefined"
          v-for="(item, i) in items_"
          :key="'date' + i"
          class="m-1"
        >
          <v-col :cols="12" :md="2" :sm="4">
            <v-datetime-picker
              label="Dátum -tól"
              :timePicker="false"
              :datePicker="true"
              v-model="item.date_from"
              :error-messages="errors['item__date_from' + item.id]"
            />
          </v-col>
          <v-col :cols="12" :md="2" :sm="4">
            <v-datetime-picker
              label="Időpont -tól"
              :timePicker="true"
              :datePicker="false"
              v-model="item.time_from"
              :error-messages="errors['item__time_from' + item.id]"
            />
          </v-col>
          <v-col :cols="12" :md="2" :sm="4">
            <v-datetime-picker
              label="Dátum -ig"
              :timePicker="false"
              :datePicker="true"
              v-model="item.date_to"
              :error-messages="errors['item__date_to' + item.id]"
            />
          </v-col>
          <v-col :cols="12" :md="2" :sm="4">
            <v-datetime-picker
              label="Időpont -ig"
              :timePicker="true"
              :datePicker="false"
              v-model="item.time_to"
              :error-messages="errors['item__time_to' + item.id]"
            />
          </v-col>
          <v-col :cols="12" :md="2" :sm="4">
            <v-switch
              label="Zárva"
              v-model="item.close.selected"
              :error-messages="errors['item__close' + item.id]"
            />
          </v-col>
          <v-col :cols="12" :md="2" :sm="4" class="d-flex align-items-center">
            <v-btn
              width="30"
              height="30"
              min-height="30"
              min-width="30"
              color="accent"
              @click="removeItem_(item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="m-1">
          <v-col :cols="12" :md="2" :sm="4">
            <v-datetime-picker
              label="Dátum -tól"
              :timePicker="false"
              :datePicker="true"
              v-model="newItem_.date_from"
              ref="newItem__date_from"
              :error-messages="errors.new_item__date_from"
            />
          </v-col>
          <v-col :cols="12" :md="2" :sm="4">
            <v-datetime-picker
              label="Időpont -tól"
              :timePicker="true"
              :datePicker="false"
              ref="newItem__time_from"
              v-model="newItem_.time_from"
              :error-messages="errors.new_item__time_from"
            />
          </v-col>
          <v-col :cols="12" :md="2" :sm="4">
            <v-datetime-picker
              label="Dátum -ig"
              :timePicker="false"
              :datePicker="true"
              v-model="newItem_.date_to"
              ref="newItem__date_to"
              :error-messages="errors.new_item__date_to"
            />
          </v-col>
          <v-col :cols="12" :md="2" :sm="4">
            <v-datetime-picker
              label="Időpont -ig"
              :timePicker="true"
              :datePicker="false"
              ref="newItem__time_to"
              v-model="newItem_.time_to"
              :error-messages="errors.new_item__time_to"
            />
          </v-col>
          <v-col :cols="12" :md="2" :sm="4">
            <v-switch
              label="Zárva"
              v-model="newItem_.close.selected"
              :error-messages="errors.new_item__close"
            />
          </v-col>
          <v-col :cols="12" :md="2" :sm="4" class="d-flex align-items-center">
            <v-btn
              width="30"
              height="30"
              min-height="30"
              min-width="30"
              color="accent"
              @click="addItem_"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row> -->
      <!-- </v-card-text>
    </v-card> -->
  </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
export default {
  data() {
    return {
      openWarning: false,
      elementTypes: [],
      elementTypeId: 0,
      elements: [],
      elementId: 0,
      items: [],
      items_: [],
      errors: [],
      newItem: {
        id: 0,
        day: 0,
        time_from: '00:00',
        time_to: '00:00',
        added: 1,
      },
      newItem_: {
        id: 0,
        date_from: '',
        date_to: '',
        time_from: '00:00',
        time_to: '00:00',
        added: 1,
        close: {
          value: 1,
          selected: false,
        },
      },
      days: [
        { value: 0, text: 'Hétfő' },
        { value: 1, text: 'Kedd' },
        { value: 2, text: 'Szerda' },
        { value: 3, text: 'Csütörtök' },
        { value: 4, text: 'Péntek' },
        { value: 5, text: 'Szombat' },
        { value: 6, text: 'Vasárnap' },
      ],
      loading: false,
    };
  },

  async mounted() {
    this.fetchElementTypes();
  },

  methods: {
    async fetchElementTypes() {
      try {
        const response = await this.$http.post('element-types/list', {
          codes: ['AJTO', 'SOROMPO', 'FORGOVILLA'],
        });
        this.elementTypes = [...this.elementTypes, ...response.element_types];
      } catch (e) {
        console.error(e);
      }
    },

    async fetchElements() {
      this.elementId = 0;
      this.elements = [];
      this.items = [];
      this.items_ = [];
      if (this.elementTypeId) {
        try {
          const response = await this.$http.get(`openings/list/elements/${this.elementTypeId}`);
          this.elements = response.elements;
        } catch (e) {
          console.error(e);
        }
      }
    },

    async fetchDates() {
      this.errors = [];
      this.items = [];
      this.items_ = [];
      this.openWarning = false;
      if (this.elementId) {
        try {
          const response = await this.$http.get(`openings/list/dates/${this.elementId}`);
          this.items = response.items;
          this.items_ = response.items_;
          if (response.open_warning == '1') {
            this.openWarning = true;
          }
        } catch (e) {
          console.error(e);
        }
      }
    },

    removeItem(item) {
      Vue.set(item, 'deleted', 1);
      Vue.delete(item, 'added');
    },

    removeItem_(item) {
      Vue.set(item, 'deleted', 1);
      Vue.delete(item, 'added');
    },

    addItem() {
      this.errors = [];

      if (!moment(this.newItem.time_from, 'HH:mm', true).isValid()) {
        this.errors.new_item_time_from = 'Hibás időpont';
      }

      if (!moment(this.newItem.time_to, 'HH:mm', true).isValid()) {
        this.errors.new_item_time_to = 'Hibás időpont';
      }

      if (
        this.errors.length == 0 &&
        moment(this.newItem.time_from, 'HH:mm', true).isAfter(
          moment(this.newItem.time_to, 'HH:mm', true)
        )
      ) {
        this.errors.new_item_time_from = 'Hibás időpont';
      }

      if (!Object.keys(this.errors).length) {
        this.items.push(JSON.parse(JSON.stringify(this.newItem)));

        this.newItem = {
          id: this.$root.generateGUID(),
          day: 0,
          time_from: '00:00',
          time_to: '00:00',
          added: 1,
        };

        // this.$refs["new_item_time_from"].reinitFlatpickr();
        // this.$refs["new_item_time_to"].reinitFlatpickr();
      }
    },

    addItem_() {
      var _this = this;
      this.errors = [];

      if (!moment(this.newItem_.date_from, 'YYYY-MM-DD', true).isValid()) {
        this.errors.new_item__date_from = 'Hibás dátum';
      }

      if (!moment(this.newItem_.date_to, 'YYYY-MM-DD', true).isValid()) {
        this.errors.new_item__date_to = 'Hibás dátum';
      }

      if (!moment(this.newItem_.time_from, 'HH:mm', true).isValid()) {
        this.errors.new_item__time_from = 'Hibás időpont';
      }

      if (!moment(this.newItem_.time_to, 'HH:mm', true).isValid()) {
        this.errors.new_item__time_to = 'Hibás időpont';
      }

      if (
        this.errors.length == 0 &&
        moment(
          this.newItem_.date_from + ' ' + this.newItem_.time_from,
          'YYYY-MM-DD HH:mm',
          true
        ).isAfter(
          moment(this.newItem_.date_to + ' ' + this.newItem_.time_to, 'YYYY-MM-DD HH:mm', true)
        )
      ) {
        this.errors.new_item__time_from = 'Hibás időpont';
      }

      if (!Object.keys(this.errors).length) {
        this.items_.push(JSON.parse(JSON.stringify(this.newItem_)));
        this.newItem_ = {
          id: this.$root.generateGUID(),
          date_from: '',
          date_to: '',
          time_from: '00:00',
          time_to: '00:00',
          close: {
            value: 1,
            selected: false,
          },
          added: 1,
        };

        // this.$refs["new_item__date_from"].reinitFlatpickr();
        // this.$refs["new_item__date_to"].reinitFlatpickr();
        // this.$refs["new_item__time_from"].reinitFlatpickr();
        // this.$refs["new_item__time_to"].reinitFlatpickr();
      }
    },

    async save() {
      console.log(this.elementTypeId);
      this.errors = [];

      this.items.map(function (item, i) {
        if (item.deleted == undefined) {
          if (!moment(item.time_from, 'HH:mm', true).isValid()) {
            this.errors.item_time_from_ = 'Hibás időpont';
          }

          if (!moment(item.time_to, 'HH:mm', true).isValid()) {
            this.errors.item_time_to_ = 'Hibás időpont';
          }

          if (
            !Object.keys(this.errors).length &&
            moment(item.time_from, 'HH:mm', true).isAfter(moment(item.time_to, 'HH:mm', true))
          ) {
            this.errors.item_time_from_ = 'Hibás időpont';
          }
        }
      });

      this.items_.map(function (item, i) {
        if (item.deleted == undefined) {
          if (!moment(item.date_from, 'YYYY-MM-DD', true).isValid()) {
            this.errors.item__date_from_ = 'Hibás dátum';
          }

          if (!moment(item.date_to, 'YYYY-MM-DD', true).isValid()) {
            this.errors.item__date_to_ = 'Hibás dátum';
          }

          if (!moment(item.time_from, 'HH:mm', true).isValid()) {
            this.errors.item__time_from_ = 'Hibás időpont';
          }

          if (!moment(item.time_to, 'HH:mm', true).isValid()) {
            this.errors.item__time_to_ = 'Hibás időpont';
          }

          if (
            !Object.keys(this.errors).length &&
            moment(item.date_from + ' ' + item.time_from, 'YYYY-MM-DD HH:mm', true).isAfter(
              moment(item.date_to + ' ' + item.time_to, 'YYYY-MM-DD HH:mm', true)
            )
          ) {
            this.errors.item__date_from_ = 'Hibás dátum és időpont';
          }
        }
      });

      if (!Object.keys(this.errors).length) {
        try {
          var data = {
            items: this.items,
            items_: this.items_,
            element_id: this.elementId,
          };
          const response = await this.$http.post(`openings/save`, data);
          var tmpElementId = this.elementId;
          this.fetchElements();
          this.elementId = tmpElementId;
          this.fetchDates();
        } catch (e) {
          console.error(e);
        }
      }
    },
  },
};
</script>
