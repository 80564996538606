var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.form != undefined ? _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_c('v-btn', _vm._b({
    attrs: {
      "color": "grey"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-arrow-left")])], 1), _c('v-btn', _vm._b({
    attrs: {
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-content-save")])], 1)], 1), _vm.$route.name === 'NewController' ? _c('h1', {
    staticClass: "my-6"
  }, [_vm._v("Új kontroller")]) : _c('h1', {
    staticClass: "my-6"
  }, [_vm._v("Kontroller szerkesztése")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 12,
      "lg": 6
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("Kontroller adatok")]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "Megnevezés",
      "error-messages": _vm.errors.name,
      "filled": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Alias",
      "error-messages": _vm.errors.alias,
      "filled": ""
    },
    model: {
      value: _vm.form.alias,
      callback: function ($$v) {
        _vm.$set(_vm.form, "alias", $$v);
      },
      expression: "form.alias"
    }
  }), _c('v-select', {
    attrs: {
      "label": "Típus",
      "error-messages": _vm.errors.type_id,
      "items": _vm.types,
      "item-text": "name",
      "item-value": "id",
      "filled": "",
      "value-comparator": _vm.$config.comparators.isEqual
    },
    model: {
      value: _vm.form.type_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "type_id", $$v);
      },
      expression: "form.type_id"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "label": "Kapcsolat",
      "error-messages": _vm.errors.connection_id,
      "items": _vm.connections,
      "item-text": "name",
      "item-value": "element_id",
      "filled": "",
      "value-comparator": _vm.$config.comparators.isEqual
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.name) + " (" + _vm._s(item.ip) + ") ")];
      }
    }, {
      key: "item",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.name) + " (" + _vm._s(item.ip) + ") ")];
      }
    }], null, false, 2585703452),
    model: {
      value: _vm.form.connection_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "connection_id", $$v);
      },
      expression: "form.connection_id"
    }
  }), _c('v-masked-text-field', {
    attrs: {
      "label": "Sorozatszám",
      "error-messages": _vm.errors.serial_number,
      "filled": ""
    },
    model: {
      value: _vm.form.serial_number,
      callback: function ($$v) {
        _vm.$set(_vm.form, "serial_number", $$v);
      },
      expression: "form.serial_number"
    }
  }), _c('v-switch', {
    attrs: {
      "label": "Engedélyezés",
      "error-messages": _vm.errors.enabled,
      "true-value": 1,
      "false-value": 0
    },
    model: {
      value: _vm.form.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.form, "enabled", $$v);
      },
      expression: "form.enabled"
    }
  }), _c('v-textarea', {
    attrs: {
      "label": "Megjegyzés",
      "error-messages": _vm.errors.comment,
      "filled": ""
    },
    model: {
      value: _vm.form.comment,
      callback: function ($$v) {
        _vm.$set(_vm.form, "comment", $$v);
      },
      expression: "form.comment"
    }
  })], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "lg": 6
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("Helyadatok")]), _c('v-card-text', [_c('v-autocomplete', {
    attrs: {
      "label": "Épület",
      "error-messages": _vm.errors.building_id,
      "items": _vm.buildings,
      "item-text": "name",
      "item-value": "id",
      "filled": "",
      "value-comparator": _vm.$config.comparators.isEqual
    },
    model: {
      value: _vm.form.building_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "building_id", $$v);
      },
      expression: "form.building_id"
    }
  }), _c('v-select', {
    attrs: {
      "label": "Épület szint",
      "error-messages": _vm.errors.building_level_id,
      "items": _vm.buildingLevels,
      "item-text": "name",
      "item-value": "id",
      "value-comparator": _vm.$config.comparators.isEqual,
      "filled": "",
      "disabled": !_vm.form.building_id
    },
    model: {
      value: _vm.form.building_level_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "building_level_id", $$v);
      },
      expression: "form.building_level_id"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Épület típus",
      "error-messages": _vm.errors.building_type,
      "filled": "",
      "placeholder": "B: épület, P: parkoló, BP vagy PB: mindkettő"
    },
    model: {
      value: _vm.form.building_type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "building_type", $$v);
      },
      expression: "form.building_type"
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c('v-masked-text-field', {
    attrs: {
      "label": "X koordináta",
      "error-messages": _vm.errors.x_coord,
      "filled": "",
      "mask": _vm.$config.inputMasks.floatNumber
    },
    model: {
      value: _vm.form.x_coord,
      callback: function ($$v) {
        _vm.$set(_vm.form, "x_coord", $$v);
      },
      expression: "form.x_coord"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c('v-masked-text-field', {
    attrs: {
      "label": "Y koordináta",
      "error-messages": _vm.errors.y_coord,
      "filled": "",
      "mask": _vm.$config.inputMasks.floatNumber
    },
    model: {
      value: _vm.form.y_coord,
      callback: function ($$v) {
        _vm.$set(_vm.form, "y_coord", $$v);
      },
      expression: "form.y_coord"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }