<template>
  <div v-if="this.$route.params.id == undefined">
    <div class="d-flex justify-end sticky to-header">
      <v-btn
        v-if="hasRight('QR_WRITE')"
        v-bind="$config.buttonAttrs.floating"
        @click="showRequestDialog()"
        title="Új igénylés"
      >
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">QR kód igénylések</h1>

    <v-card>
      <v-data-table
        :loading="loading"
        :headers="dataTable.headers"
        :items="dataTable.items"
        :server-items-length="dataTable.itemsLength"
        :options.sync="dataTable.options"
        :footer-props="$config.dataTableFooterProps"
        :item-class="itemClass"
        calculate-widths
      >
        <template #[`item.user`]="{ item }">
          {{ item.user.name }}
        </template>
        <template #[`item.groups`]="{ item }">
          <v-chip-group>
            <v-chip
              small
              label
              link
              v-for="group in item.groups"
              :key="group.id"
              :color="group.color"
              :to="{ name: 'GroupEditor', params: { id: group.element_id } }"
              class="ma-1"
            >
              {{ group.name }}
            </v-chip>
          </v-chip-group>
        </template>
        <template #[`item.amount`]="{ item }">
          {{ item.email_sent_amount }} / {{ item.amount }}
        </template>
        <template #[`item.approved`]="{ item }">
          <v-icon v-if="item.approved == 1" color="success">mdi-checkbox-marked</v-icon>
          <v-icon v-else color="error">mdi-close-box</v-icon>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn
            v-if="item.approved == 0 && item.user_id == $store.state.user.element_id"
            @click="showRequestDialog(item.id)"
            fab
            small
            color="secondary"
            text
            class="rounded-sm m-1"
            title="Igénylés módosítása"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="item.approved == 1"
            title="Vonalkódok beállítása"
            fab
            small
            color="secondary"
            text
            class="rounded-sm m-1"
            :to="{ name: 'QrCodeRequestEditor', params: { id: item.id } }"
          >
            <v-icon>mdi-cog</v-icon>
          </v-btn>
          <v-btn
            v-if="item.approved == 0 && item.user_id == $store.state.user.element_id"
            @click="deleteRequest(item)"
            title="Igénylés törlése"
            fab
            small
            color="secondary"
            text
            class="rounded-sm m-1"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <request-dialog
      v-if="requestDialog.visible"
      :request-id="requestDialog.requestID"
      @close="hideRequestDialog"
      @save="requestDialogSaveEventHandler"
    />
  </div>
</template>

<script>
import { dataTablePage } from '@/mixins';
import RequestDialog from './RequestDialog.vue';

export default {
  mixins: [dataTablePage],

  components: {
    RequestDialog,
  },

  data() {
    return {
      routeName: 'QrCodeRequestList',
      fetchItems: this.fetchRequests,
      dataTable: {
        options: {
          sortBy: ['request_date'],
          sortDesc: [true],
        },
        headers: [
          { text: 'Igénylő', value: 'user', sortName: 'Users.name' },
          { text: 'Igénylés dátuma', value: 'request_date' },
          { text: 'Csoport', value: 'groups', sortable: false },
          { text: 'Mennyiség', value: 'amount' },
          { text: 'Megjegyzés', value: 'comment' },
          { text: 'Jóváhagyva', value: 'approved', align: 'center' },
          { text: 'Jováhagyás dátuma', value: 'approved_date' },
          { text: '', value: 'actions', sortable: false, align: 'end' },
        ],
      },
      search: {},
      unapprovedAmount: 0,
      requestDialog: {
        visible: false,
        requestID: null,
      },
    };
  },

  methods: {
    async fetchRequests() {
      this.loading = true;
      this.requestId = 0;
      try {
        const response = await this.$http.post(
          `qr-code-requests/list/${this.$store.state.user.element_id}`,
          this.postData
        );
        this.dataTable.items = response.requests;
        this.dataTable.itemsLength = response.requests_count;
        this.dataTable.options.page = this.$route.query.page * 1 || 1;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    parseSearchQueries() {
      return {};
    },

    async deleteRequest(item) {
      const confirm = await this.$dialog.confirm({
        type: 'error',
        text: `Az igénylés törlésére készül!<br>Folytatja?`,
        title: 'Figyelem',
      });

      if (!confirm) return;

      try {
        const response = await this.$http.post(`qr-code-requests/delete/${item.id}`);
        this.fetchItems();
        if (response.status === 'OK') {
          this.$dialog.notify.info('Az igénylés törölve');
        }
      } catch (e) {
        console.error(e);
      }
    },

    showRequestDialog(request = 0) {
      this.requestDialog.visible = true;
      this.requestDialog.requestID = request;
    },

    hideRequestDialog() {
      this.requestDialog.visible = false;
      this.requestDialog.requestID = false;
    },

    async requestDialogSaveEventHandler(request) {
      await this.fetchItems();
      this.dataTable.highlighted = [Number(request.id)];
    },
  },

  computed: {
    searchQueries() {
      return {};
    },
  },
};
</script>
