var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_c('v-btn', _vm._b({
    attrs: {
      "color": "grey"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-arrow-left")])], 1), _c('v-btn', _vm._b({
    attrs: {
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-content-save")])], 1)], 1), _vm.$route.name === 'NewConnection' ? _c('h1', {
    staticClass: "my-6"
  }, [_vm._v("Új kapcsolat")]) : _c('h1', {
    staticClass: "my-6"
  }, [_vm._v("Kapcsolat szerkesztése")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 12,
      "lg": 6
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("Alapadatok")]), _c('v-card-text', [_c('v-masked-text-field', {
    attrs: {
      "label": "Megnevezés",
      "error-messages": _vm.errors.name,
      "filled": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c('v-select', {
    attrs: {
      "label": "Átjáró",
      "error-messages": _vm.errors.gateway_id,
      "items": _vm.gateways,
      "item-text": "name",
      "item-value": "element_id",
      "filled": "",
      "value-comparator": _vm.$config.comparators.isEqual
    },
    model: {
      value: _vm.form.gateway_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "gateway_id", $$v);
      },
      expression: "form.gateway_id"
    }
  }), _c('v-masked-text-field', {
    attrs: {
      "label": "RS232 Port név",
      "error-messages": _vm.errors.rs232_port_dev_name,
      "filled": ""
    },
    model: {
      value: _vm.form.rs232_port_dev_name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "rs232_port_dev_name", $$v);
      },
      expression: "form.rs232_port_dev_name"
    }
  }), _c('v-masked-text-field', {
    attrs: {
      "label": "RS232 Baud rate",
      "error-messages": _vm.errors.rs232_baud_rate,
      "filled": "",
      "mask": _vm.$config.inputMasks.createNumberMask({
        min: 0,
        max: 921600
      })
    },
    model: {
      value: _vm.form.rs232_baud_rate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "rs232_baud_rate", $$v);
      },
      expression: "form.rs232_baud_rate"
    }
  }), _c('v-masked-text-field', {
    attrs: {
      "label": "Ip",
      "error-messages": _vm.errors.ip,
      "filled": "",
      "mask": _vm.$config.inputMasks.ipAddress
    },
    model: {
      value: _vm.form.ip,
      callback: function ($$v) {
        _vm.$set(_vm.form, "ip", $$v);
      },
      expression: "form.ip"
    }
  }), _c('v-masked-text-field', {
    attrs: {
      "label": "Port",
      "error-messages": _vm.errors.port,
      "filled": "",
      "mask": _vm.$config.inputMasks.createNumberMask({
        min: 1,
        max: 65535
      }),
      "placeholder": "1-65535"
    },
    model: {
      value: _vm.form.port,
      callback: function ($$v) {
        _vm.$set(_vm.form, "port", $$v);
      },
      expression: "form.port"
    }
  }), _c('v-textarea', {
    attrs: {
      "label": "Megjegyzés",
      "error-messages": _vm.errors.comment,
      "filled": "",
      "rows": "3",
      "auto-grow": ""
    },
    model: {
      value: _vm.form.comment,
      callback: function ($$v) {
        _vm.$set(_vm.form, "comment", $$v);
      },
      expression: "form.comment"
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }