<template>
  <vue-scroll style="height: 100vh">
    <section style="height: 100vh" class="d-flex flex-column">
      <v-card color="info">
        <v-card-title>
          Test panel
          <v-divider></v-divider>
          <v-btn class="mx-2" @click="simulateRead('ABCDEF123456')">Existing key</v-btn>
          <v-btn class="mx-2" @click="simulateRead('ABCDEF12345')">Not existing key</v-btn>
          <v-divider></v-divider>
          <v-btn class="mx-2" @click="simulateRead('FD1F4323')">Allowed card</v-btn>
          <v-btn class="mx-2" @click="simulateRead('E87138C7')">Disallowed card</v-btn>
          <v-btn class="mx-2" @click="simulateRead('FD1F432')">Not existing card</v-btn>
        </v-card-title>
      </v-card>

      <v-expand-transition>
        <v-card
          :loading="loading"
          class="mx-auto my-auto"
          width="960"
          elevation="0"
          color="transparent"
        >
          <v-card-text>
            <v-row class="d-flex justify-center">
              <v-expand-transition>
                <v-col cols="6" v-if="card">
                  <v-card
                    outlined
                    class="d-flex flex-column justify-center align-center"
                    height="300px"
                    @click="toggleCard"
                  >
                    <v-card-title>
                      <v-icon left>mdi-card-account-details</v-icon>
                      Kártya
                    </v-card-title>
                    <v-card-text v-if="!card">Érintse a kártyát az olvasóhoz!</v-card-text>
                    <v-card outlined v-else>
                      <v-card-text class="d-flex align-center">
                        <v-avatar size="120" color="grey ligthen-2" rounded>
                          <v-icon size="80">mdi-account</v-icon>
                        </v-avatar>
                        <div class="ml-3 d-flex flex-column justify-center align-start">
                          <div class="text-h6">{{ card.owner?.name }}</div>
                          <div class="my-2">Kártyaszám: {{ card.card_number }}</div>
                          <div>{{ card.card_rule_name_hu }}</div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-card>
                </v-col>
              </v-expand-transition>
              <v-expand-transition>
                <v-col cols="6" v-if="key">
                  <v-card
                    outlined
                    class="d-flex flex-column justify-center align-center"
                    height="300px"
                    @click="toggleKey"
                  >
                    <v-card-title>
                      <v-icon left>mdi-key</v-icon>
                      Kulcs
                    </v-card-title>
                    <v-card-text v-if="!key"> Érintse a kulcsot az olvasóhoz! </v-card-text>
                    <v-card outlined v-else>
                      <v-card-text class="d-flex flex-column justify-center align-start">
                        <div class="text-h6">{{ key.name }}</div>
                        <div class="my-2">Kulcsszám: {{ key.keyNumber }}</div>
                        <div class="my-2">
                          {{ key.building }} épület {{ key.floor }}. emelet {{ key.room }}. szoba
                        </div>
                        <div>Megjegyzés: {{ key.remark }}</div>
                      </v-card-text>
                    </v-card>
                  </v-card>
                </v-col>
              </v-expand-transition>

              <v-expand-transition>
                <v-col
                  v-if="response"
                  cols="12"
                  class="text-center d-flex flex-column align-center"
                >
                  <v-alert :type="response.color" prominent>
                    {{ response.message }}
                  </v-alert>
                </v-col>
              </v-expand-transition>

              <v-expand-transition>
                <v-col
                  v-if="card || key"
                  cols="12"
                  class="text-center d-flex flex-column align-center"
                >
                  <v-btn large color="primary" @click="reset"> Visszaállítás </v-btn>
                </v-col>
              </v-expand-transition>
            </v-row>
          </v-card-text>
        </v-card>
      </v-expand-transition>
    </section>
  </vue-scroll>
</template>

<script>
const RESPONSES = {
  allowed: {
    color: 'success',
    message: 'A kártya tulajdonosa jogosult a kulcs használatára.',
  },
  disallowed: {
    color: 'error',
    message: 'A kártya tulajdonosa nem jogosult a kulcs használatára.',
  },
};

export default {
  data() {
    return {
      card: null,
      key: null,
      response: null,
      loading: false,
    };
  },

  methods: {
    reset() {
      this.card = null;
      this.key = null;
      this.response = null;
    },
    toggleCard() {
      this.card = {
        ownerName: 'Kiss-Mihály Áron',
        cardNumber: '10015467',
        cardType: 'Vendég',
      };
    },
    toggleKey() {
      this.key = {
        name: 'MC tárgyaló',
        keyNumber: '80210213',
        building: 'MC',
        floor: '2',
        room: '213',
        remark: 'Ne add oda annak a baromnak!',
      };
      this.loading = true;
      // setTimeout(() => {
      //   this.loading = false;
      //   this.response = RESPONSES.SUCCESS;
      // }, 1000);
    },

    async checkAccess() {
      const res = await this.$http.post('/api/keys/check-access', {
        card_element_id: this.card.element_id,
        key_id: this.key.id,
      });
      if (res.allowed) {
        this.response = RESPONSES.allowed;
      } else {
        this.response = RESPONSES.disallowed;
      }
    },

    // test methods
    async simulateRead(rfid) {
      try {
        this.loading = true;
        const response = await this.$http.get(`/api/keys/lookup/${rfid}`);
        if (!response.key && !response.card) {
          throw Error('Ez a kártya vagy kulcs nincs a rendszerben!<br>Kérjük próbálja újra!');
        }

        this.card = response.card ?? this.card;
        this.key = response.key ?? this.key;

        if (this.card && this.key) {
          this.checkAccess();
        }
      } catch (err) {
        this.$dialog.notify.error(err.message, {
          position: 'top-right',
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
