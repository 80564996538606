<template>
  <div>
    <h1 class="py-6">Érvényes vonalkódok</h1>
    <!--<TabulatorTable
      v-if="barcodes.length > 0"
      v-model="barcodes"
      :options="tabulator_opts"
    />-->
    <v-expansion-panels class="mb-4" :mandatory="!!Object.values(search).find((e) => e !== '')">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-filter</v-icon>
            Szűrés
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mt-2">
            <v-col :cols="12" :sm="4">
              <v-masked-text-field
                v-model="search.barcode_number"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                label="Vonalkód"
                dense
                clearable
                hide-details
                class="my-4 my-sm-0"
                :mask="$config.inputMasks.barcodeNumber"
                v-uppercase
              />
            </v-col>
            <v-col :cols="12" :sm="4">
              <v-masked-text-field
                v-model="search.name"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                label="Név"
                dense
                clearable
                hide-details
                class="my-4 my-sm-0"
                :mask="$config.inputMasks.name"
              />
            </v-col>
            <v-col :cols="12" :sm="4">
              <v-masked-text-field
                v-model="search.email"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                label="E-mail cím"
                dense
                clearable
                hide-details
                class="my-4 my-sm-0"
                autocomplete="username"
                :mask="$config.inputMasks.email"
                v-lowercase
              />
            </v-col>
            <!-- <v-col :cols="12" :sm="4" :md="2">
              <v-text-field
                v-model="search.starDateTime"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                label="Kezdő dátum"
                dense
                clearable
                hide-details
                class="my-4 my-sm-0"
                autocomplete="username"
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="2">
              <v-text-field
                v-model="search.period"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                label="Periódus (óra)"
                dense
                clearable
                hide-details
                class="my-4 my-sm-0"
                autocomplete="username"
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="2">
              <v-text-field
                v-model="search.endDate"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                label="Vég dátum"
                dense
                clearable
                hide-details
                class="my-4 my-sm-0"
                autocomplete="username"
              />
            </v-col> -->
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card :loading="loading">
      <v-data-table
        :headers="dataTable.headers"
        :items="dataTable.items"
        :server-items-length="dataTable.itemsLength"
        :options.sync="dataTable.options"
        :footer-props="$config.dataTableFooterProps"
      >

      </v-data-table>
      <v-divider class="mt-0"></v-divider>
      <div class="px-4">
        <pagination
          v-if="search.amount"
          :showcombo="true"
          :limit="search.limit"
          :amount="search.amount"
          v-model="search.page"
          @pageChanged="pageChanged"
        ></pagination>
      </div>
    </v-card>
  </div>
</template>

<script>
import { dataTablePage } from '@/mixins';

export default {
  mixins: [dataTablePage],

  data() {
    return {
      routeName: 'BarcodeList',
      fetchItems: this.fetchBarcodes,
      dataTable: {
        options: {
          sortBy: ['barcode_number'],
          sortDesc: [true],
        },
        headers: [
        { text: 'Vonalkód', value: 'barcode_number' },
        { text: 'Felhasználó név', value: 'name' },
        { text: 'Felhasználó email', value: 'email' },
        { text: 'Kezdő dátum', value: 'start_date_time' },
        { text: 'Periódus (óra)', value: 'period' },
        { text: 'Vég dátum', value: 'end_date'},
        ],
      },
      search: {
        barcode_number: '',
        name: '',
        email: '',
        // start_date_time: '',
        // period: '',
        // endDate: '',
      },
      searchTimeout: null,
    };
  },

  methods: {
    parseSearchQueries() {
      return {
        barcode_number: this.$route.query.barcode_number,
        name: this.$route.query.name,
        email: this.$route.query.email,
        // start_date_time: this.$route.query.start_date_time,
        // period: this.$route.query.period,
        // endDate: this.$route.query.endDate,
      };
    },

    async fetchBarcodes() {
      this.loading = true;
      const data = JSON.parse(JSON.stringify(this.search));
      data.sort_by = this.options.sortBy || [''];
      data.sort_desc = this.options.sortDesc || [false];
      try {
        const response = await this.$http.post(
          "barcodes/list",
          this.options
        );
        this.dataTable.items = response.barcodes;
        this.dataTable.itemsLength = response.barcodes_count;
        this.dataTable.options.page = Number(this.$route.query.page) || 1;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {
    searchQueries() {
      return {
        barcode_number: this.search.barcode_number || '',
        name: this.search.name || '',
        email: this.search.email || '',
        // start_date_time: this.search.start_date_time || '',
        // period: this.search.period || '',
        // endDate: this.search.endDate || '',
      };
    }
  }
};
</script>
