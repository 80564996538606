import store from '@/store';

async function fetchRights() {
  if (window.vue.$store.state.user) {
    const response = await window.vue.$http.get(
      `users/details/${window.vue.$store.state.user.element_id}`
    );
    window.vue.$store.commit('setUser', response.user);
    window.vue.$store.commit('setRights', response.rights);
    window.vue.$store.commit('setUserConfig', response.user?.config);
  }
}

async function requireAuth(to, from, next) {
  try {
    // fetchRights();
    if (!window.vue.$store.state.token) {
      throw 'Token is undefined';
    }

    const response = await window.vue.$http.get('auth/validate');

    if (response.status == 'OK') {
      window.vue.$store.commit('setUser', response.user);
      window.vue.$store.commit('setRights', response.rights);
      window.vue.$store.commit('setFeatures', response.features);
      window.vue.$store.commit('setUserConfig', response.user?.config);
      next();
    } else {
      throw 'Kérjük jelentkezzen be újra!';
    }
  } catch (e) {
    next({
      name: 'Login',
      params: {
        redirect: to.fullPath,
        error: e,
      },
    });
  }
}

import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '@/variants/sze/pages/home/Home.vue';
import Login from '@/variants/sze/pages/login/Login.vue';
import Logout from '@/pages/logout/logout.vue';
import Message from '@/pages/message/message.vue';

import DashboardLayout from '@/layouts/DashboardLayout.vue';
import EmptyLayout from '@/layouts/EmptyLayout.vue';

Vue.use(VueRouter);

console.log(process.env.BASE_URL);

const router = new VueRouter({
  base: process.env.BASE_URL || '',
  mode: 'history',
  routes: [
    {
      path: '/login',
      component: Login,
      name: 'Login',
      meta: {
        title: 'Bejelentkezés',
      },
    },
    {
      path: '/logout',
      component: Logout,
      name: 'Logout',
    },
    {
      path: '/kulcsfoglalas',
      component: () =>
        import(/* webpackChunkName: "KeyReservationPublic" */ '@/variants/sze/pages/key-reservations/Calendar.vue'),
      name: 'KeyReservationPublic',
    },
    {
      path: '/',
      component: DashboardLayout,
      beforeEnter: requireAuth,
      children: [
        {
          path: 'msg',
          component: Message,
          name: 'Message',
        },
        {
          path: '',
          component: EmptyLayout,
          children: [
            {
              path: 'home',
              component: Home,
              name: 'Home',
              meta: {
                title: 'Főoldal',
              },
            },
            {
              path: '',
              redirect: { name: 'Home' },
            },
          ],
        },
        {
          path: 'access-levels',
          name: 'AccessLevels',
          component: EmptyLayout,
          redirect: 'access-levels/list',
          children: [
            {
              path: 'list',
              component: () =>
                import(/* webpackChunkName: "AccessLevelList" */ '@/pages/access-levels/List.vue'),
              name: 'AccessLevelList',
              meta: {
                title: 'Hozzáférési szintek',
              },
            },
            {
              path: 'new',
              component: () =>
                import(
                  /* webpackChunkName: "AccessLevelEditor" */ '@/pages/access-levels/Editor.vue'
                ),
              name: 'NewAccessLevel',
              meta: {
                title: 'Új hozzáférési szint',
              },
            },
            {
              path: 'edit/:id',
              component: () =>
                import(
                  /* webpackChunkName: "AccessLevelEditor" */ '@/pages/access-levels/Editor.vue'
                ),
              name: 'AccessLevelEditor',
              meta: {
                title: 'Hozzáférési szint',
              },
            },
          ],
        },
        {
          path: 'qr-codes',
          name: 'QrCodes',
          component: EmptyLayout,
          redirect: 'qr-codes/list',
          children: [
            {
              path: 'list',
              component: () =>
                import(/* webpackChunkName: "QrCodeList" */ '@/pages/qr-codes/List.vue'),
              name: 'QrCodeList',
              meta: {
                title: 'QR kódok',
              },
            },
            {
              path: 'approval',
              component: () =>
                import(
                  /* webpackChunkName: "QrCodeApprovalList" */ '@/pages/qr-codes/approval/List.vue'
                ),
              name: 'QrCodeApprovalList',
              meta: {
                title: 'QR kódok jóváhagyása',
              },
            },
            {
              path: 'requests',
              component: EmptyLayout,
              redirect: 'requests/list',
              children: [
                {
                  path: 'list',
                  component: () =>
                    import(
                      /* webpackChunkName: "QrCodeRequestList" */ '@/pages/qr-codes/requests/List.vue'
                    ),
                  name: 'QrCodeRequestList',
                  meta: {
                    title: 'QR kód igénylések',
                  },
                },
                {
                  path: 'edit/:id',
                  component: () =>
                    import(
                      /* webpackChunkName: "QrCodeRequestEditor" */ '@/pages/qr-codes/requests/Editor.vue'
                    ),
                  name: 'QrCodeRequestEditor',
                  meta: {
                    title: 'QR kód igénylés',
                  },
                },
              ],
            },
          ],
        },
        {
          path: 'cards',
          name: 'Cards',
          component: EmptyLayout,
          redirect: 'cards/list',
          children: [
            {
              path: 'list',
              component: () =>
                import(/* webpackChunkName: "CardList" */ '@/variants/sze/pages/cards/List'),
              name: 'CardList',
              meta: {
                title: 'Kártyák',
              },
            },
            {
              path: 'edit/:id',
              component: () =>
                import(/* webpackChunkName: "CardEditor" */ '@/variants/sze/pages/cards/Editor'),
              name: 'CardEditor',
              meta: {
                title: 'Kártya',
              },
            },
            {
              path: 'new',
              component: () =>
                import(/* webpackChunkName: "CardEditor" */ '@/variants/sze/pages/cards/Editor'),
              name: 'NewCard',
              meta: {
                title: 'Kártya létrehozása',
              },
            },
          ],
        },
        {
          path: 'card-owners',
          name: 'CardOwners',
          component: EmptyLayout,
          redirect: 'card-owners/list',
          children: [
            {
              path: 'list',
              component: () =>
                import(/* webpackChunkName: "CardOwnerList" */ '@/pages/card-owners/List'),
              name: 'CardOwnerList',
              meta: {
                title: 'Kártyabirtokosok',
              },
            },
            {
              path: 'edit/:id',
              component: () =>
                import(/* webpackChunkName: "CardOwnerEditor" */ '@/pages/card-owners/Editor'),
              name: 'CardOwnerEditor',
              meta: {
                title: 'Kártyabirtokos',
              },
            },
            {
              path: 'new',
              component: () =>
                import(/* webpackChunkName: "CardOwnerEditor" */ '@/pages/card-owners/Editor'),
              name: 'NewCardOwner',
              meta: {
                title: 'Kártyabirtokos létrehozása',
              },
            },
          ],
        },
        {
          path: 'change-password',
          component: EmptyLayout,
          children: [
            {
              path: '',
              component: () =>
                import(
                  /* webpackChunkName: "ChangePassword" */ '@/pages/profil/change_password.vue'
                ),
              name: 'ChangePassword',
            },
          ],
        },
        {
          path: 'date-intervals',
          name: 'DateIntervals',
          component: EmptyLayout,
          redirect: 'date-intervals/list',
          children: [
            {
              path: 'list',
              component: () =>
                import(
                  /* webpackChunkName: "DateIntervalList" */ '@/pages/date-intervals/List.vue'
                ),
              name: 'DateIntervalList',
              meta: {
                title: 'Időszakok',
              },
            },
            {
              path: 'new',
              component: () =>
                import(
                  /* webpackChunkName: "DateIntervalEditor" */ '@/pages/date-intervals/Editor.vue'
                ),
              name: 'NewDateInterval',
              meta: {
                title: 'Új időszak',
              },
            },
            {
              path: 'edit/:id',
              component: () =>
                import(
                  /* webpackChunkName: "DateIntervalEditor" */ '@/pages/date-intervals/Editor.vue'
                ),
              name: 'DateIntervalEditor',
              meta: {
                title: 'Időszak',
              },
            },
          ],
        },
        {
          path: 'devices',
          name: 'Devices',
          component: EmptyLayout,
          redirect: 'devices/buildings',
          children: [
            {
              path: 'building-levels',
              name: 'BuildingLevels',
              component: EmptyLayout,
              redirect: 'building-levels/list',
              children: [
                {
                  path: 'list',
                  component: () =>
                    import(
                      /* webpackChunkName: "BuildingLevelList" */ '@/pages/building-levels/List.vue'
                    ),
                  name: 'BuildingLevelList',
                  meta: {
                    title: 'Épület szintek',
                  },
                },
                {
                  path: 'edit/:id',
                  component: () =>
                    import(
                      /* webpackChunkName: "BuildingLevelEditor" */ '@/pages/building-levels/Editor.vue'
                    ),
                  name: 'BuildingLevelEditor',
                  meta: {
                    title: 'Épület szint',
                  },
                },
                {
                  path: 'new',
                  component: () =>
                    import(
                      /* webpackChunkName: "BuildingLevelEditor" */ '@/pages/building-levels/Editor.vue'
                    ),
                  name: 'NewBuildingLevel',
                  meta: {
                    title: 'Épület szint létrehozása',
                  },
                },
              ],
            },
            {
              path: 'buildings',
              name: 'Buildings',
              component: EmptyLayout,
              redirect: 'buildings/list',
              children: [
                {
                  path: 'list',
                  component: () =>
                    import(/* webpackChunkName: "BuildingList" */ '@/pages/buildings/List.vue'),
                  name: 'BuildingList',
                  meta: {
                    title: 'Épületek',
                  },
                },
                {
                  path: 'edit/:id',
                  component: () =>
                    import(/* webpackChunkName: "BuildingEditor" */ '@/pages/buildings/Editor.vue'),
                  name: 'BuildingEditor',
                  meta: {
                    title: 'Épület',
                  },
                },
                {
                  path: 'new',
                  component: () =>
                    import(/* webpackChunkName: "BuildingEditor" */ '@/pages/buildings/Editor.vue'),
                  name: 'NewBuilding',
                  meta: {
                    title: 'Épület létrehozása',
                  },
                },
              ],
            },
            {
              path: 'gateways',
              name: 'Gateways',
              component: EmptyLayout,
              redirect: 'gateways/list',
              children: [
                {
                  path: 'list',
                  component: () =>
                    import(/* webpackChunkName: "GatewayList" */ '@/pages/gateways/List.vue'),
                  name: 'GatewayList',
                  meta: {
                    title: 'Gateway-ek',
                  },
                },
                {
                  path: 'edit/:id',
                  component: () =>
                    import(/* webpackChunkName: "GatewayEditor" */ '@/pages/gateways/Editor.vue'),
                  name: 'GatewayEditor',
                  meta: {
                    title: 'Gateway',
                  },
                },
                {
                  path: 'new',
                  component: () =>
                    import(/* webpackChunkName: "GatewayEditor" */ '@/pages/gateways/Editor.vue'),
                  name: 'NewGateway',
                  meta: {
                    title: 'Gateway létrehozása',
                  },
                },
              ],
            },
            {
              path: 'connections',
              name: 'Connections',
              component: EmptyLayout,
              redirect: 'connections/list',
              children: [
                {
                  path: 'list',
                  component: () =>
                    import(/* webpackChunkName: "ConnectionList" */ '@/pages/connections/List.vue'),
                  name: 'ConnectionList',
                  meta: {
                    title: 'Kapcsolatok',
                  },
                },
                {
                  path: 'edit/:id',
                  component: () =>
                    import(
                      /* webpackChunkName: "ConnectionEditor" */ '@/pages/connections/Editor.vue'
                    ),
                  name: 'ConnectionEditor',
                  meta: {
                    title: 'Kapcsolat',
                  },
                },
                {
                  path: 'new',
                  component: () =>
                    import(
                      /* webpackChunkName: "ConnectionEditor" */ '@/pages/connections/Editor.vue'
                    ),
                  name: 'NewConnection',
                  meta: {
                    title: 'Kapcsolat létrehozása',
                  },
                },
              ],
            },
            {
              path: 'controllers',
              name: 'Controllers',
              component: EmptyLayout,
              redirect: 'controllers/list',
              children: [
                {
                  path: 'list',
                  component: () =>
                    import(/* webpackChunkName: "ControllerList" */ '@/pages/controllers/List.vue'),
                  name: 'ControllerList',
                  meta: {
                    title: 'Kontrollerek',
                  },
                },
                {
                  path: 'edit/:id',
                  component: () =>
                    import(
                      /* webpackChunkName: "ControllerEditor" */ '@/pages/controllers/Editor.vue'
                    ),
                  name: 'ControllerEditor',
                  meta: {
                    title: 'Kontroller',
                  },
                },
                {
                  path: 'new',
                  component: () =>
                    import(
                      /* webpackChunkName: "ControllerEditor" */ '@/pages/controllers/Editor.vue'
                    ),
                  name: 'NewController',
                  meta: {
                    title: 'Kontroller létrehozása',
                  },
                },
              ],
            },
            {
              path: 'readers',
              name: 'Readers',
              component: EmptyLayout,
              redirect: 'devices/readers/list',
              children: [
                {
                  path: 'list',
                  component: () =>
                    import(/* webpackChunkName: "ReaderList" */ '@/pages/readers/List.vue'),
                  name: 'ReaderList',
                  meta: {
                    title: 'Olvasók',
                  },
                },
                {
                  path: 'edit/:id',
                  component: () =>
                    import(/* webpackChunkName: "ReaderEditor" */ '@/pages/readers/Editor.vue'),
                  name: 'ReaderEditor',
                  meta: {
                    title: 'Olvasó',
                  },
                },
                {
                  path: 'new',
                  component: () =>
                    import(/* webpackChunkName: "ReaderEditor" */ '@/pages/readers/Editor.vue'),
                  name: 'NewReader',
                  meta: {
                    title: 'Olvasó létrehozása',
                  },
                },
              ],
            },
            {
              path: 'relays',
              name: 'Relays',
              component: EmptyLayout,
              redirect: 'devices/relays/list',
              children: [
                {
                  path: 'list',
                  component: () =>
                    import(/* webpackChunkName: "RelayList" */ '@/pages/relays/List.vue'),
                  name: 'RelayList',
                  meta: {
                    title: 'Relék',
                  },
                },
                {
                  path: 'edit/:id',
                  component: () =>
                    import(/* webpackChunkName: "RelayEditor" */ '@/pages/relays/Editor.vue'),
                  name: 'RelayEditor',
                  meta: {
                    title: 'Relé',
                  },
                },
                {
                  path: 'new',
                  component: () =>
                    import(/* webpackChunkName: "RelayEditor" */ '@/pages/relays/Editor.vue'),
                  name: 'NewRelay',
                  meta: {
                    title: 'Relé létrehozása',
                  },
                },
              ],
            },
            {
              path: 'doors',
              name: 'Doors',
              component: EmptyLayout,
              redirect: 'doors/list',
              children: [
                {
                  path: 'list',
                  component: () =>
                    import(/* webpackChunkName: "DoorList" */ '@/pages/doors/List.vue'),
                  name: 'DoorList',
                  meta: {
                    title: 'Ajtók',
                  },
                },
                {
                  path: 'edit/:id',
                  component: () =>
                    import(/* webpackChunkName: "DoorEditor" */ '@/pages/doors/Editor.vue'),
                  name: 'DoorEditor',
                  meta: {
                    title: 'Ajtó',
                  },
                },
                {
                  path: 'new',
                  component: () =>
                    import(/* webpackChunkName: "DoorEditor" */ '@/pages/doors/Editor.vue'),
                  name: 'NewDoor',
                  meta: {
                    title: 'Ajtó létrehozása',
                  },
                },
              ],
            },
            {
              path: 'barriers',
              name: 'Barriers',
              component: EmptyLayout,
              redirect: 'barriers/list',
              children: [
                {
                  path: 'list',
                  component: () =>
                    import(/* webpackChunkName: "BarrierList" */ '@/pages/barriers/List.vue'),
                  name: 'BarrierList',
                  meta: {
                    title: 'Sorompók',
                  },
                },
                {
                  path: 'edit/:id',
                  component: () =>
                    import(/* webpackChunkName: "BarrierEditor" */ '@/pages/barriers/Editor.vue'),
                  name: 'BarrierEditor',
                  meta: {
                    title: 'Sorompó',
                  },
                },
                {
                  path: 'new',
                  component: () =>
                    import(/* webpackChunkName: "BarrierEditor" */ '@/pages/barriers/Editor.vue'),
                  name: 'NewBarrier',
                  meta: {
                    title: 'Sorompó létrehozása',
                  },
                },
              ],
            },
            {
              path: 'turnstiles',
              name: 'Turnstiles',
              component: EmptyLayout,
              redirect: 'turnstiles/list',
              children: [
                {
                  path: 'list',
                  component: () =>
                    import(/* webpackChunkName: "TurnstileList" */ '@/pages/turnstiles/List.vue'),
                  name: 'TurnstileList',
                  meta: {
                    title: 'Forgókapuk',
                  },
                },
                {
                  path: 'edit/:id',
                  component: () =>
                    import(
                      /* webpackChunkName: "TurnstileEditor" */ '@/pages/turnstiles/Editor.vue'
                    ),
                  name: 'TurnstileEditor',
                  meta: {
                    title: 'Forgókapu',
                  },
                },
                {
                  path: 'new',
                  component: () =>
                    import(
                      /* webpackChunkName: "TurnstileEditor" */ '@/pages/turnstiles/Editor.vue'
                    ),
                  name: 'NewTurnstile',
                  meta: {
                    title: 'Forgókapu létrehozása',
                  },
                },
              ],
            },
          ],
        },
        {
          path: 'filter',
          component: () => import(/* webpackChunkName: "Filter" */ '@/pages/filter/List.vue'),
          name: 'Filter',
          meta: {
            title: 'Szűrő',
          },
        },
        {
          path: 'groups',
          name: 'Groups',
          component: EmptyLayout,
          redirect: 'groups/list',
          children: [
            {
              path: 'list',
              component: () =>
                import(/* webpackChunkName: "GroupList" */ '@/pages/groups/List.vue'),
              name: 'GroupList',
              meta: {
                title: 'Csoportok',
              },
            },
            {
              path: 'new',
              component: () =>
                import(/* webpackChunkName: "GroupEditor" */ '@/pages/groups/Editor.vue'),
              name: 'NewGroup',
              meta: {
                title: 'Csoport létrehozása',
              },
            },
            {
              path: 'edit/:id',
              component: () =>
                import(/* webpackChunkName: "GroupEditor" */ '@/pages/groups/Editor.vue'),
              name: 'GroupEditor',
              meta: {
                title: 'Csoport',
              },
            },
          ],
        },
        {
          path: 'log',
          name: 'Log',
          component: EmptyLayout,
          redirect: 'log/admin',
          children: [
            {
              path: 'admin',
              component: () =>
                import(/* webpackChunkName: "AdminLog" */ '@/pages/logs/AdminLog.vue'),
              name: 'AdminLog',
              meta: {
                title: 'Admin napló',
              },
            },
            {
              path: 'building',
              component: () =>
                import(/* webpackChunkName: "BuildingLog" */ '@/pages/daemon_log/building_log.vue'),
              name: 'BuildingLog',
              params: {
                type: 'Building',
              },
              meta: {
                title: 'Épület napló',
              },
            },
            {
              path: 'parking',
              component: () =>
                import(/* webpackChunkName: "ParkingLog" */ '@/pages/daemon_log/parking_log.vue'),
              name: 'ParkingLog',
              params: {
                type: 'Parkinglot',
              },
              meta: {
                title: 'Parkoló napló',
              },
            },
            {
              path: 'parking_nciom',
              component: () =>
                import(
                  /* webpackChunkName: "ParkingLogNciom" */ '@/pages/daemon_log/parking_log_nciom.vue'
                ),
              name: 'ParkingLogNciom',
              params: {
                type: 'Parkinglot',
              },
              meta: {
                title: 'Parkoló napló (NC)',
              },
            },
          ],
        },
        {
          path: 'opening',
          component: EmptyLayout,
          children: [
            {
              path: '',
              component: () =>
                import(/* webpackChunkName: "OpeningHours" */ '@/pages/openings/Editor.vue'),
              name: 'OpeningHours',
              meta: {
                title: 'Nyitvatartások',
              },
            },
          ],
        },
        {
          path: 'operations',
          component: EmptyLayout,
          children: [
            {
              path: '',
              component: () =>
                import(/* webpackChunkName: "OperationList" */ '@/pages/operations/operations.vue'),
              name: 'OperationList',
              meta: {
                title: 'Műveletek',
              },
            },
          ],
        },
        {
          path: 'plates',
          name: 'Plates',
          component: EmptyLayout,
          redirect: 'plates/list',
          children: [
            {
              path: 'list',
              component: () => import(/* webpackChunkName: "PlateList" */ '@/pages/plates/List'),
              name: 'PlateList',
              meta: {
                title: 'Rendszámok',
              },
            },
            {
              path: 'new',
              component: () =>
                import(/* webpackChunkName: "PlateEditor" */ '@/pages/plates/Editor'),
              name: 'NewPlate',
              meta: {
                title: 'Rendszám létrehozása',
              },
            },
            {
              path: 'edit/:id',
              component: () =>
                import(/* webpackChunkName: "PlateEditor" */ '@/pages/plates/Editor'),
              name: 'PlateEditor',
              meta: {
                title: 'Rendszám',
              },
            },
          ],
        },
        {
          path: 'remote-open',
          component: EmptyLayout,
          children: [
            {
              path: '',
              component: () =>
                import(/* webpackChunkName: "RemoteOpen" */ '@/pages/remote_open/RemoteOpen.vue'),
              name: 'RemoteOpen',
              meta: {
                title: 'Távoli nyitás',
              },
            },
          ],
        },
        {
          path: 'schedules',
          name: 'Schedules',
          component: EmptyLayout,
          redirect: 'schedules/list',
          children: [
            {
              path: 'list',
              component: () =>
                import(/* webpackChunkName: "ScheduleList" */ '@/pages/schedule-groups/List.vue'),
              name: 'ScheduleList',
              meta: {
                title: 'Ütemzések',
              },
            },
            {
              path: 'new',
              component: () =>
                import(
                  /* webpackChunkName: "ScheduleEditor" */ '@/pages/schedule-groups/Editor.vue'
                ),
              name: 'NewSchedule',
              meta: {
                title: 'Ütemzés létrehozása',
              },
            },
            {
              path: 'edit/:id',
              component: () =>
                import(
                  /* webpackChunkName: "ScheduleEditor" */ '@/pages/schedule-groups/Editor.vue'
                ),
              name: 'ScheduleEditor',
              meta: {
                title: 'Ütemzés',
              },
            },
          ],
        },
        {
          path: 'status',
          component: EmptyLayout,
          children: [
            {
              path: '',
              component: () => import(/* webpackChunkName: "Status" */ '@/pages/status/status.vue'),
              name: 'Status',
              meta: {
                title: 'Státusz',
              },
            },
          ],
        },
        // {
        //   path: 'tickets',
        //   name: 'Tickets',
        //   component: EmptyLayout,
        //   redirect: 'tickets/list',
        //   children: [
        //     {
        //       path: 'list',
        //       component: ParkingTicketsList,
        //       name: 'TicketList',
        //       meta: {
        //         title: 'Parkolójegyek',
        //       },
        //     },
        //   ],
        // },
        {
          path: 'users',
          name: 'Users',
          component: EmptyLayout,
          redirect: 'users/list',
          children: [
            {
              path: 'new',
              component: () =>
                import(/* webpackChunkName: "UserEditor" */ '@/pages/users/Editor.vue'),
              name: 'NewUser',
              meta: {
                title: 'Felhasználó létrehozása',
              },
            },
            {
              path: 'edit/:id',
              component: () =>
                import(/* webpackChunkName: "UserEditor" */ '@/pages/users/Editor.vue'),
              name: 'UserEditor',
              meta: {
                title: 'Felhasználó',
              },
            },
            {
              path: 'list',
              component: () => import(/* webpackChunkName: "UserList" */ '@/pages/users/List.vue'),
              name: 'UserList',
              meta: {
                title: 'Felhasználók',
              },
            },
          ],
        },
        {
          path: 'keys',
          name: 'Keys',
          component: EmptyLayout,
          redirect: 'keys/list',
          children: [
            {
              path: 'new',
              component: () =>
                import(/* webpackChunkName: "KeyEditor" */ '@/variants/sze/pages/keys/Editor.vue'),
              name: 'NewKey',
              meta: {
                title: 'Kulcs létrehozása',
              },
            },
            {
              path: 'edit/:id',
              component: () =>
                import(/* webpackChunkName: "KeyEditor" */ '@/variants/sze/pages/keys/Editor.vue'),
              name: 'KeyEditor',
              meta: {
                title: 'Kulcs',
              },
            },
            {
              path: 'list',
              component: () =>
                import(/* webpackChunkName: "KeyList" */ '@/variants/sze/pages/keys/List.vue'),
              name: 'KeyList',
              meta: {
                title: 'Kulcsok',
              },
            },
            {
              path: 'log',
              component: () =>
                import(/* webpackChunkName: "KeysLog" */ '@/variants/sze/pages/keys-log/List.vue'),
              name: 'KeysLog',
              meta: {
                title: 'Kulcs napló',
              },
            },
            {
              path: 'handling',
              component: () =>
                import(
                  /* webpackChunkName: "KeyHandling" */ '@/variants/sze/pages/key-handling/KeyHandling.vue'
                ),
              name: 'KeyHandling',
              meta: {
                title: 'Kulcskezelés',
              },
            },
            {
              path: 'reservations',
              name: 'KeyReservations',
              component: EmptyLayout,
              redirect: 'keys/reservations/calendar',
              children: [
                {
                  path: 'calendar',
                  component: () =>
                    import(
                      /* webpackChunkName: "KeyReservationsCalendar" */ '@/variants/sze/pages/key-reservations/Calendar.vue'
                    ),
                  name: 'KeyReservationsCalendar',
                  meta: {
                    title: 'Kulcsfoglalás',
                  },
                },
              ],
            },
            {
              path: 'cabinets',
              name: 'KeyKabinets',
              component: EmptyLayout,
              redirect: 'keys/cabinets/list',
              children: [
                {
                  path: 'new',
                  component: () =>
                    import(
                      /* webpackChunkName: "KeyCabinetEditor" */ '@/variants/sze/pages/key-cabinets/Editor.vue'
                    ),
                  name: 'NewKeyCabinet',
                  meta: {
                    title: 'Kulcsszekrény létrehozása',
                  },
                },
                {
                  path: 'edit/:id',
                  component: () =>
                    import(
                      /* webpackChunkName: "KeyCabinetEditor" */ '@/variants/sze/pages/key-cabinets/Editor.vue'
                    ),
                  name: 'KeyCabinetEditor',
                  meta: {
                    title: 'Kulcsszekrény',
                  },
                },
                {
                  path: 'list/:highlights?',
                  component: () =>
                    import(
                      /* webpackChunkName: "KeyCabinetList" */ '@/variants/sze/pages/key-cabinets/List.vue'
                    ),
                  name: 'KeyCabinetList',
                  meta: {
                    title: 'Kulcsszekrények',
                  },
                },
              ],
            },
          ],
        },
        // {
        //   path: 'key-cabinets',
        //   name: 'KeyKabinets',
        //   component: EmptyLayout,
        //   redirect: 'key-cabinets/list',
        //   children: [
        //     {
        //       path: 'new',
        //       component: KeyCabinetEditor,
        //       name: 'NewKeyCabinet',
        //     },
        //     {
        //       path: 'edit/:id',
        //       component: KeyCabinetEditor,
        //       name: 'KeyCabinetEditor',
        //     },
        //     {
        //       path: 'list',
        //       component: KeyCabinetList,
        //       name: 'KeyCabinetList',
        //     },
        //   ],
        // },
        {
          path: '',
          component: Home,
          beforeEnter: requireAuth,
        },
        {
          path: '*',
          component: Home,
        },
      ],
    },
  ],
});

// Wait for vuex store to restore states
router.beforeEach(async (to, from, next) => {
  await store.restored;
  if (to.meta?.title) {
    document.title = to.meta.title + ' | UNIOM Beléptetőrendszer';
  }
  next();
});

export default router;
