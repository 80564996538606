<template>
  <div>
    <div class="d-flex justify-end sticky">
      <v-btn v-bind="$config.buttonAttrs.floating" color="grey" @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn v-bind="$config.buttonAttrs.floating" @click="save" :disabled="loading">
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Rendszám</h1>

    <loading-overlay v-if="loading" />

    <v-row>
      <v-col :cols="12" :md="5">
        <v-card>
          <v-card-title>Alapadatok</v-card-title>
          <v-card-text>
            <CardAutocomplete
              label="Kártya"
              v-model="form.card_id"
              :items="cards.items"
              item-value="element_id"
              filled
              :loading="cards.loading"
              :search-input.sync="cards.search"
              :value-comparator="$config.comparators.isEqual"
              :error-messages="errors.card_id"
              no-filter
              clearable
              :hide-no-data="cards.search?.length < 3 || cards.loading"
              @click:clear="cards.items = []"
            />
            <v-text-field
              label="Email cím"
              :error-messages="errors.email"
              v-model="form.email"
              v-lowercase
              filled
            />
            <v-masked-text-field
              label="Rendszám"
              :error-messages="errors.number"
              v-model="form.number"
              :mask="$config.inputMasks.plateNumber"
              v-uppercase
              filled
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    form: false,
    access_levels: [],
    errors: [],
    loading: false,
    new_access_level_id: 0,
    new_access_level_opts: [],
    new_valid_to: '',
    cards: {
      timeout: null,
      loading: false,
      search: '',
      items: [],
    },
  }),

  mounted() {
    this.fetchPlate();
  },

  watch: {
    'cards.search'(val) {
      if (!val || val.length < 3) {
        this.cards.loading = false;
        this.cards.items = [];
        return;
      }

      clearTimeout(this.cards.timeout);
      this.cards.loading = true;

      this.cards.timeout = setTimeout(async () => {
        // Lazily load input items
        try {
          const response = await this.$http.post('cards/list', {
            ownername_or_cardnumber: val,
            limit: 20
          });
          this.cards.items = response.cards;
          this.$forceUpdate();
        } catch (e) {
          console.error(e);
        } finally {
          this.cards.loading = false;
        }
      }, 500);
    },
  },

  methods: {
    async fetchPlate() {
      try {
        this.loading = true;
        const response = await this.$http.get(`plates/details/${this.$route.params.id || 0}`);
        if (response.msg) {
          throw response.msg;
        }
        this.form = response.plate;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async save() {
      try {
        this.errors = {};
        this.loading = true;
        const response = await this.$http.post('plates/save/', this.form);
        if (response.errors && Object.keys(response.errors).length) {
          this.errors = response.errors;
        } else {
          this.$router.push({ name: 'PlateList' });
          this.$dialog.notify.success('Sikeres mentés!');
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
