<template>
  <div>
    <entry-dialog v-model="entryDialog" @success="fetchGuestLog" />
    <details-dialog v-if="detailsDialog" v-model="detailsDialog" @success="fetchGuestLog" />

    <title-row title="Vendégbelépések">
      <template #buttons>
        <title-row-button @click="entryDialog = true">
          <v-icon left>mdi-plus</v-icon>
          Új vendég
        </title-row-button>
      </template>
    </title-row>

    <v-expansion-panels class="mb-4" :mandatory="!!Object.values(search).find((e) => e !== '')">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-filter</v-icon>
            Szűrés
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mt-2">
            <v-col :cols="12" :sm="4" :md="3">
              <v-text-field
                v-model="search.name"
                @input="searchEventHandler"
                label="Név"
                dense
                clearable
                hide-details
                class="my-4 my-sm-0"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card>
      <v-data-table
        :loading="loading"
        :headers="dataTable.headers"
        :items="dataTable.items"
        :server-items-length="dataTable.itemsLength"
        :options.sync="dataTable.options"
        :footer-props="$config.dataTableFooterProps"
        calculate-widths
      >
        <template #[`item.name`]="{ item }">
          <div class="font-weight-medium">{{ item.name }}</div>
          <div class="text-caption">
            {{ item.phone_number | VMask($config.inputMasks.phoneNumber) }}
          </div>
        </template>
        <template #[`item.entry_datetime`]="{ item }">
          <span>{{ item.entry_datetime | moment('lll') }}</span>
        </template>
        <template #[`item.entry_details`]="{ item }">
          <div>{{ item.entry_gate_name }}</div>
          <div class="text-caption">{{ item.entry_user_name }}</div>
        </template>
        <template #[`item.exit_datetime`]="{ item }">
          <span v-if="item.exit_datetime">{{ item.exit_datetime | moment('lll') }}</span>
          <span v-else>-</span>
        </template>
        <template #[`item.exit_details`]="{ item }">
          <div>{{ item.exit_gate_name }}</div>
          <div class="text-caption">{{ item.exit_user_name }}</div>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn icon tile title="Részletek megjelenítése" @click="detailsDialog = item"
            ><v-icon>mdi-eye</v-icon></v-btn
          >
        </template>
      </v-data-table>
    </v-card>

  </div>
</template>

<script>
import { dataTablePage } from '@/mixins';
import EntryDialog from './EntryDialog.vue';
import DetailsDialog from './DetailsDialog.vue';

export default {
  mixins: [dataTablePage],

  components: {
    EntryDialog,
    DetailsDialog,
  },

  data() {
    return {
      routeName: 'GuestLogList',
      fetchItems: this.fetchGuestLog,
      dataTable: {
        options: {
          sortBy: ['entry_datetime'],
          sortDesc: [true],
        },
        headers: [
          { text: 'Név / Telefonszám', value: 'name' },
          { text: 'Belépés ideje', value: 'entry_datetime' },
          { text: 'Belépés részletek', value: 'entry_details', sortable: false },
          { text: 'Kilépés ideje', value: 'exit_datetime' },
          { text: 'Kilépés részletek', value: 'exit_details', sortable: false },
          { value: 'actions', sortable: false, align: 'end' },
        ],
      },
      search: {
        name: '',
        email: '',
        phone: '',
      },
      entryDialog: false,
      exitDialog: false,
      detailsDialog: false,
    };
  },

  methods: {
    parseSearchQueries() {
      return {
        name: this.$route.query.name,
        email: this.$route.query.email,
        phone: this.$route.query.phone,
      };
    },

    async fetchGuestLog() {
      this.loading = true;
      try {
        const response = await this.$http.post('guest-log/list', this.postData);
        this.dataTable.items = response.guest_logs;
        this.dataTable.itemsLength = response.guest_logs_count;
        this.dataTable.options.page = this.$route.query.page * 1 || 1;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {
    searchQueries() {
      return {
        name: this.search.name || '',
        email: this.search.email || '',
        phone: this.search.phone || '',
      };
    },
  },
};
</script>
