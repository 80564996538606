<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn
        v-if="hasRight('DEVICE_WRITE')"
        v-bind="$config.buttonAttrs.floating"
        :to="{ name: 'NewConnection' }"
        title="Hozzáadás"
      >
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Kapcsolatok</h1>

    <v-expansion-panels class="mb-4" :mandatory="!!Object.values(search).find((e) => e !== '')">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-filter</v-icon>
            Szűrés
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col :cols="12" :sm="4" :md="3">
              <v-text-field
                v-model="search.name"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                label="Név"
                clearable
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-select
                label="Átjáró"
                :items="gateways"
                item-text="name"
                item-value="element_id"
                @change="searchEventHandler"
                @click:clear="searchEventHandler"
                v-model="search.gateway_id"
                clearable
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-text-field
                v-model="search.ip"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                label="IP"
                clearable
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-text-field
                v-model="search.port"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                label="Port"
                clearable
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card>
      <v-data-table
        :loading="loading"
        :headers="dataTable.headers"
        :items="dataTable.items"
        :server-items-length="dataTable.itemsLength"
        :options.sync="dataTable.options"
        :footer-props="$config.dataTableFooterProps"
        calculate-widths
      >
        <template #[`item.actions`]="{ item }">
          <v-btn
            v-if="hasRight('DEVICE_WRITE')"
            small
            text
            fab
            dark
            depressed
            color="secondary"
            class="rounded-sm"
            :to="{ name: 'ConnectionEditor', params: { id: item.element_id } }"
            title="Szerkesztés"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>

          <v-btn
            v-if="hasRight('DEVICE_WRITE')"
            text
            fab
            small
            color="secondary"
            class="rounded-sm m-3"
            @click="deleteConnection(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { dataTablePage } from '@/mixins';

export default {
  mixins: [dataTablePage],

  data() {
    return {
      routeName: 'ConnectionList',
      fetchItems: this.fetchConnections,
      dataTable: {
        options: {
          sortBy: ['gateway_name'],
        },
        headers: [
          { text: 'Megnevezés', value: 'connection_name' },
          { text: 'Átjáró', value: 'gateway_name' },
          { text: 'IP', value: 'ip' },
          { text: 'Port', value: 'port' },
          { value: 'actions', sortable: false, align: 'end' },
        ],
      },
      gateways: [],
      search: {
        name: '',
        gateway_id: 0,
        ip: '',
        port: '',
      },
    };
  },

  mounted() {
    this.fetchGateways();
  },

  methods: {
    parseSearchQueries() {
      return {
        name: this.$route.query.name,
        gateway_id: this.$route.query.gateway_id,
        ip: this.$route.query.ip,
        port: this.$route.query.port,
      };
    },

    async fetchConnections() {
      this.loading = true;
      try {
        const response = await this.$http.post('connections/list', this.options);
        this.dataTable.items = response.connections;
        this.dataTable.itemsLength = response.connections_count;
        this.dataTable.options.page = this.$route.query.page * 1 || 1;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async fetchGateways() {
      try {
        const response = await this.$http.get('gateways/list');
        this.gateways = response.gateways;
      } catch (e) {
        console.error(e);
      }
    },

    async deleteConnection(item) {
      const confirm = await this.$dialog.confirm({
        type: 'error',
        text: `<b>${item.name}</b> connection törlésére készül!<br>Folytatja?`,
        title: 'Figyelem',
      });

      if (!confirm) return;

      try {
        const response = await this.$http.get(`connections/delete/${item.element_id}`);
        this.fetchConnections();
        if (response.status === 'OK') {
          this.$dialog.notify.info('A connection törölve');
        }
      } catch (e) {
        console.error(e);
      }
    },
  },

  computed: {
    searchQueries() {
      return {
        name: this.search.name || '',
        gateway_id: this.search.gateway_id || '',
        ip: this.search.ip || '',
        port: this.search.port || '',
      };
    },
  },
};
</script>
