<template>
  <v-sheet
    min-height="36px"
    rounded
    color="transparent"
    class="mt-6 px-6 pa-1 relative font-weight-regular text-uppercase text-caption grey--text"
  >
    <div>
    {{ item.label }}
    </div>
  </v-sheet>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
