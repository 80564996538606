var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('title-row',{attrs:{"title":"UNIOM beléptetőrendszer"}}),_c('div',{staticClass:"normal-text mb-8"},[_vm._v(" A rendszer használatához válasszon a bal oldali menüpontok közül! ")]),_c('v-row',{staticClass:"d-flex align-stretch"},[_c('v-col',{attrs:{"md":6,"xl":3,"cols":12}},[_c('home-stat-card',{attrs:{"title":"Kártyák","icon":"mdi-badge-account-horizontal","loading":_vm.loading,"statistics":[
          { label: 'Aktív', value: _vm.cards.active_count , to: { name: 'CardList' }},
          { label: 'Összesen', value: _vm.cards.count, to: { name: 'CardList' } },
          { label: 'Bent tartózkodik', value: _vm.cards.in_zones, to: { name: 'OccupantList' } },
        ]}})],1),_c('v-col',{attrs:{"md":6,"xl":3,"cols":12}},[_c('home-stat-card',{attrs:{"title":"Vendégek","icon":"mdi-account-edit","loading":_vm.loading,"statistics":[
          { label: 'Utolsó 24 óra', value: _vm.guests.day_count, to: { name: 'GuestLogList' } },
          { label: 'Utolsó 7 nap', value: _vm.guests.week_count, to: { name: 'GuestLogList' } },
          { label: 'Összesen', value: _vm.guests.count, to: { name: 'GuestLogList' } },
          { label: 'Bent tartózkodik', value: _vm.guests.in_zones, to: { name: 'OccupantList' } },
        ]}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }