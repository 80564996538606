<template>
  <v-footer
    app
    inset
    dark
    class="d-flex justify-space-between flex-wrap py-0"
    :height="footerHeight"
  >
    <div>© 2017-{{ year }} Uninet Kft. <span>- Minden jog fenntarva.</span></div>
    <div class="text-caption text-right">
      <div :title="`Build #${buildNumber}\n${buildDate}`">V{{ version }}</div>
    </div>
  </v-footer>
</template>

<script>
import { version } from '@/../package.json';

export default {
  data() {
    return {
      version
    }
  },
  computed: {
    footerHeight() {
      return parseInt(process.env.VUE_APP_FOOTER_HEIGHT);
    },
    year() {
      return new Date(process.env.VUE_APP_BUILD_DATETIME).getFullYear();
    },
    buildDate() {
      return new Date(process.env.VUE_APP_BUILD_DATETIME).toLocaleString();
    },
    buildNumber() {
      return parseInt(process.env.VUE_APP_BUILD_NUMBER);
    },
  },
};
</script>

<style lang="scss" scoped>
footer {
  width: auto;
}
</style>
