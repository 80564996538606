<template>
  <div>

    <h1 class="py-6">Érvényes QR kódok</h1>

    <v-expansion-panels class="mb-4" :mandatory="!!Object.values(search).find((e) => e !== '')">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-filter</v-icon>
            Szűrés
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mt-2">
            <v-col :cols="12" :sm="4">
              <v-masked-text-field
                v-model="search.qr_code"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                label="Kód"
                dense
                clearable
                hide-details
                class="my-4 my-sm-0"
                :mask="$config.inputMasks.barcodeNumber"
                v-uppercase
              />
            </v-col>
            <v-col :cols="12" :sm="4">
              <v-masked-text-field
                v-model="search.name"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                label="Név"
                dense
                clearable
                hide-details
                class="my-4 my-sm-0"
                :mask="$config.inputMasks.name"
              />
            </v-col>
            <v-col :cols="12" :sm="4">
              <v-masked-text-field
                v-model="search.email"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                label="E-mail cím"
                dense
                clearable
                hide-details
                class="my-4 my-sm-0"
                autocomplete="username"
                :mask="$config.inputMasks.email"
                v-lowercase
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card>
      <v-data-table
        :loading="loading"
        :headers="dataTable.headers"
        :items="dataTable.items"
        :server-items-length="dataTable.itemsLength"
        :options.sync="dataTable.options"
        :footer-props="$config.dataTableFooterProps"
      >
        <template #[`item.start_date_time`]="{ item }">
          <span v-if="item.start_date_time" :title="item.start_date_time | moment($config.momentFormats.explicit)">{{
            item.start_date_time | moment('lll')
          }}</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { dataTablePage } from '@/mixins';

export default {
  mixins: [dataTablePage],

  data() {
    return {
      routeName: 'QrCodeList',
      fetchItems: this.fetchQrCodes,
      dataTable: {
        options: {
          sortBy: ['barcode_number'],
          sortDesc: [true],
        },
        headers: [
          { text: 'Kód', value: 'barcode_number' },
          { text: 'Felhasználó név', value: 'name' },
          { text: 'Felhasználó email', value: 'email' },
          { text: 'Kezdő dátum', value: 'start_date_time' },
          { text: 'Periódus (óra)', value: 'period' },
          { text: 'Vég dátum', value: 'end_date' },
        ],
      },
      search: {
        qr_code: '',
        name: '',
        email: '',
      },
      searchTimeout: null,
    };
  },

  methods: {
    parseSearchQueries() {
      return {
        qr_code: this.$route.query.qr_code,
        name: this.$route.query.name,
        email: this.$route.query.email,
      };
    },

    async fetchQrCodes() {
      this.loading = true;
      const data = JSON.parse(JSON.stringify(this.search));
      data.sort_by = this.options.sortBy || [''];
      data.sort_desc = this.options.sortDesc || [false];
      try {
        const response = await this.$http.post('qr-codes/list', this.options);
        this.dataTable.items = response.qr_codes;
        this.dataTable.itemsLength = response.qr_codes_count;
        this.dataTable.options.page = Number(this.$route.query.page) || 1;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {
    searchQueries() {
      return {
        qr_code: this.search.qr_code || '',
        name: this.search.name || '',
        email: this.search.email || '',
      };
    },
  },
};
</script>
