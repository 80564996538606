<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn
        v-bind="$config.buttonAttrs.floating"
        @click="importer.dialog = true"
        title="Importálás Excel fájlból"
        :disabled="!!selectedItems.length"
      >
        <v-icon dark>mdi-file-excel</v-icon>
      </v-btn>
      <v-btn
        v-bind="$config.buttonAttrs.floating"
        @click="save"
        title="Mentés"
        :loading="saving"
        :disabled="!isDirty"
      >
        <v-icon dark>mdi-content-save</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">QR kód beállítás</h1>

    <v-expansion-panels class="mb-4" accordion v-model="expansionPanelIndex">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-pencil-box-multiple</v-icon>
            Csoportos adatmódosítás
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12" md="3" class="d-flex align-center">
              <v-autocomplete
                label="Jogosultság"
                v-model="multiEdit.accessLevelIds"
                :items="accessLevels"
                item-value="element_id"
                item-text="name"
                multiple
                small-chips
                hide-details
                dense
                @input="multiEdit.accessLevelIds.sort((a, b) => a - b)"
                @change="multiEdit.accessLevelIdsChanged = true"
                class="flex-grow-1"
              />
              <v-btn
                class="flex-grow-0"
                :disabled="!multiEdit.accessLevelIdsChanged || !selectedItems.length"
                icon
                tile
                color="primary"
                title="Kijelölt sorok módosítása"
                @click="setAccessLevelIds"
              >
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" md="2" class="d-flex align-center">
              <v-datetime-picker
                label="Kezdő dátum"
                :time-picker="true"
                :date-picker="true"
                v-model="multiEdit.startDateTime"
                hide-details
                clearable
                dense
                @change="multiEdit.startDateTimeChanged = true"
                class="flex-grow-1"
              />
              <v-btn
                class="flex-grow-0"
                append-icon="mdi-check"
                :disabled="!multiEdit.startDateTimeChanged || !selectedItems.length"
                icon
                tile
                color="primary"
                title="Kijelölt sorok módosítása"
                @click="setStartDateTimes"
              >
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" md="2" class="d-flex align-center">
              <v-masked-text-field
                class="text-right"
                label="Időtartam"
                :disabled="lockPeriods"
                v-model="multiEdit.period"
                :mask="$config.inputMasks.createNumberMask({ min: 1, max: 168 })"
                hide-details
                dense
                suffix="óra"
              />
              <v-btn
                :disabled="!multiEdit.periodChanged || !selectedItems.length"
                icon
                tile
                color="primary"
                title="Kijelölt sorok módosítása"
                @click="setPeriods"
              >
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-email</v-icon>
            E-mail küldés
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12" md="2" class="d-flex align-center">
              <v-select
                label="Kiküldés ideje"
                hide-details
                dense
                v-model="email.sendMode"
                :items="[
                  { value: 'immediate', text: 'Azonnal' },
                  { value: 'specific_time', text: 'Választott időpontban' },
                ]"
              />
            </v-col>

            <v-col
              v-if="email.sendMode === 'specific_time'"
              cols="12"
              md="2"
              class="d-flex align-center"
            >
              <v-datetime-picker
                label="Időpont"
                dense
                class="flex-grow-1"
                :time-picker="true"
                :date-picker="true"
                :allowed-from="new Date().setMinutes(new Date().getMinutes() + 10)"
                v-model="email.sendDateTime"
                hide-details
                clearable
                elevation="0"
                placeholder="Válasszon"
                persistent-placeholder
              />
            </v-col>

            <v-col class="d-flex align-center">
              <v-btn
                color="primary"
                large
                depressed
                @click="sendEmails"
                :loading="sending"
                :disabled="
                  !selectedItems.length ||
                  (email.sendMode === 'specific_time' && !email.sendDateTime)
                "
              >
                <v-icon left>mdi-email-fast</v-icon>
                Küldés
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card>
      <v-data-table
        :loading="loading"
        :headers="dataTable.headers"
        :items="dataTable.items"
        :options.sync="dataTable.options"
        :footer-props="$config.dataTableFooterProps"
        show-select
        selectable-key="is_selectable"
        v-model="selectedItems"
        calculate-widths
        disable-sort
      >
        <template #[`item.data-table-select`]="{ item, isSelected, select }">
          <v-checkbox
            v-if="!item.email_sent"
            :value="isSelected"
            @change="select"
            :disabled="!item.is_selectable"
          />
        </template>
        <template #[`item.barcode_number`]="{ item }">
          <div class="py-1">
            <div>{{ item.rf_id }}</div>
            <div>{{ item.barcode_number }}</div>
            <div v-if="item.email_sent">
              <v-icon small>mdi-email</v-icon>
              <span>{{ item.user?.name }} ({{ item.user?.email }})</span>
            </div>
          </div>
        </template>
        <template #[`item.name`]="{ item }">
          <v-text-field
            :disabled="!!item.email_sent"
            :error-messages="errors[item.id]?.name"
            :hide-details="!errors[item.id]"
            v-model="item.name"
            filled
            dense
            placeholder="Név"
            persistent-placeholder
          />
        </template>
        <template #[`item.email`]="{ item }">
          <v-text-field
            :disabled="!!item.email_sent"
            :error-messages="errors[item.id]?.email"
            :hide-details="!errors[item.id]"
            v-model="item.email"
            filled
            dense
            placeholder="E-mail cím"
            persistent-placeholder
          />
        </template>
        <template #[`item.plate`]="{ item }">
          <v-masked-text-field
            :disabled="!!item.email_sent"
            :error-messages="errors[item.id]?.license_plate"
            :hide-details="!errors[item.id]"
            v-model="item.license_plate"
            :mask="$config.inputMasks.plateNumber"
            v-uppercase
            filled
            dense
            placeholder="Rendszám"
            persistent-placeholder
          />
        </template>
        <template #[`item.permissions`]="{ item }">
          <v-autocomplete
            :disabled="!!item.email_sent"
            :error-messages="errors[item.id]?.access_level_ids"
            :hide-details="!errors[item.id]"
            v-model="item.access_level_ids"
            :value-comparator="$config.comparators.isEqual"
            :items="accessLevels"
            item-value="element_id"
            item-text="name"
            multiple
            filled
            small-chips
            dense
            @input="item.access_level_ids.sort((a, b) => (a > b ? 1 : -1))"
          />
        </template>
        <template #[`item.start_date_time`]="{ item }">
          <v-datetime-picker
            :disabled="!!item.email_sent"
            :time-picker="true"
            :date-picker="true"
            filled
            :error-messages="errors[item.id]?.start_date_time"
            :hide-details="!errors[item.id]"
            v-model="item.start_date_time"
            clearable
            dense
            placeholder="Kezdő dátum"
            persistent-placeholder
            :allowed-from="new Date().setHours(new Date().getHours() + 1)"
            allow-seconds
          />
        </template>
        <template #[`item.period`]="{ item }">
          <v-masked-text-field
            :key="item.key"
            class="text-right"
            placeholder="Időtartam"
            persistent-placeholder
            filled
            :disabled="!!item.email_sent || lockPeriods"
            :error-messages="errors[item.id]?.period"
            :hide-details="!errors[item.id]"
            dense
            v-model="item.period"
            :mask="$config.inputMasks.createNumberMask({ min: 1, max: 168 })"
            :suffix="item.period ? 'óra' : ''"
          />
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-if="importer.dialog" :value="true" persistent max-width="480px">
      <v-card>
        <v-card-title>
          <v-icon left>mdi-file-import</v-icon>
          Vonalkód importálás
        </v-card-title>
        <v-card-text>
          <v-btn href="/assets/doc/vonalkod_import_sablon.xls" download large depressed block>
            <v-icon left>mdi-file-download</v-icon>
            Sablon XLS fájl letöltése
          </v-btn>

          <v-divider class="ma-4"></v-divider>

          <v-file-input
            label="Vonalkódokat tartalmazó XLS fájl feltöltése"
            filled
            prepend-icon=""
            prepend-inner-icon="mdi-file-upload"
            v-model="importer.file"
            :error-messages="errors.file"
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="importer.dialog = false">Mégse</v-btn>
          <v-btn
            color="primary"
            depressed
            :disabled="importer.loading"
            :loading="importer.loading"
            @click="importCodes"
          >
            Importálás
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      importer: {
        loading: false,
        dialog: false,
        file: null,
      },
      multiEdit: {
        accessLevelIds: [],
        accessLevelIdsChanged: false,
        startDateTime: null,
        startDateTimeChanged: false,
        period: null,
        periodChanged: false,
        lockPeriod: false,
        show: false,
      },
      expansionPanelIndex: null,
      errors: {},
      accessLevels: [],
      selectedItems: [],
      loading: true,
      saving: false,
      sending: false,
      snapshot: [],
      lockPeriods: false,
      email: {
        sendMode: 'immediate',
        sendDateTime: null,
      },
      dataTable: {
        items: [],
        options: {
          itemsPerPage: 25,
        },
        headers: [
          { text: 'Rf ID / Szám', value: 'barcode_number' },
          { text: 'Kedvezményezett neve', value: 'name' },
          { text: 'Kedvezményezett email címe', value: 'email' },
          { text: 'Rendszám', value: 'plate', width: 200 },
          { text: 'Jogosultság', value: 'permissions' },
          { text: 'Kezdő dátum', value: 'start_date_time', width: 210 },
          { text: 'Időtartam (óra)', value: 'period', width: 120 },
        ],
      },
    };
  },

  watch: {
    'multiEdit.accessLevelIds'(curVal, prevVal) {
      this.multiEdit.accessLevelIdsChanged = curVal !== prevVal;
    },
    'multiEdit.startDateTime'(curVal, prevVal) {
      this.multiEdit.startDateTimeChanged = curVal !== prevVal;
    },
    'multiEdit.period'(curVal, prevVal) {
      this.multiEdit.periodChanged = curVal !== prevVal && (curVal !== '' || prevVal !== null);
    },
  },

  mounted() {
    this.fetchCodes();
  },

  methods: {
    async fetchCodes() {
      this.loading = true;
      const response = await this.$http.post(
        `qr-code-requests/codes/list/${this.$route.params.id || 0}`,
        this.postData
      );
      this.accessLevels = response.access_levels;

      if (this.$store.state.user.barcode_period_fix) {
        this.lockPeriods = true;
        this.multiEdit.period = response.qr_codes[0]?.period;
      }
      this.multiEdit.accessLevelIdsChanged = false;
      this.multiEdit.startDateTimeChanged = false;
      this.multiEdit.periodChanged = false;
      this.multiEdit.show = true;

      this.dataTable.items = response.qr_codes;
      this.selectedItems = this.lodash.intersectionWith(
        this.dataTable.items,
        this.selectedItems,
        (a, b) => a.id === b.id
      );
      this.snapshot = this.lodash.cloneDeep(this.dataTable.items);
      this.loading = false;
    },

    async importCodes() {
      this.errors = {};
      this.importer.loading = true;
      try {
        const formData = new FormData();
        formData.append('file', this.importer.file);
        formData.append('request_id', this.$route.params.id);

        const response = await this.$http.post('qr-code-requests/codes/import', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (Object.keys(response.errors).length) {
          this.errors = response.errors;
        }
        if (response.status === 'OK') {
          this.importer.dialog = false;
          this.importer.file = null;
          this.importer.loading = false;
          this.fetchCodes();
          this.$dialog.notify.success('Sikeres importálás');
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.importer.loading = false;
      }
    },

    async save() {
      this.errors = {};
      this.saving = true;
      try {
        const response = await this.$http.post('qr-code-requests/codes/save', {
          codes: this.dataTable.items,
        });

        if (Object.keys(response.errors).length) {
          this.errors = response.errors;
        } else {
          this.$dialog.notify.success('Sikeres mentés');
          await this.fetchCodes();
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.saving = false;
      }
    },

    async sendEmails() {
      if (this.isDirty) {
        await this.save();
      }
      this.sending = true;
      this.errors = {};
      try {
        const response = await this.$http.post('qr-code-requests/codes/email', {
          codes: this.selectedItems,
          send_delayed: this.email.sendMode === 'immediate' ? false : this.email.sendDateTime,
        });
        if (Object.keys(response.errors).length) {
          this.errors = response.errors;
        } else if (response.status === 'OK') {
          this.selectedItems = [];
          this.fetchCodes();
          this.$dialog.notify.success('Sikeres küldés');
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.sending = false;
      }
    },

    filterOnylUnusedCodes(array) {
      return array.filter((item) => !item.email_sent);
    },

    isSelected(item) {
      return this.selectedItems.map((e) => e.id).includes(item.id);
    },

    setAccessLevelIds() {
      for (const item of this.selectedItems) {
        item.access_level_ids = this.multiEdit.accessLevelIds;
      }
      this.multiEdit.accessLevelIdsChanged = false;
    },

    setStartDateTimes() {
      for (const item of this.selectedItems) {
        item.start_date_time = this.multiEdit.startDateTime;
      }
      this.multiEdit.startDateTimeChanged = false;
    },

    setPeriods() {
      for (const item of this.selectedItems) {
        item.period = this.multiEdit.period;
        item.key = item.period;
      }
      this.multiEdit.periodChanged = false;
      this.$forceUpdate();
    },
  },

  asyncComputed: {
    isDirty: {
      get() {
        return this.$config.comparators.isDirty(this.dataTable.items, this.snapshot);
      },
      watch: ['dataTable.items'],
    },
    // qrCodes: {
    //   async get() {
    //     this.loading = true;
    //     const response = await this.$http.post(
    //       `qr-code-requests/codes/list/${this.$route.params.id || 0}`,
    //       this.postData
    //     );
    //     this.accessLevels = response.access_levels;
    //     if (this.$store.state.user.barcode_period_fix) {
    //       this.lockPeriods = true;
    //       this.multiEdit.period = response.qr_codes[0]?.period;
    //     }
    //     this.multiEdit.accessLevelIdsChanged = false;
    //     this.multiEdit.startDateTimeChanged = false;
    //     this.multiEdit.periodChanged = false;
    //     this.multiEdit.show = true;
    //     this.loading = false;
    //     this.snapshot = this.lodash.cloneDeep(response.qr_codes);
    //     this.selectedItems = this.lodash.intersectionWith(this.dataTable.items, this.selectedItems, (a, b) => a.id === b.id)
    //     return response.qr_codes;
    //   },
    //   default: [],
    // },
  },
};
</script>

<style lang="scss" scoped>
::v-deep #overflow-btn {
  flex-grow: 0 !important;
  width: 0px !important;
  min-width: 0px !important;
}
</style>
