<template>
  <v-navigation-drawer v-model="drawer" :width="sidebarWidth" hide-overlay absolute app :mobile-breakpoint="840"
    class="elevation-1">
    <sidebar-menu :items="menu" />

    <!-- <v-list dense nav>
        <template v-for="(item, i) in menu">
          <v-list-item
            v-if="!item.children && hasRight(item.right)"
            :key="`menu-item-${i}`"
            :to="item.link || item.to"
            class="white"
            :class="{
              'primary': matchRoute(item?.to?.name),
              'white--text': !$store.state.darkMode && matchRoute(item.to ? item.to.name : null),
            }"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            v-else-if="hasRight(item.right)"
            :key="`menu-item-group-${i}`"
            :prepend-icon="item.icon"
            :value="matchChildRoute(item.group)"
            color="primary"
            class="rounded elevation-0 white my-1"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </template>

<v-list class="px-2">
  <template v-for="child in item.children">
                <v-list-item
                  v-if="hasRight(child.right)"
                  :key="child.label"
                  :to="child.link || child.to"
                  :class="{
                    'primary white--text': matchRoute(child?.to?.name),
                  }"
                >
                  <v-list-item-icon>
                    <v-icon>{{ child.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="child.label"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
</v-list>
</v-list-group>
</template>
</v-list>
<v-sheet height="100" color="transparent"></v-sheet> -->

    <template v-slot:prepend>

      <router-link :to="{ name: 'Home' }">
        <v-hover>
          <v-sheet fab tile :width="sidebarWidth" :height="headerHeight" class="d-flex align-center">
            <!-- <div class="mr-4" style="background: url('https://www.metalprinting.hu/wp-content/uploads/2019/11/sze_logo_blue-300x100.png') no-repeat center left; background-size: cover; width: 50px; height: 50px; filter: brightness(10)"></div> -->
            <v-icon size="36" class="mx-4">mdi-shield-key-outline</v-icon>
            <div class="text-left">
              <div class="font-weight-bold">Széchenyi Egyetem</div>
              <div class="caption">Beléptetőrendszer</div>
            </div>
          </v-sheet>
        </v-hover>
      </router-link>

      <!-- <router-link :to="{ name: 'Home' }">
        <v-hover v-slot="{ hover }">
          <v-sheet
            fab
            tile
            :width="sidebarWidth"
            :height="headerHeight"
            :color="`primary darken-${hover ? 1 : 5}`"
            dark
            class="d-flex align-center"
          >
            <v-icon size="36" class="mx-4">mdi-shield-key-outline</v-icon>
            <div class="text-left">
              <div class="font-weight-bold">Széchenyi Egyetem</div>
              <div class="caption">Beléptetőrendszer</div>
            </div>
          </v-sheet>
        </v-hover>
      </router-link> -->
      <v-sheet height="4" color="primary darken-1"></v-sheet>
    </template>
    <template v-slot:append>
      <v-divider class="ma-0" />
      <v-sheet flat dark :height="footerHeight" class="d-flex justify-center align-center pa-3">
        <v-btn large text block @click="$logout">
          <v-icon left>mdi-exit-run</v-icon>
          Exit
        </v-btn>
      </v-sheet>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { version } from '@/../package.json';
import menu from '../../menu';
import SidebarMenu from '@/components/SidebarMenu.vue';

export default {
  components: {
    SidebarMenu,
  },
  data: () => ({
    width: 80,
    version,
    menu,
  }),
  methods: {
    matchRoute(name) {
      return !!this.$route.matched.filter((e) => e.path && e.name === name).length;
    },
    matchChildRoute(name) {
      return !!this.$route.matched.filter((e) => e.path && e.name === name).length;
    },
    // hasRight(name) {
    //   if (!name) {
    //     return true;
    //   }
    //   const right = this.findRight(this.$store.state.rights, name);
    //   if (right && (right.selected || this.lodash.find(right.children, { selected: true}))) {
    //     return true;
    //   }
    //   return false;
    // },

    // findRight(rights, alias) {
    //   let result = null;

    //   for (const right of rights) {
    //     if (right.alias === alias) {
    //       result = right;
    //       break;
    //     }
    //     if (right.children) {
    //       result = this.findRight(right.children, alias);
    //       if (result) {
    //         break;
    //       }
    //     }
    //   }

    //   return result;
    // },
  },
  computed: {
    headerHeight() {
      return parseInt(process.env.VUE_APP_HEADER_HEIGHT - 4);
    },
    footerHeight() {
      return parseInt(process.env.VUE_APP_FOOTER_HEIGHT);
    },
    sidebarWidth() {
      return parseInt(process.env.VUE_APP_SIDEBAR_WIDTH);
    },
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(value) {
        this.$store.commit('setDrawer', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-group--active {
  // background: #fff;
  // box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
  //   0px 1px 5px 0px rgb(0 0 0 / 12%);
}
</style>
