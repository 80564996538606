var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ma-auto"},[_c('h1',{staticClass:"py-6"},[_vm._v("Kulcskezelés")]),_c('v-row',[_c('v-col',{attrs:{"cols":12,"md":6}},[_c('v-card',{staticStyle:{"position":"sticky","top":"10px"}},[_c('v-card-title',[_c('span',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-swap-horizontal-bold")]),_vm._v(" Kulcsok kezelése ")],1)]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-sheet',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"d-flex flex-column justify-center align-center",staticStyle:{"cursor":"pointer"},attrs:{"color":"primary","rounded":"","height":"160","dark":"","title":"Kártyatulajdonos kulcshoz való hozzáférésének ellenőrzése","elevation":"2"},on:{"click":function($event){_vm.view = _vm.Request}}},[_c('v-icon',{attrs:{"size":"96"}},[_vm._v("mdi-tray-arrow-up")]),_vm._v(" Kulcs kiadása ")],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-sheet',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"d-flex flex-column justify-center align-center",staticStyle:{"cursor":"pointer"},attrs:{"color":"primary","rounded":"","height":"160","dark":"","title":"Kulcs visszavétele kártyatulajdonostól","elevation":"2"},on:{"click":function($event){_vm.view = _vm.Return}}},[_c('v-icon',{attrs:{"size":"96"}},[_vm._v("mdi-tray-arrow-down")]),_vm._v(" Kulcs visszavétele ")],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":12,"md":6}},[_c('v-card',[_c('v-card-title',[_c('span',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-key-chain")]),_vm._v(" Kulcsszekrény ")],1)]),_c('v-card-text',[_c('v-autocomplete',{attrs:{"label":"Kulcsszekrény","filled":"","loading":_vm.keyCabinetsLoading,"items":_vm.keyCabinets,"item-text":"name","item-value":"id","value-comparator":_vm.$config.comparators.isEqual,"hint":!_vm.keyCabinetId
                ? 'Nincs kulcsszekrény kiválasztva. A továbblépéshez válasszon egyet!'
                : '',"persistent-hint":""},model:{value:(_vm.keyCabinetId),callback:function ($$v) {_vm.keyCabinetId=$$v},expression:"keyCabinetId"}}),_c('v-row',[_c('v-col',{attrs:{"cols":12,"lg":12}},[(_vm.keyCabinetId)?_c('v-card',{attrs:{"outlined":"","loading":_vm.keysLoading}},[_c('v-card-title',[_vm._v("Kulcsok")]),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.keys),function(key){return _c('tr',{key:`key-${key.id}`},[_c('td',{attrs:{"width":"10"}},[_c('v-badge',{attrs:{"title":key.taken_by ? 'A kulcsot elvitték' : 'A kulcs a helyén van',"avatar":"","bordered":"","overlap":"","color":key.taken_by ? 'error' : 'success',"icon":key.taken_by ? 'mdi-close' : 'mdi-check'}},[_c('v-avatar',{attrs:{"size":"32","color":key.color,"rounded":""}},[_c('v-icon',[_vm._v("mdi-key")])],1)],1)],1),_c('td',[_c('strong',[_vm._v(_vm._s(key.name))]),_c('br'),_c('small',[_vm._v(_vm._s(key.remark))])])])}),0)]},proxy:true}],null,false,4029254286)})],1):_vm._e()],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"value":_vm.view,"persistent":"","fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},on:{"input":(v) => {
        _vm.view = v ? _vm.view : null;
        if (!_vm.view) {
          _vm.$asyncComputed.keys.update();
        }
      }}},[_c('v-card',{staticClass:"d-flex flex-column justify-center",attrs:{"elevation":"0","color":"muted"}},[_c('v-toolbar',{staticClass:"flex-grow-1",attrs:{"color":"transparent","elevation":"0"}},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-sheet',{staticClass:"mx-auto",attrs:{"max-width":"800","color":"transparent"}},[_c(_vm.view,{tag:"component",on:{"close":_vm.closeDialog,"switch-action":v => _vm.view = v}})],1),_c('v-spacer')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }