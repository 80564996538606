<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn v-bind="$config.buttonAttrs.floating" color="grey" @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn v-bind="$config.buttonAttrs.floating" @click="save" :disabled="loading">
        <v-icon dark>mdi-content-save</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Ajtó</h1>

    <loading-overlay v-if="fetching" />

    <v-card>
      <v-card-text>
        <v-row>
          <v-col :cols="12" :md="6" :sm="12">
            <v-text-field
              label="Megnevezés"
              :error-messages="errors.name"
              v-model="form.name"
              filled
              clearable
            />
            <v-autocomplete
              label="Kontroller"
              :error-messages="errors.controller_id"
              v-model="form.controller_id"
              :items="controllers"
              item-text="name"
              item-value="element_id"
              filled
              :value-comparator="$config.comparators.isEqual"
            />
            <v-autocomplete
              label="Olvasó"
              :error-messages="errors.reader_id"
              v-model="form.reader_id"
              :items="readers"
              item-text="name"
              item-value="element_id"
              filled
              persistent-hint
              :disabled="!form.controller_id"
              :hint="form.controller_id ? '' : 'Kontroller kötelező'"
              :value-comparator="$config.comparators.isEqual"
            />
            <v-autocomplete
              label="Relé"
              :error-messages="errors.relay_id"
              v-model="form.relay_id"
              :items="relays"
              item-text="name"
              item-value="element_id"
              filled
              persistent-hint
              :disabled="!form.controller_id"
              :hint="form.controller_id ? '' : 'Kontroller kötelező'"
              :value-comparator="$config.comparators.isEqual"
            />
          </v-col>
          <v-col :cols="12" :md="6" :sm="12">
            <v-textarea
              label="Megjegyzés"
              :error-messages="errors.comment"
              v-model="form.comment"
              filled
              auto-grow
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12" :md="3" :sm="6">
            <v-autocomplete
              label="Épület"
              :error-messages="errors.building_id"
              v-model="form.building_id"
              :items="buildings"
              item-text="name"
              item-value="id"
              filled
              @change="form.building_level_id = null"
              :value-comparator="$config.comparators.isEqual"
            />
          </v-col>
          <v-col :cols="12" :md="3" :sm="6">
            <v-autocomplete
              label="Épület szint"
              :error-messages="errors.building_level_id"
              v-model="form.building_level_id"
              :items="buildingLevels"
              item-text="name"
              item-value="id"
              filled
              persistent-hint
              :disabled="!form.building_id"
              :hint="form.building_id ? '' : 'Épület kötelező'"
              :value-comparator="$config.comparators.isEqual"
            />
          </v-col>
          <v-col :cols="6" :md="3" :sm="6">
            <v-text-field
              label="X koordináta"
              :error-messages="errors.x_coord"
              v-model="form.x_coord"
              type="number"
              filled
            />
          </v-col>
          <v-col :cols="6" :md="3" :sm="6">
            <v-text-field
              label="Y koordináta"
              :error-messages="errors.y_coord"
              v-model="form.y_coord"
              type="number"
              filled
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12" :md="6" :sm="12">
            <v-switch
              label="Kiegészítő eszköz"
              :error-messages="errors.additional"
              v-model="form.additional.selected"
            />
            <v-text-field
              label="Megnevezés kiegészítés"
              :error-messages="errors.name_addition"
              v-model="form.name_addition"
              filled
            />
          </v-col>
          <v-col :cols="12" :md="6">
            <v-switch
              :error-messages="errors.opening_warning"
              v-model="form.open_warning.selected"
            >
              <template v-slot:label>
                <div>
                  <div>Tartós nyitás figyelmeztetés</div>
                  <div class="text-caption">
                    Olyan záraknál kell beállítani, ahol a hosszú nyitójel tönkreteheti azt
                  </div>
                </div>
              </template>
            </v-switch>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
<!--
    <v-card class="mt-6">
      <v-card-title>
        <v-icon left>mdi-chip</v-icon>
        Hozzáférési szintek
      </v-card-title>
      <v-card-text>
        <v-multi-select-list
          :headers="[
            { text: 'Hozzáférési szint', value: 'access_level' },
            { text: 'Engedélyezve', value: 'schedule_allow' },
            { text: 'Letiltva', value: 'schedule_deny' },
          ]"
          :items="form.access_level_schedules"
          v-model="form.access_levels_ids"
          item-text="name"
          item-value="element_id"
          :blank-item-template="{
            access_level_id: 0,
            schedule_group_id_allow: 0,
            schedule_group_id_deny: 0,
          }"
        >
          <template #[`item.access_levels`]="{ item, items }">
            <v-autocomplete
              placeholder="Hozzáférési szint"
              :items="form.access_levels"
              item-text="name"
              item-value="element_id"
              v-model="item.access_level_id"
              filled
              hide-details
              required
              :value-comparator="$config.comparators.isEqual"
            />
          </template>
          <template #[`item.schedule_allow`]="{ item }">
            <v-autocomplete
              placeholder="Soha"
              :items="form.schedule_groups"
              item-value="id"
              item-text="name"
              v-model="item.schedule_group_id_allow"
              filled
              hide-details
              clearable
              :value-comparator="$config.comparators.isEqual"
            />
          </template>
          <template #[`item.schedule_deny`]="{ item }">
            <v-autocomplete
              placeholder="Soha"
              :items="form.schedule_groups"
              item-value="id"
              item-text="name"
              v-model="item.schedule_group_id_deny"
              filled
              hide-details
              clearable
              :value-comparator="$config.comparators.isEqual"
            />
          </template>
        </v-multi-select-list>
      </v-card-text>
    </v-card> -->
  </div>
</template>
<script>
import { editorPage } from '@/mixins';

export default {
  mixins: [editorPage],

  data() {
    return {
      form: {
        additional: {
          value: 1,
          selected: false,
        },
        open_warning: {
          value: 1,
          selected: false,
        },
      },
      fetchStates: {
        doors: false,
        controllers: false,
        buildings: false,
        buildingLevels: this.$route.name === 'NewDoor',
        relays: this.$route.name === 'NewDoor',
        readers: this.$route.name === 'NewDoor',
      },
    };
  },

  async mounted() {
    try {
      const response = await this.$http.get(`doors/details/${this.$route.params.id || 0}`);
      this.form = response.door;
    } catch (e) {
      console.error(e);
    } finally {
      this.fetchStates.doors = true;
    }
  },

  methods: {
    async save() {
      try {
        this.errors = {};
        this.loading = true;
        const response = await this.$http.post('doors/save', this.form);
        if (Object.keys(response.errors).length) {
          this.errors = response.errors;
        } else {
          if (this.$route.name !== 'DoorEditor') {
            this.$router.push({ name: 'DoorEditor', params: { id: response.door.element_id } });
          }
          this.fetchDoor();
          this.$dialog.notify.success('Sikeres mentés!');
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = this.$route.name === 'NewDoor';
      }
    },
  },

  asyncComputed: {
    readers: {
      async get() {
        const response = await this.$http.post('readers/list', {
          controller_id: this.form.controller_id,
        });
        this.fetchStates.readers = true;
        return response.readers;
      },
      default: [],
      shouldUpdate() {
        return this.form.controller_id;
      },
    },
    relays: {
      async get() {
        const response = await this.$http.post('relays/list', {
          controller_id: this.form.controller_id,
        });
        this.fetchStates.relays = true;
        return response.relays;
      },
      default: [],
      shouldUpdate() {
        return this.form.controller_id;
      },
    },
    controllers: {
      async get() {
        const response = await this.$http.post('controllers/list');
        this.fetchStates.controllers = true;
        return response.controllers;
      },
      default: [],
    },
    buildings: {
      async get() {
        const response = await this.$http.post('buildings/list');
        this.fetchStates.buildings = true;
        return response.buildings;
      },
      default: [],
    },
    buildingLevels: {
      async get() {
        const response = await this.$http.post('building-levels/list', {
          building_id: this.form.building_id,
        });
        this.fetchStates.buildingLevels = true;
        return response.building_levels;
      },
      default: [],
      lazy: true,
      shouldUpdate() {
        return this.form.building_id;
      },
    },
  },
};
</script>
