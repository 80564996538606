<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn v-bind="$config.buttonAttrs.floating" @click="$router.go(-1)" title="Vissza">
        <v-icon dark>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn v-bind="$config.buttonAttrs.floating" @click="save" :disabled="loading" title="Mentés">
        <v-icon dark>mdi-content-save</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Kamera</h1>

    <v-card :loading="loading" class="mb-6">
      <v-card-title>
        <v-icon left>mdi-cctv</v-icon>
        Alapadatok
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col :cols="12" :md="6">
            <v-text-field
              label="Megnevezés"
              :error-messages="errors.name"
              v-model="form.name"
              filled
            />
            <v-autocomplete
              label="Kapcsolat"
              :error-messages="errors.connection_id"
              v-model="form.connection_id"
              :items="connections"
              item-text="name"
              item-value="element_id"
              filled
              :value-comparator="$config.comparators.isEqual"
            />
            <v-text-field
              label="Stream szerver cím"
              :error-messages="errors.webrtc_stream_server_address"
              v-model="form.webrtc_stream_server_address"
              filled
            />
            <v-text-field
              label="Stream azonosító"
              :error-messages="errors.webrtc_stream_id"
              v-model="form.webrtc_stream_id"
              filled
            />
          </v-col>
          <v-col :cols="12" :md="6">
            <v-textarea
              label="Megjegyzés"
              :error-messages="errors.comment"
              v-model="form.comment"
              filled
              :rows="3"
              auto-grow
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="12" :md="3" :sm="6">
            <v-autocomplete
              label="Épület"
              :error-messages="errors.building_id"
              v-model="form.building_id"
              :items="buildings"
              item-text="name"
              item-value="id"
              filled
              :value-comparator="$config.comparators.isEqual"
            />
          </v-col>
          <v-col :cols="12" :md="3" :sm="6">
            <v-autocomplete
              label="Épület szint"
              :error-messages="errors.building_level_id"
              v-model="form.building_level_id"
              :items="buildingLevels"
              item-text="name"
              item-value="id"
              filled
              :value-comparator="$config.comparators.isEqual"
              :disabled="!form.building_id"
              :persistent-hint="!form.building_id"
              :hint="form.building_id ? '' : 'Épület kötelező'"
            />
          </v-col>
          <v-col :cols="12" :md="3" :sm="6">
            <v-text-field
              label="X koordináta"
              :error-messages="errors.x_coord"
              v-model="form.x_coord"
              filled
              type="number"
            />
          </v-col>
          <v-col :cols="12" :md="3" :sm="6">
            <v-text-field
              label="Y koordináta"
              :error-messages="errors.y_coord"
              v-model="form.y_coord"
              filled
              type="number"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row>
      <v-col cols="12" md="6">
        <v-card :loading="loading">
          <v-card-title>
            <v-icon left>mdi-television</v-icon>
            Élő nézet
          </v-card-title>
          <v-card-text>
            <div id="lpr-overlay">
              <stream-player
                v-if="form.webrtc_stream_server_address"
                :server-address="form.webrtc_stream_server_address"
                :stream-id="form.webrtc_stream_id"
                :channel="0"
              />
              <canvas ref="canvas" width="1920" height="1080"></canvas>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card :loading="loading">
          <v-card-title>
            <v-icon left>mdi-vector-polygon-variant</v-icon>
            Rendszám zónák
          </v-card-title>
          <v-card-text>
            <v-multi-select-list
            :headers="[
              { text: 'Poligon', value: 'polygon' },
              { text: 'Sorompó', value: 'gate_id' }
            ]"
            :items="barriers"
            v-model="form.lpr_zones"
            :blank-item-template="{ name: '', polygon: '' }"
          >
            <template #[`item.polygon`]="{ item }">
              <v-text-field
                v-model="item.polygon"
                hide-details
                filled
                dense
              />
            </template>
            <template #[`item.gate_id`]="{ item, items }">
              <v-autocomplete
                :items="items"
                v-model="item.gate_id"
                item-text="name"
                item-value="element_id"
                placeholder="Válassz"
                hide-details
                filled
                dense
                clearable
              />
            </template>
          </v-multi-select-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const colors = [
  '#ff0000',
  '#ffff00',
  '#00ff00',
  '#00ffff',
  '#0000ff',
  '#ff00ff',
];

import StreamPlayer from '@/components/StreamPlayerMSE.vue';

export default {
  components: {
    StreamPlayer,
  },

  data() {
    return {
      errors: {},
      form: {},
      loading: false,
    };
  },

  mounted() {
    this.fetchCamera();
  },

  watch: {
    'form.lpr_zones': {
      handler(val) {
        const canvas = this.$refs.canvas;
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);

        let n = 0;
        for (const zone of this.form.lpr_zones) {
          const points = JSON.parse(zone.polygon);
          context.fillStyle = colors[n] + '08';
          context.strokeStyle = colors[n];
          context.lineWidth = 4;
          context.beginPath();
          context.moveTo(points[0][0]*canvas.width, points[0][1]*canvas.height);
          for (let i = 1 ; i < points.length; i++) {
            context.lineTo(Math.round(points[i][0]*canvas.width), Math.round(points[i][1]*canvas.height));
          }
          context.closePath();
          context.fill();
          context.stroke();
          n++;
        }
      },
      deep: true
    }
  },

  methods: {
    async fetchCamera() {
      this.loading = true;
      const response = await this.$http.get(`cameras/details/${this.$route.params.id || 0}`);
      this.loading = false;
      this.form = response.camera;
    },

    async save() {
      try {
        this.errors = {};
        this.loading = true;
        const response = await this.$http.post('cameras/save/', this.form);
        if (Object.keys(response.errors).length) {
          this.errors = response.errors;
        } else {
          if (this.$route.name !== 'CameraEditor') {
            this.$router.push({
              name: 'CameraEditor',
              params: { id: response.camera.element_id },
            });
          }
          this.fetchCamera();
          this.$dialog.notify.success('Sikeres mentés!');
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },

  asyncComputed: {
    controllers: {
      async get() {
        const response = await this.$http.post('controllers/list');
        return response.controllers;
      },
      default: [],
      lazy: true,
    },

    buildings: {
      async get() {
        const response = await this.$http.post('buildings/list');
        return response.buildings;
      },
      default: [],
      lazy: true,
    },

    buildingLevels: {
      async get() {
        const response = await this.$http.post('building-levels/list', {
          building_id: this.form.building_id,
        });
        return response.building_levels;
      },
      default: [],
      lazy: true,
    },

    barriers: {
      async get() {
        const response = await this.$http.post('barriers/list', { limit: 9999 });
        return response.barriers;
      },
      default: [],
    },

    connections: {
      async get() {
        const response = await this.$http.post('connections/list', { limit: 9999 });
        return response.connections;
      },
      default: [],
      lazy: true,
    },
  },
};
</script>

<style lang="scss">
#lpr-overlay {
  position: relative;

  & > canvas {
    position: absolute;
    inset: 0;
    width: 100%;
    aspect-ratio: 16/9;
  }
}
</style>
