<template>
  <div>
    <title-row title="UNIOM beléptetőrendszer"> </title-row>

    <div class="normal-text mb-8">
      A rendszer használatához válasszon a bal oldali menüpontok közül!
    </div>

    <v-row class="d-flex align-stretch">
      <v-col :md="6" :xl="3" :cols="12">
        <home-stat-card
          title="Kártyák"
          icon="mdi-badge-account-horizontal"
          :loading="loading"
          :statistics="[
            { label: 'Aktív', value: cards.active_count , to: { name: 'CardList' }},
            { label: 'Összesen', value: cards.count, to: { name: 'CardList' } },
            { label: 'Bent tartózkodik', value: cards.in_zones, to: { name: 'OccupantList' } },
          ]"
        />
      </v-col>
      <v-col :md="6" :xl="3" :cols="12">
        <home-stat-card
          title="Vendégek"
          icon="mdi-account-edit"
          :loading="loading"
          :statistics="[
            { label: 'Utolsó 24 óra', value: guests.day_count, to: { name: 'GuestLogList' } },
            { label: 'Utolsó 7 nap', value: guests.week_count, to: { name: 'GuestLogList' } },
            { label: 'Összesen', value: guests.count, to: { name: 'GuestLogList' } },
            { label: 'Bent tartózkodik', value: guests.in_zones, to: { name: 'OccupantList' } },
          ]"
        />
      </v-col>
    </v-row>
    <!-- <v-row class="d-flex align-stretch">
      <v-col :md="6" :xl="3" :cols="12">
        <home-stat-card
          title="Szerver statisztika"
          icon="mdi-format-list-bulleted-type"
          :loading="loading"
          :statistics="[
            { label: 'Ping', value: 14, unit: 'ms' },
            { label: 'CPU', value: 43, unit: '%' },
            { label: 'RAM', value: 78, unit: '%' },
            { label: 'Storage', value: 17, unit: '%' },
          ]"
        />
      </v-col>
      <v-col :md="6" :xl="3" :cols="12">
        <home-stat-card
          title="Fizikai eszközök"
          icon="mdi-server"
          :loading="loading"
          :statistics="[
            { label: 'Ajtó', value: 0 },
            { label: 'Forgóvilla', value: 2 },
            { label: 'Sorompó', value: 1 },
            { label: 'Olvasó', value: 6 },
            { label: 'Relé', value: 4 },
            { label: 'Kamera', value: 5 },
            { label: 'Kontroller', value: 1 },
          ]"
        />
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import { HomeStatCard } from '@/components';

export default {
  components: {
    HomeStatCard,
  },

  data() {
    return {
      fetchTimeout: null,
      cards: {},
      guests: {},
      loading: false,
    };
  },

  mounted() {
    this.fetchStats();
  },

  destroyed() {
    clearTimeout(this.fetchTimeout);
  },

  methods: {
    async fetchStats() {
      try {
        this.loading = true;
        const response = await this.$http.get('statistics/list');
        this.cards = response.cards || {};
        this.guests = response.guests || {};
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
        this.fetchTimeout = setTimeout(() => {
          this.fetchStats();
        }, 10000);
      }
    },

    setupDownload() {
      window.open('/cardReaderSetup.zip');
    },
  },
};
</script>
