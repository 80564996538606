<template>
  <v-dialog v-if="value" :value="true" persistent max-width="480px">
    <v-card>
      <v-card-title>
        <v-icon left>mdi-file-import</v-icon>
        Hozzáférési szintek importálása
        <v-spacer />
        <v-btn icon @click="$emit('close')">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>

      <v-divider/>

      <v-card-text>
        <v-btn href="/assets/doc/kulcs_import_sablon.xls" download large depressed block class="my-5">
          <v-icon left>mdi-file-download</v-icon>
          Sablon XLS fájl letöltése
        </v-btn>

        <file-upload-input
          v-model="files"
          elevation="0"
          color="muted"
          accept=".xls, .xlsx"
        />

        <v-alert type="error" v-if="errors.file" text>
          {{ errors.file[0] }}
        </v-alert>
      </v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" large depressed :disabled="loading" :loading="loading" @click="submit">
          Importálás
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
  },

  data() {
    return {
      loading: false,
      files: [],
      errors: {},
    };
  },

  computed: {
    open() {
      return value;
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },

    async submit() {
      this.errors = {};
      this.loading = true;
      try {
        const formData = new FormData();
        for (const file of this.files) {
          formData.append('file[]', file);
        }

        const response = await this.$http.post('keys/import', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response.errors && Object.keys(response.errors).length) {
          this.errors = response.errors;
          return;
        }
        if (response.status === 'OK') {
          this.file = null;
          this.loading = false;
          this.$dialog.notify.success('Sikeres importálás');
          this.$emit('success');
          this.$emit('close');
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
